import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function Series() {
    const [series, setSeries] = useState([]);
    const [seriesList, setSeriesList] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosConfig.get("ItemGroup")
            .then(data => setItemGroups(data));
        axiosConfig.get("Brand")
            .then(data => setBrands(data));
    }, []);

    const onValueChanged = (e) => {
       console.log(e);
        setSeries(prevState => ({
            ...prevState,
            [e.element.id]: e.value
        }));
    }
    useEffect(()=>{
        axiosConfig.get("Series")
        .then(data => setSeriesList(data));
    },[loading]);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoading(true);
        axiosConfig.post("Series",  JSON.stringify(series))
            .then(data =>  setLoading(false));
    }



    return(
        <div>
        <Loader loading={loading}/>
        <ToastContainer />
        <div className="page-header ">
                <div>
                <h1 className="page-title">Series</h1>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item className="breadcrumb-item" href="#">
                    Item Master
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                    className="breadcrumb-item active breadcrumds"
                    aria-current="page"
                    >
                    Series
                    </Breadcrumb.Item>
                </Breadcrumb>
                </div>
        </div>
        <CForm
            className="row g-3 needs-validation mb-4"
            // noValidate
            // validated={validated}
            onSubmit={handleSubmit}
        >
            <CCol md={4}>
                <CFormLabel htmlFor="seriesName">Series Name</CFormLabel>
                <CFormInput
                    type="text"
                    id="seriesName"
                    placeholder="Enter Series"
                    onChange={e => setSeries(prevState => ({
                        ...prevState,
                        SeriesName: e.target.value
                    }))}
                    required
                />
                <CFormFeedback valid>Looks good!</CFormFeedback>
            </CCol>
            <CCol md={2}>
                <CFormLabel htmlFor="itemGroup">Item Group</CFormLabel>
                <SelectBox
                    dataSource={itemGroups}
                    placeholder="Select Item Group"
                    displayExpr="groupName"
                    valueExpr="groupId"
                    id="GroupId"
                    onValueChanged={onValueChanged}
                    />
            </CCol>
            <CCol md={2}>
                <CFormLabel htmlFor="BrandId">Brand Name</CFormLabel>
                <SelectBox
                    dataSource={brands}
                    placeholder="Select brand"
                    displayExpr="brandName"
                    valueExpr="brandId"
                    id="BrandId"
                    onValueChanged={onValueChanged}
                    />
            </CCol>
            <CCol md={4}>
                <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
                <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                    <span>
                        <i className="fe fe-plus"></i>&nbsp;
                    </span>
                    Add
                </Button>
            </CCol>
        </CForm>
        <Card>
            <Card.Body>

                <div className="row">
                <DataGrid
                        dataSource={seriesList}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="seriesName" />
                        <Column dataField="groupName" />
                        <Column dataField="brandName" />
                    </DataGrid>

                </div>
            </Card.Body>
        </Card>
    </div>
    );

}

export default Series;

/**
 * 
 * 

    return (
        <div>
            <div className="container">
                <div className="row">
                <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="seriesName">Series Name</label>
                            <input type="text" className="form-control" id="seriesName" placeholder="Enter Series Name" 
                                onChange={e => setSeries(prevState => ({
                                    ...prevState,
                                    SeriesName: e.target.value
                                }))}/>
                        </div>
                    </div>
                    <div className="col-sm">
                    <div className="form-group">
                            <label htmlFor="prodTypeId">Item Group Name</label>
                            <SelectBox
                            dataSource={itemGroups}
                            placeholder="Select Item Group"
                            displayExpr="groupName"
                            valueExpr="groupId"
                            id="GroupId"
                            onValueChanged={onValueChanged}
                            />
                        </div>
                       
                    </div>
                    <div className="col-sm">
                    <div className="form-group">
                            <label htmlFor="prodTypeId">Brand Name</label>
                            <SelectBox
                            dataSource={brands}
                            placeholder="Select brand"
                            displayExpr="brandName"
                            valueExpr="brandId"
                            id="BrandId"
                            onValueChanged={onValueChanged}
                            />
                        </div>
                       
                    </div>
                    <div className="col-sm">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add</button>
                    </div>
                </div>
                <div className="row">
                    <DataGrid
                        dataSource={seriesList}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="seriesName" />
                        <Column dataField="groupName" />
                        <Column dataField="brandName" />
                    </DataGrid>

                </div>
            </div>
        </div>);

 */