import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
//import ClipLoader from "react-spinners/ClipLoader";
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
  } from "react-bootstrap";
import 'devextreme/dist/css/dx.light.css';
import { Loader } from '../Shared/Utils';
import axiosConfig from '../config/axiosConfig';

function QuotationPBD({ }) {

    const [pbd, setPBD,] = useState({ quotationHeader: {}, productPrices: [], costItemBreakDownDCs: [] ,totalValueDCs:[],netOptions:[]});
    const [pbdKeys, setPBDKeys,] = useState([]);
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const quotationNum = params.get('QuotationNum');
    const revNum = params.get('revNum');
    console.log(quotationNum + " " + revNum);
    useEffect(() => {

        if (quotationNum != undefined && revNum != undefined) {
            getPBD(quotationNum, revNum);
        }
    }, [])

    const insertKey = (key, value, obj, pos) => {
        return Object.keys(obj).reduce((ac, a, i) => {
            if (i === pos) ac[key] = value;
            ac[a] = obj[a];
            return ac;
        }, {})
    }
    const getPBD = (quotationNum, revNum) => {
        setLoading(true);
        axiosConfig.get("Quotation/pbd?quotationNum=" + quotationNum + "&revNum=" + revNum)
            .then(data => {
                setLoading(false);
                setPBD(data);
                if (data.productPrices.length > 0) {
                    setPBDKeys(Object.keys(data.productPrices[0]));
                }

            });
    }


    const generateRow = (item) => {
        var rows = [];
        let netOptionOfLineNum  = pbd.netOptions.filter(x=> x.LineNum == item.LineNum);
        Object.keys(item).map((item1, key) => {
            /*if (item1 == "ListPrice") {
                [...Array(10 - key)].map((e, i) => rows = rows.concat(<td key={i + "e1" + item1} align="center">&nbsp;&nbsp;&nbsp; </td>))
                rows = rows.concat(<td key={key} align="center">{item[item1]}</td>);
            }*/
            if (item1 == "ItemCode") {
                rows = rows.concat(<td key={key} style={{whiteSpace : "nowrap"}}>{item[item1]}</td>);
            }
            else if(netOptionOfLineNum.some(x=> x.OptCode == item1 )){
                let isNet = netOptionOfLineNum.filter(x=> x.OptCode == item1)[0].IsNet;
                rows = rows.concat(<td key={key} style={{"backgroundColor": isNet == "True" ? "yellow":""}} align="center">{item[item1] != null ? item[item1].toLocaleString('en-US', {minimumFractionDigits: 2}) :  null}</td>);
            }
            else{
                rows = rows.concat(<td key={key} align="center">{item[item1] != null ? item[item1].toLocaleString('en-US', {minimumFractionDigits: 2}) :  null}</td>);
            }
               
        });

        return rows;
    }
    const generateHeaders = (product) => {
        var rows = [];
        Object.keys(product.optionsPricing[0]).map((item, key) => {
            /*if (item == "ListPrice") {
                [...Array(10 - key)].map((e, i) => rows = rows.concat(<th key={i + "e1" + item} align="center">&nbsp;&nbsp;&nbsp; </th>))
                rows = rows.concat(<th align="center" scope="col" key={key} style={{ fontSize: "7pt", width: "32px",color:"white" }}>{item}</th>);
            }
            else*/
                if(item == "ItemCode")
                    rows = rows.concat(<th  scope="col" key={key} style={{ fontSize: "7pt", width: "32px",color:"white" }}>{item}</th>);
                else
                rows = rows.concat(<th align="center" scope="col" key={key} style={{ fontSize: "7pt", width: "32px",color:"white" }}>{item}</th>);
        });
        return rows;
    }
    const renderLines = () => {
        return (
            <table cellSpacing="0" id="newGVPType" style={{ borderCollapse: "collapse", width:'100%' }}>
                <tbody>
                    {
                        pbd.productPrices.map((product) => {
                            return <tr>
                                <td>
                                    <span  style={{ color: "#990000", fontSize: "Medium", fontWeight: "bold", textAlign: "left" }}>{product.productType}</span>
                                    <br />
                                    <table className="table table-sm table-bordered table-nopadding" cellSpacing="0" rules="all" border="1" style={{ borderCollapse: "collapse" }}>
                                        <tbody>
                                            <tr align="center" style={{ backgroundColor: "#367fc2", fontSize: "7pt", fontWeight: "bold" }}>
                                                {
                                                    generateHeaders(product)
                                                }
                                            </tr>
                                            {
                                                product.optionsPricing.map((item,index) => {
                                                    if(index != product.optionsPricing.length -1)
                                                        return <tr  style={{ fontSize: "7pt" }}>{generateRow(item)}</tr>
                                                    else
                                                        return <tr  style={{ fontSize: "10pt",backgroundColor:"aliceblue",fontWeight:"bold" }}>{generateRow(item)}</tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{display:"flex",justifyContent:"space-between"}}>
                                      {product.costItemProductWise.length> 0 &&  <table className="table table-sm table-bordered table-nopadding" cellSpacing="0" rules="all" border="1" style={{ borderCollapse: "collapse",width:"25%" }}>
                                            <tbody>
                                                <tr style={{ backgroundColor: "#367fc2",color:"white" ,fontSize: "7pt", fontWeight: "bold" }}>
                                                    <th scope="col" style={{color:"white"}}>Cost Item Code</th>
                                                    <th scope="col" style={{color:"white"}}>Tot Cost Prov</th>
                                                    <th scope="col" style={{color:"white"}}>Value</th>
                                                </tr>
                                                {
                                                    product.costItemProductWise.map((item) => {
                                                        return <tr style={{ fontSize: "8pt" }}>
                                                            {
                                                                Object.keys(item).map((item1, key) => {

                                                                    return <td key={key} >{item[item1]}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        }
                                        
                                    </div>
                                    <br />
                                    <br />
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        );
    }

    return (
        <Card> 
            <Card.Body>
                <Loader loading={loading}/>
                <div id="pnlQuot">
                    <table id="tblTitle" className="table table-sm table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold" }}>Quot. No:</span>
                                </td>
                                <td>
                                    <span id="lblQuotNo" className="lblValue">{pbd.quotationHeader.QuotationNum}-R{pbd.quotationHeader.RevNum}</span>
                                </td>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Quotation Date:</span>
                                </td>
                                <td>
                                    <span id="lblQuotDt" className="lblValue">{pbd.quotationHeader.QuotationDate}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Project:</span>
                                </td>
                                <td>
                                    <span id="lblProjName" className="lblValue">{pbd.quotationHeader.ProjectName}</span>
                                </td>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Customer:</span>
                                </td>
                                <td>
                                    <span id="lblCust" className="lblValue">{pbd.quotationHeader.CustomerName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Area:</span>
                                </td>
                                <td>
                                    <span id="lblArea" className="lblValue">{pbd.quotationHeader.AreaName}</span>
                                </td>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>SR:</span>
                                </td>
                                <td>
                                    <span id="lblMSP" className="lblValue">{pbd.quotationHeader.MspName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Currency:</span>
                                </td>
                                <td>
                                    <span id="lblCurrency" className="lblValue">{pbd.quotationHeader.CurrencyCode}</span>
                                </td>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Expected Delivery Date:</span>
                                </td>
                                <td>
                                    <span id="lblCurrency" className="lblValue">{pbd.quotationHeader.ExpectedDeliveryDate}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Status:</span>
                                </td>
                                <td>
                                    <span id="lblCurrency" className="lblValue">{pbd.quotationHeader.StatusName}</span>
                                </td>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Probability:</span>
                                </td>
                                <td>
                                    <span id="lblCurrency" className="lblValue">{pbd.quotationHeader.Probability}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Delivery Term:</span>
                                </td>
                                <td>
                                    <span id="lblCurrency" className="lblValue">{pbd.quotationHeader.DeliveryTermName}</span>
                                </td>
                                <td>
                                    <span className="text-bold" style={{color:"#367fc2",fontWeight:"bold"}}>Payment Term:</span>
                                </td>
                                <td>
                                    <span id="lblCurrency" className="lblValue">{pbd.quotationHeader.PaymentTermName}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
           
                <Row>
                <Col md={12}>
                    {renderLines()}
                </Col>
                </Row>
                <br />
                <div style={{display:"flex"}}>
                    <div style={{flexGrow:"2"}}>
                        <h5><span  style={{ color: "#990000", fontSize: "Medium", fontWeight: "bold", textAlign: "left" }}>Cost Item Summary</span></h5>
                        <div>
                            <table className="table table-sm table-bordered" cellSpacing="0" rules="all" border="1" id="gvCostItemSummary" style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr style={{ backgroundColor: "#367fc2" }}>
                                        <th scope="col" style={{color:"white"}}>Cost Item Code</th>
                                        <th scope="col" style={{color:"white"}}>Tot Cost Prov</th>
                                    </tr>
                                    {

                                        pbd.costItemBreakDownDCs.map((item, index) => {
                                            return <tr><td >{item.CostItemCode}</td><td >{item.TotCostProv}</td></tr>
                                        })

                                    }

                                </tbody></table>
                        </div>
                    </div>
                    <div style={{flexGrow:"1"}}>
                        <h5><span  style={{ color: "#990000", fontSize: "Medium", fontWeight: "bold", textAlign: "left" }}>Total Summary</span></h5>
                        <div>
                            <table className="table table-sm table-bordered" cellSpacing="0" rules="all" border="1" id="gvCostItemSummary1" style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr  style={{ backgroundColor: "#367fc2" }}>
                                    <th scope="col" style={{color:"white"}}>T/CostProv</th>
                                    <th scope="col" style={{color:"white"}}>T/Cost</th>
                                    <th scope="col" style={{color:"white"}}>T/Price</th>
                                    <th scope="col" style={{color:"white"}}>T/Qty</th>
                                    </tr>
                                    {

                                        pbd.totalValueDCs.map((item, index) => {
                                            return <tr><td >{item.TotalCostValue}</td><td>{item.TotalCost}</td><td>{item.TotalPrice}</td><td>{item.TotalQty}</td></tr>
                                        })

                                    }

                            </tbody></table>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>


    );

}

export default QuotationPBD;
/*

<table className="ml-auto table table-sm table-bordered table-nonfluid table-td-rightalign" cellSpacing="0" rules="all" border="1" id="newGVPType_gvTotal1_0" style={{ borderCollapse: "collapse",width:"25%" }}>
                                            <tbody>
                                                <tr style={{ backgroundColor: "#367fc2", fontSize: "9pt", fontWeight: "bold" }}>
                                                
                                                    <th scope="col" style={{color:"white"}}>T/CostProv</th>
                                                    <th scope="col" style={{color:"white"}}>T/Cost</th>
                                                    <th scope="col" style={{color:"white"}}>T/Price</th>
                                                    <th scope="col" style={{color:"white"}}>T/Qty</th>
                                                </tr>
                                                <tr>
                                                    
                                                    <td>{product.totals[0].TotalCostValue}</td>
                                                    <td>{product.totals[0].TotalCost}</td>
                                                    <td>{product.totals[0].TotalPrice}</td>
                                                    <td>{product.totals[0].TotalQty}</td>
                                                </tr>
                                            </tbody>
                                        </table>


*/
