
import axios from 'axios';
const instance = axios.create({
   //baseURL: 'https://localhost:7281/api'
   baseURL: 'https://cms.comfortrh.com:5000/api',
});


instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
instance.defaults.headers.post['Content-Type'] = 'application/json';


instance.interceptors.request.use(request => {
    //console.log(request);

    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    //console.log(response);

    return response.data;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

export default instance;