import 'devextreme/dist/css/dx.light.css';
//import { Button as DxButton } from 'devextreme-react/button';
//import SelectBox from 'devextreme-react/select-box';
import { useEffect, useContext, useRef, useState } from "react";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../Shared/Utils';
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem,
  Pager,
  Paging,
  FilterRow, HeaderFilter,
  SearchPanel,
} from 'devextreme-react/data-grid';
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Modal,
  Col,
  Form,
  Button
} from "react-bootstrap";
//import DateBox from 'devextreme-react/date-box';
import 'devextreme/dist/css/dx.light.css';
//import { Popup } from 'devextreme-react/popup';
//import notify from 'devextreme/ui/notify';
import axiosConfig from '../config/axiosConfig';
import MyContext from '../Shared/MyContext';

function QuotationCopyOption({ popupVisible, setPopupVisible}) {

  const [quotationOptions, setQuotationLineAllOptions] = useState([]);
  //const [quotLines, setQuotationLines] = useState([]);
  const quotToCopyOptionGrid = useRef();
  const quotFromCopyOptionGrid = useRef();
  const {quotationObjContext,setQuotationObjContext,loadingContext,setLoadingContext,setQuotationHeaderContext,setLinesToBeUpdatedContext,setCostItemsToBeUpdatedContext,isHeaderToBeUpdatedContext,
    setHeaderToBeUpdatedContext,quotationLinesContext }= useContext(MyContext);
  //const [visible, setvisibility] = useState(false);
  useEffect(() => {
    if (popupVisible) {
      axiosConfig.get("Quotation/lines/options?quotationId=" +  quotationObjContext.quotationNum + "&revNum=" + quotationObjContext.revNum)
        .then(data => {
          //setvisibility(popupVisible);
          setQuotationLineAllOptions(data);
          //setPopupVisible(false);
        });
    }
  }, [popupVisible]);
  /*useEffect(() => {
    if (quotationLines != undefined) {
      setQuotationLines(quotationLines)
    }
  }, [quotationLines])*/


  const onCopyAddOptionClick = (e) => {
    setLoadingContext(true);
    let fromOptCodes = quotFromCopyOptionGrid.current.instance.getSelectedRowsData().map(x => x["optCode"]);
    let toLines = quotToCopyOptionGrid.current.instance.getSelectedRowsData().map(x => x["lineNum"]);
    if (fromOptCodes.length <= 0 || toLines.length <= 0) {
      if (fromOptCodes.length <= 0)
      showNotificationError(`Select from optcodes`);
      if (toLines.length <= 0)
      showNotificationError(`Select to lines`);
      return;
    }
    let input = { quotationNum: quotationObjContext.quotationNum, revNum: quotationObjContext.revNum, fromOptCodes: fromOptCodes, copyToLines: toLines }
    console.log(input);
    axiosConfig.post('Quotation/line/copyoption', JSON.stringify(input))
      .then((data) => {
        //console.log(data);
        //refreshLinesGrid([]);
        showNotificationSuccess(`Options copied succesfully`);
        setPopupVisible(false);
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
        quotFromCopyOptionGrid.current.instance.deselectAll();
        quotToCopyOptionGrid.current.instance.deselectAll();
      })
      .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in copying Quotations :"+error)
        } 
     })
     .then(()=>setLoadingContext(false));
    //
  }
  const onSelectionChanged = (e) => {

  }
  const handleClose =(e) =>{
    setPopupVisible(false);
    e.preventDefault();
  }
  return (
    <Modal
      show={popupVisible}
      onHide={handleClose}
      size="xl"
      //dialogClassName ="modal-xl"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header>
        <Modal.Title> Copy Options</Modal.Title>
        <button onClick={handleClose}  className="btn btn-icon me-3">
            <i className="fe fe-x"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
          <Row>
        <Col md={6}>
          <DataGrid
            dataSource={quotationOptions}
            showBorders={true}
            allowColumnReordering={true}
            onSelectionChanged={onSelectionChanged}
            //keyExpr="lineNum"
            allowSearch
            ref={quotFromCopyOptionGrid}
            columnAutoWidth
            // rowAlternationEnabled={true}
            //width={'300'}
            height={500}
          >
            <Paging defaultPageSize={10} enabled={true} />
            <Selection mode="multiple" />
            <FilterRow visible={true} />
            <Grouping autoExpandAll={false} />
            <Column dataField="lineNum" groupIndex={0} />
            <Column dataField="itemCode" />
            <Column dataField="optCode" />
            <Column dataField="isNet" />
          </DataGrid>
        </Col>
        <Col md={1}>
          &nbsp;
        </Col>
        <Col  md={4}>

          <DataGrid
            dataSource={quotationLinesContext}
            showBorders={true}
            allowColumnReordering={true}
            //rowAlternationEnabled={true}
            allowSearch
            //keyExpr="lineNum"
            ref={quotToCopyOptionGrid}
            columnAutoWidth
            height={500}
          >
            <FilterRow visible={true} />
            <Paging defaultPageSize={10} enabled={true} />
            <Pager visible={true} />
            <Selection mode="multiple" />
            <Column dataField="lineNum" />
            <Column dataField="itemCode" />
          </DataGrid>
        </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="success" className="me-1" onClick={onCopyAddOptionClick}>
          Copy options
        </Button>
      </Modal.Footer>
    </Modal>
  );

}

export default QuotationCopyOption;