export const IssueItem  = class {
    id =  null;
    type = null;
    title=  null;
    createdBy= 0;
    additionalRemarks= null;
    status=  null;
    createdOn=  null;
    devRemarks=  null;
    updatedOn=  null;
}