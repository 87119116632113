import SelectBox from 'devextreme-react/select-box';
import { useEffect, useRef, useState, useCallback, useContext } from "react";
import DateBox from 'devextreme-react/date-box';
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import axiosConfig from '../config/axiosConfig';
import WarrantyLines from './WarrantyLines';
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button
} from "react-bootstrap";
import {
  CForm,
  CCol,
  CFormLabel,
  CTable,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
} from "@coreui/react";
import DataGrid, {
  Column,
  Editing,
  Format,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem, Item, Toolbar,Lookup,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,FilterRow,
  Export,
  RemoteOperations, GroupItem, SimpleItem,
  Button as DXButtonDataGrid
} from 'devextreme-react/data-grid';

import { showNotificationSuccess, showNotificationError, showWarningAlert,Loader, showErrorAlert } from '../Shared/Utils';
import MyContext from '../Shared/MyContext';
import UserContext from '../Shared/UserContext';
import { useNavigate,useParams } from 'react-router-dom';

function WarrantyHeader() {
  const { jobDetailsId } = useParams();
  const { warrantyObjContext, setWarrantyObjContext,setLoadingContext,loadingContext, setWarrantyLinesContext,warrantyLinesContext } = useContext(MyContext);
  const {user} = useContext(UserContext);
  //const [popupCurrencyVisible, setPopupCurrencyVisible] = useState(false);
  const [currency, setCurrency] = useState({});
  const [customers, setCustomers] = useState([]);
  const [warranty, setWarranty ] = useState({});
  const [warrantyLines, setWarrantyLines ] = useState({});
  const [errors, setErrors] = useState({});
  const [masters, setMasters] = useState({  users: [], areas: [],  paymentTerms: [], customers: [],currency: [], clients:[],consultants:[] });
  const mandatoryFields = ["salesOrderReference", "jobDetailsId"];
  const [validated, setValidated] = useState(false);
  //const [oldCurrencyCode, setOldCurrencyCode] = useState("");
 // const probabilityList = [{Name:"0%",Id:0},{Name:"25%",Id:25},{Name:"50%",Id:50},{Name:"75%",Id:75},{Name:"90%",Id:90},{Name:"100%",Id:100}];
  //const[productCAF,setproductCAF] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    //setLoadingContext(true);
    if (jobDetailsId != undefined) {
      setWarrantyObjContext({...warrantyObjContext, jobDetailsId:jobDetailsId});
    }
  }, []);

  useEffect(() => {
    setLoadingContext(true);
    axiosConfig.get('Master')
      .then(data => {
        setMasters({
          users: JSON.parse(data["users"]),
          areas: JSON.parse(data["areas"]),
          paymentTerms: JSON.parse(data["paymentTerms"]),
          customers: JSON.parse(data["customers"]),
          clients: JSON.parse(data["clients"]),
          consultants: JSON.parse(data["consultants"]),
          currency: JSON.parse(data["currency"]),
        });
        //setCustomers(JSON.parse(data["customers"]));
      })
      .catch((error) => showNotificationError("Masters loading error:" + error))
      .then(() => setLoadingContext(false));
    if (jobDetailsId != undefined && user != null) {

      axiosConfig.get('Warranty/' + jobDetailsId)
        .then(data => {
          /*let isLocked= false;
          if(data.lockedForEditingBy != null && data.lockedForEditingBy != ""){
           if(data.lockedForEditingBy != user.Id) isLocked = true;
          }*/
          setWarrantyObjContext({ ...warrantyObjContext});
          setWarranty(JSON.parse(data.header));
          setWarrantyLinesContext(JSON.parse(data.lines));
          //setQuotationHeaderContext(data);
          
          //if(isLocked)
           // showWarningAlert(["Quotation is locked by User "+ data.lockedForEditingByName]);
        })
        .catch((error) => showNotificationError("Quotation loading error:" + error))
        .then(() => setLoadingContext(false));
    }

  }, [warrantyObjContext["salesOrderReference"],user]);


  const getWarrantyHeaderRefresh = () => {
    setLoadingContext(true);
    axiosConfig.get("Warranty?Id=" + warrantyObjContext["salesOrderReference"])
      .then(data => {
        setWarranty(data);
        //setQuotationHeaderContext(data);
        setLoadingContext(false);
      });
  }


  const onInputChange = (e) => {
    //for dxSelectBox
    if (e.element != undefined) {
      setWarranty(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));

      if (e.value != "") {
        setErrors(prevState => ({
          ...prevState,
          [e.element.id]: "valid"
        }));
      }
    }
    //for normal inputs
    else {
        setWarranty(prevState => ({
        ...prevState,
        [e.target.id]: e.target.value
      }));

      if (e.target.value != "") {
        setErrors(prevState => ({
          ...prevState,
          [e.target.id]: "valid"
        }));
      }
    }
  }


  const handleWarrantySave = (evt) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setValidated(true);
    let isFormValid = true;
    /*mandatoryFields.forEach(item => {
      if (warranty[item] == null || warranty[item] == "") {
        setErrors(prevState => ({
          ...prevState,
          [item]: "invalid"
        }));
        isFormValid = false;
      }
    });*/
    if (isFormValid && form.checkValidity()) {
      setLoadingContext(true);
      setErrors({});
      let inputData = {...warranty,userId:user.Id};
      axiosConfig.post("Warranty/header", JSON.stringify(inputData))
        .then(data => {
          showNotificationSuccess("Warranty Header saved successfully");
          if(warranty.jobDetailsId != undefined){
            navigate("/Warranty/"+data.jobDetailsId);
            window.location.reload(false);
          }
        })
        .catch((error) =>
          showNotificationError("Error in saving Warranty:" + error)
        )
        .then(() => setLoadingContext(false));
    }

  }

  const setValidationStatus = (id) => {
    return errors[id] != undefined ? errors[id] : "valid";
  }

  const onRefreshCustomerClick =(e,type) =>{
    setLoadingContext(true);
    axiosConfig.get('master/buyers/all?type='+type)
    .then(data => {
      if(type == 4 )
        setMasters(prevState => ({
          ...prevState,
          customers: data
        }));
      else if (type == 5)
        setMasters(prevState => ({
          ...prevState,
          clients: data
        }));
      else 
        setMasters(prevState => ({
          ...prevState,
          consultants: data
        }));
    })
    .catch((error) => showNotificationError("Buyer loading error:" + error))
    .then(() => setLoadingContext(false));
    e.preventDefault();
  }

  const WarrantyProvisions = ({ warranty, onInputChange }) => {
    // Inline styles for table headers and cells
    const headerStyle = { padding: '10px', textAlign: 'left', borderBottom: '1px solid #ddd' };
    const cellStyle = { padding: '10px' };
  
    // This function is used to render input cells in the table
    const renderInputCell = (id, value) => (
      <td style={cellStyle}>
        <CFormInput type="number" id={id} value={value} onChange={onInputChange} />
      </td>
    );
  
    return (
      <div>
        <h3>Warranty Provisions</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}> {/* Adding styles here for the table layout */}
          <thead>
            <tr>
              <th style={headerStyle}>Category</th>
              <th style={headerStyle}>Total</th>
              <th style={headerStyle}>Utilized</th>
              <th style={headerStyle}>Reversed</th>
              <th style={headerStyle}>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={headerStyle}>Parts</th>
              {renderInputCell('WarrantyProvisionPartsTotal', warranty["warrantyProvisionPartsTotal"])}
              {renderInputCell('WarrantyProvisionPartsUtilized', warranty["warrantyProvisionPartsUtilized"])}
              {renderInputCell('WarrantyProvisionPartsReversed', warranty["warrantyProvisionPartsReversed"])}
              {renderInputCell('WarrantyProvisionPartsBalance', warranty["warrantyProvisionPartsBalance"])}
            </tr>
            <tr>
              <th style={headerStyle}>Labour</th>
              {renderInputCell('WarrantyProvisionLabourTotal', warranty["warrantyProvisionLabourTotal"])}
              {renderInputCell('WarrantyProvisionLabourUtilized', warranty["warrantyProvisionLabourUtilized"])}
              {renderInputCell('WarrantyProvisionLabourReversed', warranty["warrantyProvisionLabourReversed"])}
              {renderInputCell('WarrantyProvisionLabourBalance', warranty["warrantyProvisionLabourBalance"])}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

   return (
        <div>
          <Loader loading={loadingContext}/>
            <ToastContainer />
            <div className="page-header">
                <div>
                <h1 className="page-title">Warranty</h1>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item className="breadcrumb-item" href="#">
                    Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                    className="breadcrumb-item active breadcrumds"
                    aria-current="page"
                    >
                    Warranty
                    </Breadcrumb.Item>
                </Breadcrumb>
                </div>
                <div className="ms-auto pageheader-btn">
                <Link to={"#"} onClick={()=>console.log("dd")} className="btn btn-primary btn-icon text-white me-3">
                    <span>
                    <i className="fe fe-plus"></i>&nbsp;
                    </span>
                    Add Warranty
                </Link>
                </div>
            </div>
          <Card> 
            <Card.Header>
            <h3 className="card-title">Sales Order Reference-{warranty["salesOrderReference"]}</h3>
            </Card.Header>
            <Card.Body>
              <CForm
                className="row g-3 needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleWarrantySave}
              >
                <CCol md={2}>
                  <CFormLabel htmlFor="jobDetailsId">Job Details ID</CFormLabel>
                  <CFormInput
                    type="text"
                    id="jobDetailsId"
                    onChange={onInputChange}
                    value={warranty["jobDetailsId"]}
                    required
                    readOnly={jobDetailsId !=null ? true:false}
                  />
                </CCol>
      
                <CCol md={4}>
                  <CFormLabel htmlFor="salesOrderReference">Sales Order Reference</CFormLabel>
                  <CFormInput
                    type="text"
                    id="salesOrderReference"
                    onChange={onInputChange}
                    value={warranty["salesOrderReference"]}
                    required
                    readOnly={jobDetailsId !=null ? true:false}
                  />
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="projectName">Project Name</CFormLabel>
                  <CFormInput
                    type="text"
                    id="projectName"
                    onChange={onInputChange}
                    value={warranty["projectName"]}
                    required
                  />
                </CCol>
                <CCol md={2}>
              <CFormLabel htmlFor="areaCode">Area</CFormLabel>
              <SelectBox
                dataSource={masters["areas"]}
                validationStatus={setValidationStatus("areaCode")}
                //placeholder="Select Item Group"
                displayExpr="Name"
                valueExpr="Code"
                id="areaCode"
                value={warranty["areaCode"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol md={2} sm={2}>
              <CFormLabel htmlFor="currencyCode">Currency</CFormLabel>
              <CInputGroup className="has-validation">
                <SelectBox
                  dataSource={masters["currency"]}
                  validationStatus={setValidationStatus("warrantyProvisionCurrency")}
                  //placeholder="Select Item Group"
                  displayExpr="Name"
                  valueExpr="Code"
                  id="warrantyProvisionCurrency"
                  value={warranty["warrantyProvisionCurrency"]}
                  onValueChanged={onInputChange}
                  required
                />
              </CInputGroup>
            </CCol>
                <CCol md={3}>
              <CFormLabel htmlFor="customerCode">Customer</CFormLabel>
              <Row>
                <CCol>
                  <SelectBox
                    dataSource={masters.customers}
                    validationStatus={setValidationStatus("customerCode")}
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Name'}
                    searchTimeout={200}
                    minSearchLength={2}
                    displayExpr="Name"
                    valueExpr="Code"
                    id="customerCode"
                    value={warranty["customerCode"]}
                    onValueChanged={onInputChange}
                  />
                </CCol>
                  <span className="col-auto mb-2">
                    <button className="btn btn-secondary" onClick={(e) => onRefreshCustomerClick(e,4)}>
                      <i className="fe fe-refresh-ccw"></i>
                    </button>
                  </span>
              </Row>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="clientCode">Client</CFormLabel>
              <Row>
                <CCol>
                  <SelectBox
                    dataSource={masters.clients}
                    //validationStatus={setValidationStatus("clientCode")}
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Name'}
                    searchTimeout={200}
                    minSearchLength={2}
                    displayExpr="Name"
                    valueExpr="Code"
                    id="clientCode"
                    value={warranty["clientCode"]}
                    onValueChanged={onInputChange}
                  />
                </CCol>
                  <span className="col-auto mb-2">
                    <button className="btn btn-secondary" onClick={(e) => onRefreshCustomerClick(e,5)}>
                      <i className="fe fe-refresh-ccw"></i>
                    </button>
                  </span>
              </Row>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="consultantCode">Consultant</CFormLabel>
              <Row>
                <CCol>
                  <SelectBox
                    dataSource={masters.consultants}
                    //validationStatus={setValidationStatus("consultantCode")}
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Name'}
                    searchTimeout={200}
                    minSearchLength={2}
                    displayExpr="Name"
                    valueExpr="Code"
                    id="consultantCode"
                    value={warranty["consultantCode"]}
                    onValueChanged={onInputChange}
                  />
                </CCol>
                  <span className="col-auto mb-2">
                    <button className="btn btn-secondary" onClick={(e) => onRefreshCustomerClick(e,6)}>
                      <i className="fe fe-refresh-ccw"></i>
                    </button>
                  </span>
              </Row>
            </CCol>
            
           
            <CCol md={2}>
              <CFormLabel htmlFor="msp">ASM</CFormLabel>
              <SelectBox
                dataSource={masters["users"]}
                validationStatus={setValidationStatus("salesRepresentativeId")}
                displayExpr="Name"
                valueExpr="Id"
                id="salesRepresentativeId"
                value={warranty["salesRepresentativeId"]}
                onValueChanged={onInputChange}
              />
            </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="customersOrderReference">Customer's Order Reference</CFormLabel>
                  <CFormInput
                    type="text"
                    id="customersOrderReference"
                    onChange={onInputChange}
                    value={warranty["customersOrderReference"]}
                    required
                  />
                </CCol>
                <CCol sm={4}>
                <CFormLabel htmlFor="paymentTermsId"> Payment Term </CFormLabel>
                <SelectBox
                    dataSource={masters["paymentTerms"]}
                    validationStatus={setValidationStatus("paymentTermsId")}
                    displayExpr="Name"
                    valueExpr="Id"
                    id="paymentTermsId"
                    value={warranty["paymentTermsId"]}
                    onValueChanged={onInputChange}
                />
                </CCol>
      
                <CCol md={4}>
                  <CFormLabel htmlFor="paymentStatus">Payment Status</CFormLabel>
                  <CFormInput
                    type="text"
                    id="paymentStatus"
                    onChange={onInputChange}
                    value={warranty["paymentStatus"]}
                    required
                  />
                </CCol>
    
                <CCol md={6}>
                {WarrantyProvisions({warranty,onInputChange})}
                </CCol>
                <CCol md={12}>
                  <CButton color="success" type="submit" className="float-end">
                    Save
                  </CButton>
                </CCol>
              </CForm>
            </Card.Body>
          </Card>
         <WarrantyLines/>
    
        </div>
  );

}

export default WarrantyHeader;



