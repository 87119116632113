import SelectBox from 'devextreme-react/select-box';
import { useEffect, useRef, useState, useCallback, useContext } from "react";
import DateBox from 'devextreme-react/date-box';
import axiosConfig from '../config/axiosConfig';
import { Button as DxButton } from 'devextreme-react/button';
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button
} from "react-bootstrap";
import {
  CForm,
  CCol,
  CFormLabel,
  CTable,
  CGroup ,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
} from "@coreui/react";
import DataGrid, {
  Column,
  Editing,
  Format,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem, Item, Toolbar,Lookup,
  ColumnChooser,
  Pager,
  Paging,
  Button as DxDataGridButton,
  SearchPanel,FilterRow,
  Export,
  RemoteOperations, GroupItem, SimpleItem} from 'devextreme-react/data-grid';
import { WarrantyItem } from "../Models/WarrantyItemModel";

import { showNotificationSuccess, showNotificationError, showWarningAlert,Loader, showErrorAlert } from '../Shared/Utils';
import MyContext from '../Shared/MyContext';
import UserContext from '../Shared/UserContext';
import { useNavigate } from 'react-router-dom';

function WarrantyLines() {
  const currencyGrid = useRef();
  const { warrantyObjContext, setWarrantyObjContext,setLoadingContext, setWarrantyLinesContext,warrantyLinesContext } = useContext(MyContext);
  const {user} = useContext(UserContext);
  const [showWarrantyItemEditPopUp, setShowWarrantyItemEditPopUp] = useState(false);
  const [warrantyItem, setWarrantyItem] = useState(new WarrantyItem());
 // const [warranty, setWarranty ] = useState(warranty);
  const [warrantyLines, setWarrantyLines ] = useState({});
  const [errors, setErrors] = useState({});
  const [masters, setMasters] = useState({  users: [], areas: [],  paymentTerms: [], customers: [],currency: [], clients:[],consultants:[] });
  const mandatoryFields = ["ourDOReference"];
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const[lineToBeAdded,setLineToBeAdded]=useState(false);

/*
  useEffect(() => {
    setLoadingContext(true);
    if (warrantyObjContext["jobReference"] != "" && user != null) {

      axiosConfig.get('Warranty?Id=' + warrantyObjContext["jobReference"])
        .then(data => {
          /*let isLocked= false;
          if(data.lockedForEditingBy != null && data.lockedForEditingBy != ""){
           if(data.lockedForEditingBy != user.Id) isLocked = true;
          }
          setWarrantyObjContext({ ...warrantyObjContext});
          setWarranty(data);
          //setQuotationHeaderContext(data);
          
          //if(isLocked)
           // showWarningAlert(["Quotation is locked by User "+ data.lockedForEditingByName]);
        })
        .catch((error) => showNotificationError("Quotation loading error:" + error))
        .then(() => setLoadingContext(false));
    }

  }, [warrantyObjContext["jobReference"],user]);
  */




  const onInputChange = (e) => {
    //for dxSelectBox
    if (e.element != undefined) {
      setWarrantyItem(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));

      if (e.value != "") {
        setErrors(prevState => ({
          ...prevState,
          [e.element.id]: "valid"
        }));
      }
    }
    //for normal inputs
    else {
      setWarrantyItem(prevState => ({
        ...prevState,
        [e.target.id]: e.target.value
      }));

      if (e.target.value != "") {
        setErrors(prevState => ({
          ...prevState,
          [e.target.id]: "valid"
        }));
      }
    }
  }


 

  const setValidationStatus = (id) => {
    return errors[id] != undefined ? errors[id] : "valid";
  }



  const onSaveWarrantyItemClick =(evt) =>{
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
    evt.preventDefault();
    evt.stopPropagation();
    }
    setValidated(true);
    let isFormValid = true;
    /*mandatoryFields.forEach(item => {
        if (warrantyItem[item] == null || warrantyItem[item] == "") {
            setErrors(prevState => ({
            ...prevState,
            [item]: "invalid"
            }));
            isFormValid = false;
        }
    });*/
    if (isFormValid && form.checkValidity()) {
    setLoadingContext(true);
    setErrors({});
    let inputList =[];
    let url ="Warranty/lines";
    let input={...warrantyItem,jobDetailsId:warrantyObjContext.jobDetailsId};
   // if(warrantyItem.ourDoreference != null){ //for costItemTobeAdded
    //  url ="Warranty/lines/update";
    //}
    /*else{
        url ="Quotation/costLine/update";
        data ={
            ...warrantyItem,
            quotationLineCostItems:quotationLinesContext.filter(x => x.prodTypeId == warrantyItem.prodTypeId)      //costItemLinesGrid.current.instance.getSelectedRowsData(),//.map((item) => ({ lineNum: item.lineNum, itemCode: item.itemCode })),
                                .filter(x=> selectedCostLinesKeys.includes(x.lineNum))
                                .map((x) => {return {lineNum:x.lineNum,itemCode:x.itemCode}}),
            quotationNum: quotationObjContext["quotationNum"],
            revNum: quotationObjContext["revNum"]
        };
    }*/
    

    axiosConfig.post(url, JSON.stringify(input) )
        .then(data => {
            
            //setWarranLinesToBeUpdatedContext(true);
            //setCostItemsToBeUpdatedContext(true);
            setShowWarrantyItemEditPopUp(false);
            refreshWarrantyLineSGrid();
            showNotificationSuccess('Warranty Item Line saved');
        })
        .catch((error) => {
            //getCostItemsAndRefreshGrid();
            showNotificationError(`Error in saving warantyItemLine` + error);
        })
        .then(() => setLoadingContext(false));
    }
  }
  const refreshWarrantyLineSGrid =() =>{
    setLoadingContext(true);
    axiosConfig.get('Warranty/' + warrantyObjContext["jobDetailsId"])
    .then(data => {
      /*let isLocked= false;
      if(data.lockedForEditingBy != null && data.lockedForEditingBy != ""){
       if(data.lockedForEditingBy != user.Id) isLocked = true;
      }*/
      //setWarrantyObjContext({ ...warrantyObjContext});
      //setWarranty(JSON.parse(data.header));
      setWarrantyLinesContext(JSON.parse(data.lines));
      //setQuotationHeaderContext(data);
      
      //if(isLocked)
       // showWarningAlert(["Quotation is locked by User "+ data.lockedForEditingByName]);
    })
    .catch((error) => showNotificationError("Quotation loading error:" + error))
    .then(() => setLoadingContext(false));
  }
  const isAdded =()=>{
    return warrantyItem.ourDoreference != null ? false : true;
  }
  const onAddWarrentyItemClick =()=>{
    setWarrantyItem({});
    setShowWarrantyItemEditPopUp(true);
    setLineToBeAdded(true);
  }  
  const onEditButtonClick = (e) => {
    setWarrantyItem(e.row.data);
    setLineToBeAdded(false);
    setShowWarrantyItemEditPopUp(true);
    /*setCostItem(e.row.data);
    setShowCostItemEditPopUp(true);
    setShowFreightCost(false);
    setErrors({});
    freight.forEach(item =>{
        if(e.row.data.costItemId == item.Code)
        setShowFreightCost(true);
    });
    let selectedLines = e.row.data.quotationLineCostItems.map(item=> item.lineNum);
    //costItemLinesGrid.current.instance.selectRows(selectedLines,true);
    setSelectedCostLinesKeys(selectedLines);*/
  }

  const WarrantyDates = ({ warrantyItem, onInputChange }) => {
    // Inline styles for table headers and cells
    const headerStyle = { padding: '10px', textAlign: 'left', borderBottom: '1px solid #ddd' };
    const cellStyle = { padding: '10px' };
  
    // This function is used to render input cells in the table
    const renderInputCell = (id, value) => (
      <td style={cellStyle}>
        <DateBox  id={id} type="date" pickerType="calendar" validationStatus={setValidationStatus(id)}
                onValueChanged={onInputChange} value={value}  displayFormat="dd/MM/yyyy"   />
      </td>
    );
  
    return (
      <div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}> {/* Adding styles here for the table layout */}
          <thead>
            <tr>
              <th style={headerStyle}>Category</th>
              <th style={headerStyle}>Warranty Period Unit</th>
              <th style={headerStyle}>Warranty Period Components</th>
              <th style={headerStyle}>Manufacturers Warranty Period Unit</th>
              <th style={headerStyle}>Manufacturers Warranty Period Components</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={headerStyle}>Start Date</th>
              {renderInputCell('warrantyPeriodUnitStartDate', warrantyItem["warrantyPeriodUnitStartDate"])}
              {renderInputCell('warrantyPeriodComponentsStartDate', warrantyItem["warrantyPeriodComponentsStartDate"])}
              {renderInputCell('manufacturersWarrantyPeriodUnitStartDate', warrantyItem["manufacturersWarrantyPeriodUnitStartDate"])}
              {renderInputCell('manufacturersWarrantyPeriodComponentsStartDate', warrantyItem["manufacturersWarrantyPeriodComponentsStartDate"])}
            </tr>
            <tr>
              <th style={headerStyle}>End Date</th>
              {renderInputCell('warrantyPeriodUnitEndDate', warrantyItem["warrantyPeriodUnitEndDate"])}
              {renderInputCell('warrantyPeriodComponentsEndDate', warrantyItem["warrantyPeriodComponentsEndDate"])}
              {renderInputCell('manufacturersWarrantyPeriodUnitEndDate', warrantyItem["manufacturersWarrantyPeriodUnitEndDate"])}
              {renderInputCell('manufacturersWarrantyPeriodComponentsEndDate', warrantyItem["manufacturersWarrantyPeriodComponentsEndDate"])}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

   return (
        <div>

          <Card>
            <Card.Header className="d-flex justify-content-between">

            <h5>Warranty Item</h5>

            <DxButton
            //width={120}
            //text="Copy Option"
            icon="add"
            //type="normal"
            //stylingMode="contained"
            onClick={onAddWarrentyItemClick}
            />

            </Card.Header>
            <Card.Body>

              <DataGrid
                dataSource={warrantyLinesContext}
                showBorders={true}
                rowAlternationEnabled={true}
                wordWrapEnabled={true}
              >
                <Paging enabled={true} defaultPageSize={10} />
                <Editing allowDeleting useIcons/>

                <Column dataField="ourDoreference" caption="DO Reference" />
                <Column dataField="jobDetailsId" caption="Job Details ID" dataType="number" visible={false}/>
                <Column dataField="dodate" caption="DO Date" dataType="date" format="dd/MM/yyyy"/>
                <Column dataField="invoiceReference" caption="Invoice Reference" />
                <Column dataField="invoiceDate" caption="Invoice Date" dataType="date" format="dd/MM/yyyy"/>
                <Column dataField="product" caption="Product" />
                <Column dataField="manufacturer" caption="Manufacturer" />
                <Column dataField="model" caption="Model" />
                <Column dataField="productSerialNumber" caption="Product Serial Number" />
                <Column dataField="commissioningDate" caption="Commissioning Date" dataType="date" format="dd/MM/yyyy"/>
                <Column dataField="warrantyCommitment" caption="Warranty Commitment" />
                <Column
                caption="Manufacturers Warranty Period Unit"
                isBand={true}
              >
                <Column dataField="warrantyPeriodUnitStartDate" caption="Start Date" dataType="date" format="dd/MM/yyyy"/>
                <Column dataField="warrantyPeriodUnitEndDate" caption="End Date" dataType="date" format="dd/MM/yyyy"/>
                </Column>
                <Column
                caption="Manufacturers Warranty Period Unit"
                isBand={true}
              >
                <Column dataField="warrantyPeriodComponentsStartDate" caption="Start Date" dataType="date" format="dd/MM/yyyy"/>
                <Column dataField="warrantyPeriodComponentsEndDate"  caption="End Date" dataType="date" format="dd/MM/yyyy"/>
                </Column>
                <Column dataField="remarks" caption="Remarks" />
                <Column type="buttons">
                  <DxDataGridButton
                      text="Edit"
                      icon="edit"
                      hint="Edit"
                      onClick={onEditButtonClick} />
                </Column>
                {/* Add the rest of your fields similarly, specifying the caption and dataType where needed */}

                {/* ... other configurations like export, additional editing options, etc. */}
              </DataGrid>

            </Card.Body>
          </Card>
          <Modal
                    show={showWarrantyItemEditPopUp}
                    //onHide={()=> setNewCustomerVisible(false)}
                    size="lg"
                    dialogClassName="modal-90w"
                    //size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header >
                        <Modal.Title>{lineToBeAdded ? "Add" : "Edit"} Warranty Item</Modal.Title>
                        <button onClick={() => setShowWarrantyItemEditPopUp(false)}  className="btn btn-icon me-3">
                            <i className="fe fe-x"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CForm
                            className="row g-3 needs-validation"
                            noValidate
                            validated={validated}
                            onSubmit={onSaveWarrantyItemClick}
                        >
                          <CCol md={4}>
                                <CFormLabel htmlFor="ourDoreference">DO Reference</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="ourDoreference"
                                    onChange={onInputChange}
                                    value={warrantyItem.ourDoreference}
                                    required
                                    readOnly ={lineToBeAdded ? false:true}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="dodate">DO Date</CFormLabel>
                                 <DateBox id="dodate" type="date" pickerType="calendar" validationStatus={setValidationStatus("dodate")}
                onValueChanged={onInputChange} value={warrantyItem["dodate"]} required displayFormat="dd/MM/yyyy"   />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="invoiceReference">Invoice Reference</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="invoiceReference"
                                    onChange={onInputChange}
                                    value={warrantyItem.invoiceReference}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="invoiceDate">Invoice Date</CFormLabel>
                                <DateBox id="invoiceDate" type="date" pickerType="calendar" validationStatus={setValidationStatus("invoiceDate")}
                onValueChanged={onInputChange} value={warrantyItem["invoiceDate"]} required displayFormat="dd/MM/yyyy"   />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="product">Product</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="product"
                                    onChange={onInputChange}
                                    value={warrantyItem.product}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="manufacturer">Manufacturer</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="manufacturer"
                                    onChange={onInputChange}
                                    value={warrantyItem.manufacturer}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="Model">Model</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="model"
                                    onChange={onInputChange}
                                    value={warrantyItem.model}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="productSerialNumber">Product Serial Number</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="productSerialNumber"
                                    onChange={onInputChange}
                                    value={warrantyItem.productSerialNumber}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="commissioningDate">Commissioning Date</CFormLabel>
                                 <DateBox id="commissioningDate" type="date" pickerType="calendar" validationStatus={setValidationStatus("commissioningDate")}
                onValueChanged={onInputChange} value={warrantyItem["commissioningDate"]} required displayFormat="dd/MM/yyyy"   />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="warrantyCommitment">Warranty Commitment</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="warrantyCommitment"
                                    onChange={onInputChange}
                                    value={warrantyItem.warrantyCommitment}
                                />
                            </CCol>
                            {WarrantyDates({warrantyItem,onInputChange})}
                           
                           
                            <CCol md={4}>
                                <CFormLabel htmlFor="manufacturersOrderReference">Manufacturers Order Reference</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="manufacturersOrderReference"
                                    onChange={onInputChange}
                                    value={warrantyItem.manufacturersOrderReference}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="manufacturersInvoiceReference">Manufacturers Invoice Reference</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="manufacturersInvoiceReference"
                                    onChange={onInputChange}
                                    value={warrantyItem.manufacturersInvoiceReference}
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="manufacturersInvoiceDate">Manufacturers InvoiceDate</CFormLabel>
                                   <DateBox id="manufacturersInvoiceDate" type="date" pickerType="calendar" validationStatus={setValidationStatus("manufacturersInvoiceDate")}
                onValueChanged={onInputChange} value={warrantyItem["manufacturersInvoiceDate"]} required displayFormat="dd/MM/yyyy"   />
                            </CCol>
                           

                            <CCol md={12}>
                                <CFormLabel htmlFor="remarks">Remarks</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="remarks"
                                    onChange={onInputChange}
                                    value={warrantyItem.remarks}
                                />
                            </CCol>
                            <CCol  md={{ span: 12, offset: 12 }}>
                                <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3 float-end">
                                    Save
                                </Button>
                            </CCol>
                        </CForm>
                    </Modal.Body>
          </Modal>
        </div>
  );

}

export default WarrantyLines;



