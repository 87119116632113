export const WarrantyItem  = class {
      ourDoreference = null;
      jobDetailsId = null; // for numbers, 'null' implies that the value is not set yet
      doDate = null; // dates are usually either strings or Date objects in JavaScript
      invoiceReference = null;
      invoiceDate = null;
      product = null;
      manufacturer = null;
      model = null;
      productSerialNumber = null;
      commissioningDate = null;
      warrantyCommitment = null;
      warrantyPeriodUnitStartDate = null;
      warrantyPeriodUnitEndDate = null;
      warrantyPeriodComponentsStartDate = null;
      warrantyPeriodComponentsEndDate = null;
      manufacturersOrderReference = null;
      manufacturersInvoiceReference = null;
      manufacturersInvoiceDate = null;
      manufacturersWarrantyPeriodUnitStartDate = null;
      manufacturersWarrantyPeriodUnitEndDate = null;
      manufacturersWarrantyPeriodComponentsStartDate = null;
      manufacturersWarrantyPeriodComponentsEndDate = null;
      remarks = null;
    }