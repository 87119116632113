import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const config ={
    "UploadAHU":{"Desc":"Upload AHU Template","Data":[
        { "Model": 'model1', "Customer reference": "unitTag", "Price": 1234,"OptName":"optName1","Vat":10,"Margin":10}
      ],"Message":"Required cols: Model,Customer reference,Price,OptName" },
    "UploadLines":{"Desc":"Upload Quotation Lines","Data":[
        { "Item Code": 'model1', "Unit Tag": "unitTag", "Qty": 1,"Mtlp":1.1,"Margin%":10,"Vat":10}
      ],"Message":"Required cols: Item Code,Unit Tag,Qty,Mtlp"},
      "UploadWarranty" :{"Desc":"Upload Warranty Lines","Data":[
        {
          "JobDetailsId": "JD12345",
          "ProjectName": "Solar Panel Installation",
          "SalesOrderReference": "SO98765",
          "ClientName": "Green Energy Corp",
          "ConsultantName": "Jane Smith",
          "CustomerName": "Sunshine Homes Ltd",
          "CustomersOrderReference": "COR54321",
          "PaymentTerms": "Net 30",
          "PaymentStatus": "Paid",
          "AreaCode": "NW001",
          "SalesRepresentativeName": "John Doe",
          "WarrantyProvisionCurrency": "USD",
          "WarrantyProvisionPartsTotal": 5000.00,
          "WarrantyProvisionPartsUtilized": 1500.50,
          "WarrantyProvisionPartsBalance": 3499.50,
          "WarrantyProvisionPartsReversed": 0.00,
          "WarrantyProvisionLabourTotal": 2000.00,
          "WarrantyProvisionLabourUtilized": 750.25,
          "WarrantyProvisionLabourReversed": 0.00,
          "WarrantyProvisionLabourBalance": 1249.75
        },{
          "OurDOReference": "DO98765",
          "JobDetailsId": "JD12345",
          "DODate": "2023-08-15",
          "InvoiceReference": "INV87654",
          "InvoiceDate": "2023-08-20",
          "Product": "Solar Panel XYZ-100",
          "Manufacturer": "SunTech Industries",
          "Model": "XYZ-100",
          "ProductSerialNumber": "SN123456789",
          "CommissioningDate": "2023-09-01",
          "WarrantyCommitment": "5 Years Full Coverage",
          "WarrantyPeriodUnitStartDate": "2023-09-01",
          "WarrantyPeriodUnitEndDate": "2028-08-31",
          "WarrantyPeriodComponentsStartDate": "2023-09-01",
          "WarrantyPeriodComponentsEndDate": "2028-08-31",
          "ManufacturersOrderReference": "MO54321",
          "ManufacturersInvoiceReference": "MI76543",
          "ManufacturersInvoiceDate": "2023-08-10",
          "ManufacturersWarrantyPeriodUnitStartDate": "2023-09-01",
          "ManufacturersWarrantyPeriodUnitEndDate": "2028-08-31",
          "ManufacturersWarrantyPeriodComponentsStartDate": "2023-09-01",
          "ManufacturersWarrantyPeriodComponentsEndDate": "2028-08-31",
          "Remarks": "Standard installation, no special conditions noted."
        }
       
      ],"Message":"Required cols: JobDetailsId ,OurDOReference" ,"Sheets":["Header","Lines"]},
}



const QuotationExcelDownloadTemplate = (key) => {
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    
    if (config[key].Sheets != null && Array.isArray(config[key].Sheets)) {
      for (let i = 0; i < config[key].Sheets.length; i++) {
        const sheet = config[key].Sheets[i];
        const data = [config[key].Data[i]];
        if (data) {
          const ws = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(wb, ws, sheet);
        }
      }
    } else {
      const ws = XLSX.utils.json_to_sheet([config[key].Data]);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    }

    // Generate Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, key+'.xlsx');
  };

  return (
    <>
      <a href="#" onClick={generateExcel} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
        {config[key].Desc}
      </a>
      <i>{config[key].Message}</i>
    </>
  );
};

export default QuotationExcelDownloadTemplate;