import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    Editing,
    Lookup,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function UserMaster(props) {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const roles =["STANDARD","ADMIN"];
    useEffect(() => {
        if(loading) {
            getAllUsers();
        }
    }, [loading]);

    const getAllUsers = () => {
        axiosConfig.get("Master/users")
        .then(data => setUsers(data))
        .catch((error) => showNotificationError("Error getting users"+error))
        .then(()=>{
          setLoading(false);
        });
    }
 
    const onRowInserting =(e) =>{
        console.log(e);
        let data =  {firstName:e.data.firstName , lastName:e.data.lastName, email:e.data.email , role :e.data.role,isActive :e.data.isActive};
        axiosConfig.post("Master/Insert/User", JSON.stringify(data))
        .then(data =>  {
            getAllUsers();
            showNotificationSuccess("Added");
        })
        .catch((error) => showNotificationError("Error loading pricing data:"+error))
        .then(()=>{
            setLoading(false);
        });
    }
    const onRowUpdating =(e) =>{
        console.log(e);
        let data ={...e.key,...e.newData};
        axiosConfig.post("Master/Update/User", JSON.stringify(data))
        .then(data =>  {
            getAllUsers();
            showNotificationSuccess("Updated");
        })
        .catch((error) => showNotificationError("Error loading pricing data:"+error))
        .then(()=>{
            setLoading(false);
        });
    }
    return (
        <div>
            <Loader loading={loading}/>
            <ToastContainer />
            <div className="page-header ">
              <div>
              <h1 className="page-title"></h1>
              <Breadcrumb className="breadcrumb">
                  <Breadcrumb.Item className="breadcrumb-item" href="#">
                  User Master
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                  className="breadcrumb-item active breadcrumds"
                  aria-current="page"
                  >
                  
                  </Breadcrumb.Item>
              </Breadcrumb>
              </div>
          </div>
            <Card>
                <Card.Body>

                    <div className="row">
                    <DataGrid
                        dataSource={users}
                        //keyExpr="Id"
                        showBorders={true}
                        columnAutoWidth
                        onRowInserting={onRowInserting}
                        onRowUpdating={onRowUpdating}
                        >
                        <Paging enabled={false} />
                            <Editing
                                mode="form"
                                allowUpdating={true}
                                allowAdding={true} />
                            <Column dataField="firstName" />
                            <Column dataField="lastName" />
                            <Column dataField="email"/>
                            <Column dataField="role" >
                                <Lookup dataSource={roles}  />
                            </Column>
                            <Column dataField="isActive" dataType="boolean"/>
                        </DataGrid>

                    </div>
                </Card.Body>
            </Card>
        </div>);

}

export default UserMaster;