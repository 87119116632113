import FileUpload from '../../Shared/FileUpload';
import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function OptionCode() {
    const [optionCode, setOptionCode] = useState({});
    const [optionCodes, setOptionCodes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        axiosConfig.get("OptCode")
        .then(data => setOptionCodes(data));
    },[loading]);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoading(true);
        axiosConfig.post("OptCode", JSON.stringify(optionCode))
            .then(data =>  setLoading(false));
    }
    const refresh = () =>{
        setLoading(true);
    }


return(
    <div>
    <Loader loading={loading}/>
    <ToastContainer />
    <div className="page-header ">
            <div>
            <h1 className="page-title">Option Codes</h1>
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item className="breadcrumb-item" href="#">
                Item Master
                </Breadcrumb.Item>
                <Breadcrumb.Item
                className="breadcrumb-item active breadcrumds"
                aria-current="page"
                >
                Option Codes
                </Breadcrumb.Item>
            </Breadcrumb>
            </div>
    </div>
    <CForm
        className="row g-3 needs-validation mb-4"
        // noValidate
        // validated={validated}
        onSubmit={handleSubmit}
    >
        <CCol md={4}>
            <CFormLabel htmlFor="seriesName">Option Code</CFormLabel>
            <CFormInput
                type="text"
                id="optCode"
                placeholder="Enter Option Code"
                onChange={e => setOptionCode(prevState => ({
                    ...prevState,
                    OptCode: e.target.value
                }))}
                required
            />
            <CFormFeedback valid>Looks good!</CFormFeedback>
        </CCol>
        <CCol md={4}>
            <CFormLabel htmlFor="optionName">Option Name</CFormLabel>
            <CFormInput
                type="text"
                id="optionName"
                placeholder="Enter Option Name"
                onChange={e => setOptionCode(prevState => ({
                    ...prevState,
                    OptName: e.target.value
                }))}
                required
            />
            <CFormFeedback valid>Looks good!</CFormFeedback>
        </CCol>
        <CCol md={4}>
            <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
            <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                <span>
                    <i className="fe fe-plus"></i>&nbsp;
                </span>
                Add
            </Button>
        </CCol>
    </CForm>
    <Card>
        <Card.Body>

            <div className="row">
            <DataGrid
                        dataSource={optionCodes}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="optCode" />
                        <Column dataField="optName" />
                    </DataGrid>

            </div>
        </Card.Body>
    </Card>
</div>
);

}

export default OptionCode;

/*
    return (
        <div>
            <div className="container">
                <div className="row">
                <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="optCode">Opt Code</label>
                            <input type="text" className="form-control" id="optCode" placeholder="Enter opt code" 
                                onChange={e => setOptionCode(prevState => ({
                                    ...prevState,
                                    OptCode: e.target.value
                                }))}/>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="optName">Opt Name</label>
                            <input type="text" className="form-control" id="optName" placeholder="Enter opt Name" 
                                onChange={e => setOptionCode(prevState => ({
                                    ...prevState,
                                    OptName: e.target.value
                                }))}/>
                        </div>
                    </div>
                    <div className="col-sm">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add</button>
                    </div>
                </div>
                <div className="row">
                    <FileUpload url="OptCode/import/excel" refresh={{refresh}}/>
                  </div>
                <div className="row">
                    <DataGrid
                        dataSource={optionCodes}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="optCode" />
                        <Column dataField="optName" />
                    </DataGrid>

                </div>
            </div>
        </div>);

*/