import SelectBox from 'devextreme-react/select-box';
import { Autocomplete } from 'devextreme-react';
import { useEffect, useRef, useState, useCallback } from "react";
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    ValueAxis,
    ArgumentAxis,
    Label,
    Border,
    Tooltip,
    Export,
  } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  OverlayTrigger,
  //Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Button
} from "react-bootstrap";
import CustomStore from 'devextreme/data/custom_store';
import { Loader } from './Shared/Utils';
import axiosConfig from './config/axiosConfig';
import { Filter } from '@mui/icons-material';
function Dashboard() {
  const palette = ['#00ced1', '#008000', '#ffd700', '#ff7f50'];
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState({});
  const [data, setData] = useState({});
  const [dataSource, setDataSource] = useState({});
  const [masters, setMasters] = useState({ quotationYears:[],users: [], areas: [], quotations: [], brands: [], customers: [], statuses: [], projects: [], products: [] });
 

  useEffect(() => {
    setLoading(true);
    axiosConfig.get("Master/Search/GetAll")
      .then(data => {
        setMasters({
          users: JSON.parse(data["users"]),
          areas: JSON.parse(data["areas"]),
          customers: JSON.parse(data["customers"]),
          statuses: JSON.parse(data["statuses"]),
          quotationYears: JSON.parse(data["quotationYears"]),
          brands: JSON.parse(data["brands"]),
          products: JSON.parse(data["products"]),
          projects: JSON.parse(data["projects"]),
        });
        axiosConfig.post("Quotation/dashboard", JSON.stringify(quotation))
        .then(data => { setLoading(false); setDataSource(data); });
      });
      
  }, []);


  /*const onInputChange = (e) => {
      setQuotation(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));
  }*/
  const onInputChange = (e) => {
    //for dxSelectBox
    if (e.element != undefined) {
      setQuotation(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));
    }
    //for normal inputs
    else {
      setQuotation(prevState => ({
        ...prevState,
        [e.target.id]: e.target.id == "itemCodeWise" ? e.target.checked : e.target.value
      }));
    }
  }

  const handleSearch = (evt) => {
    evt.preventDefault();
      console.log(quotation);
      setLoading(true);
      axiosConfig.post("Quotation/dashboard", JSON.stringify(quotation))
        .then(data => { setLoading(false); setDataSource(data); });
  }

  const handleReset = () =>{ 
    setQuotation({});
  }
  const customizeTooltip = (pointInfo) => {
    return {
      text: `Sales area: ${pointInfo.argumentText} <br/>Total Order value: ${pointInfo.valueText}EUR (${pointInfo.size}%)`,
    };
  }
  
  const seriesClick = (e) => {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }
  
  const customizeText =(e) => {
    return `${e.value}`;
  }
  return (
    <div>
    <Loader loading={loading}/>
     <div className="page-header ">
        <div>
          <h1 className="page-title">Brand-Ordervalue </h1>
        </div>
      </div>
     <Card>
        <Card.Body>
        <Row>
            <Col md={2}>
              <Form.Group>
                <Form.Label>
                  Customer <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["customers"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="customerCode"
                  value={quotation["customerCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>
                  Sales Representative <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                  dataSource={masters["users"]}
                  displayExpr="Name"
                  valueExpr="Id"
                  id="msp"
                  value={quotation["msp"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  Status <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                  dataSource={masters["statuses"]}
                  displayExpr="Name"
                  valueExpr="Id"
                  id="statusId"
                  value={quotation["statusId"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={1} md={1}>
              <Form.Group>
                <Form.Label>
                  Quotation Year <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                  dataSource={masters["quotationYears"]}
                  displayExpr="Name"
                  valueExpr="Id"
                  id="quotationYear"
                  value={quotation["quotationYear"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  From Booking date <span className="text-red">*</span>
                </Form.Label>
                <DateBox id="fromBookingDate" type="date" pickerType="calendar" 
                onValueChanged={onInputChange} value={quotation["fromBookingDate"]} required displayFormat="dd/MM/yyyy"   />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  To Booking date <span className="text-red">*</span>
                </Form.Label>
                <DateBox id="toBookingDate" type="date" pickerType="calendar"
                onValueChanged={onInputChange} value={quotation["toBookingDate"]} required displayFormat="dd/MM/yyyy"   />
              </Form.Group>
            </Col>
            <Col sm={12} md={12}>
              <Row>
              <Col sm={1} md={1}>
              <Form.Group>
                <Form.Label>
                  &nbsp;
                </Form.Label>
              <Button variant="success" onClick={handleSearch} type="submit">
                      Search
                    </Button>
                    </Form.Group>
                    </Col>
                    <Col sm={2} md={2}>
                    <Form.Group>
                    <Form.Label>
                  &nbsp;
                </Form.Label>
                    <Button className='' onClick={handleReset}>
                      Reset
                    </Button>
                    </Form.Group>
                    </Col>
              </Row>
            </Col>

          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <Row>
        <Chart
      id="chart"
      title={'Correlation between Total OrderValue and\n SalesArea Brand wise'}
      palette={palette}
      //onSeriesClick={seriesClick}
      dataSource={dataSource}>
      {
      <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip} />
}
      <CommonSeriesSettings type="bubble" />
      <ValueAxis title="Total Order Value (EUR)">
        <Label customizeText={customizeText} />
      </ValueAxis>
      <ArgumentAxis title="Sales Area">
        <Label customizeText={customizeText} />
      </ArgumentAxis>
        <Series
            name="Aermec"
            valueField="TotalOrderValue1"
            argumentField="AreaName1"
            sizeField="perc1"
            //tagField="tag1"
        />
        <Series
            name="Toshiba"
            valueField="TotalOrderValue2"
            argumentField="AreaName2"
            sizeField="perc2"
            //tagField="tag2"
        />
        <Series
            name="Untes"
            valueField="TotalOrderValue3"
            argumentField="AreaName3"
            sizeField="perc3"
            //tagField="tag3"
        />
      <Legend
        position="inside"
        horizontalAlignment="left"
      >
        <Border visible={true} />
      </Legend>
      <Export enabled={true} />
    </Chart>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );

}

export default Dashboard;