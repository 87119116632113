import SelectBox from 'devextreme-react/select-box';
import { Autocomplete } from 'devextreme-react';
import { useEffect, useRef, useState, useCallback,useContext } from "react";
import DataGrid, {
  Column,
  Editing,
  Format,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem, Item, Toolbar,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,
  FilterRow,
  Export,
  HeaderFilter,
  Button as DXButtonDataGrid
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Modal,
  Form,
  Button
} from "react-bootstrap";
import axiosConfig from '../config/axiosConfig';
import TagBox from 'devextreme-react/tag-box';
import QuotationExcelDownloadTemplate from '../Quotation/QuotationExcelDownloadTemplate';
import { showNotificationSuccess,showNotificationError,showErrorAlert, showWarningAlert,Loader } from '../Shared/Utils';
import UserContext from '../Shared/UserContext';
import { ToastContainer, toast, Slide, Flip } from "react-toastify";

function WarrantyMain() {
  const [loading, setLoading] = useState(false);
  const [warranty, setWarranty] = useState({});
  const [data, setData] = useState({});
  const [masters, setMasters] = useState({ users: [], areas: [], jobs: [], brands: [], customers: [], statuses: [],consultants:[],clients:[] });
  const [jobs,setJobs]=useState([]);
  const [popupUploadVisible,setPopupUploadVisible] = useState(false);
	const [isFilePicked, setIsFilePicked] = useState(false);
  const {user} =useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    setLoading(true);
    axiosConfig.get("Master/Search/GetAll")
      .then(data => {
        setMasters({
          users: JSON.parse(data["users"]),
          areas: JSON.parse(data["areas"]),
          customers: JSON.parse(data["customers"]),
          clients: JSON.parse(data["clients"]),
          consultants: JSON.parse(data["consultants"]),
          statuses: JSON.parse(data["statuses"]),
          //jobs : JSON.parse(data["jobs"])
          //quotationYears: JSON.parse(data["quotationYears"]),
          //brands: JSON.parse(data["brands"]),
          //products: JSON.parse(data["products"]),
          //projects: JSON.parse(data["projects"]),
        });
      });
      axiosConfig.get("Warranty/all")
        .then(data => {
          setLoading(false);
          setJobs(data);
        });
      
  }, []);


  const onInputChange = (e) => {
    //for dxSelectBox
    if (e.element != undefined) {
      setWarranty(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));
    }
    //for normal inputs
    else {
      setWarranty(prevState => ({
        ...prevState,
        [e.target.id]: e.target.id == "itemCodeWise" ? e.target.checked : e.target.value
      }));
    }
  }

  const handleSearch = (evt) => {
    evt.preventDefault();
      console.log(warranty);
      setLoading(true);
      axiosConfig.post("Warranty/Search", JSON.stringify(JSON.stringify(warranty)))
        .then(data => { setLoading(false); setData(data); });
  }

  const generateQuotlink =(e) =>{
    console.log(e);
    let link = "/Quotations/"+e.row.data.quotationNum;
    return <a target={"_blank"} href={link}><b>{e.row.data.quotationNum}</b></a>;
  }
  const handleReset = () =>{ 
    setWarranty({});
  }
  const generateJoblink =(e) =>{
    console.log(e);
    let link = "/Warranty/"+e.row.data.jobDetailsId;
    return <a target={"_blank"} href={link}><b>{e.row.data.salesOrderReference}</b></a>;
  }
  const onValueChanged = useCallback((e) => {
    setWarranty(prevState => ({
      ...prevState,
      [e.element.id]: e.value
    }));
}, []);
const onFileChange =(event) =>{
  setSelectedFile(event.target.files[0]);
  setIsFilePicked(true);
}
  const onSaveWarrantyClick =(e) =>{
    e.preventDefault();
    if(!isFilePicked){
      showWarningAlert(["Load an excel file."]);
      return;
    }
    setLoading(true);
    const formData = new FormData();
		formData.append('File', selectedFile);
    formData.append('CreatedBy', user.Id);
    let url = "Warranty/import/excel";
    axiosConfig.post(url, formData)
        .then(data => {
              showNotificationSuccess("Warranty uploaded successfully.");
        })
        .catch((error) => {
          if(error.response.status == 422){
            showErrorAlert(error.response.data);
          }
          else {
            showNotificationError("Error in saving Warranty :"+error)
          } 
       })
       .then(()=>setLoading(false));
  }
  return (
    <div>
    <Loader loading={loading}/>
    <ToastContainer />
     <div className="page-header ">
        <div>
          <h1 className="page-title">Search Warranty</h1>
        </div>
         <div className="ms-auto pageheader-btn">
          <Link to={"/Warranty"}  className="btn btn-primary btn-icon text-white me-3">
            <span>
              <i className="fe fe-plus"></i>&nbsp;
            </span>
            Add Warranty
          </Link>
          <Link onClick={() => setPopupUploadVisible(true)}  className="btn btn-primary btn-icon text-white me-3">
            <span>
              <i className="fe fe-plus"></i>&nbsp;
            </span>
            Upload Warranty
          </Link>
        </div>
      </div>
     <Card>
        <Card.Body>
        <Row>
            <Col sm={4} md={4}>
              <Form.Group>
                <Form.Label>
                  Job Reference <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                  dataSource={jobs}
                  searchEnabled={true}
                  searchMode={'contains'}
                  //searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  //displayExpr="Name"
                  //valueExpr="Code"
                  id="salesOrderReference"
                  value={warranty["salesOrderReference"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Customer <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["customers"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="customerCode"
                  value={warranty["customerCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Client <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["clients"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="clientCode"
                  value={warranty["clientCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Consultant <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["consultants"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="consultantCode"
                  value={warranty["consultantCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  Area
                </Form.Label>
                <TagBox dataSource={masters["areas"]}
                        displayExpr="Name"  
                        id="areaCode"
                        onValueChanged={onValueChanged}
                        value ={warranty["areaCode"]}
                        valueExpr="Code" />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>
                  Sales Representative
                </Form.Label>
                <TagBox dataSource={masters["users"]}
                        displayExpr="Name"  
                        id="msp"
                        onValueChanged={onValueChanged}
                        value ={warranty["msp"]}
                        valueExpr="Id" />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  Payment Status
                </Form.Label>
                <TagBox dataSource={masters["statuses"]}
                        displayExpr="Name"  
                        id="statusId"
                        onValueChanged={onValueChanged}
                        value ={warranty["statusId"]}
                        valueExpr="Id" />
              </Form.Group>
            </Col>
            
            <Col sm={12} md={12}>
              <Row>
              <Col sm={1} md={1}>
              <Form.Group>
                <Form.Label>
                  &nbsp;
                </Form.Label>
              <Button variant="success" onClick={handleSearch} type="submit">
                      Search
                    </Button>
                    </Form.Group>
                    </Col>
                    <Col sm={2} md={2}>
                    <Form.Group>
                    <Form.Label>
                  &nbsp;
                </Form.Label>
                    <Button className='' onClick={handleReset}>
                      Reset
                    </Button>
                    </Form.Group>
                    </Col>
              </Row>
            </Col>

          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <Row>
        <DataGrid
                dataSource={data}
                showBorders={true}
                rowAlternationEnabled={true}
                wordWrapEnabled={true}
              >
                <Paging enabled={true} defaultPageSize={10} />

                <Column dataField="salesOrderReference" caption="Job Refernce" cellRender={generateJoblink}/>
                <Column dataField="jobDetailsId" caption="Job Details ID" dataType="number" visible={false}/>
                <Column dataField="clientName" caption="Client"  />
                <Column dataField="consultantName" caption="Consultant" />
                <Column dataField="customerName" caption="Customer" /> 
                <Column dataField="areaName" caption="Area" />
                <Column dataField="customersOrderReference" caption="Customers Order Reference" />
                <Column dataField="paymentTerms" caption="Payment Terms" />
                <Column dataField="currencyCode" caption="Currency" />
                <Column dataField="salesRep" caption="SalesRep" />
                <Column
                caption=" Warranty Provision Parts"
                isBand={true}
              >
                <Column dataField="warrantyProvisionPartsTotal" caption="Total" dataType="number"/>
                <Column dataField="warrantyProvisionPartsUtilized" caption="Utilized" dataType="number"/>
                <Column dataField="warrantyProvisionPartsReversed" caption="Reversed" dataType="number"/>
                <Column dataField="warrantyProvisionPartsBalance" caption="Balance" dataType="number"/>

                </Column>
                <Column
                caption="Warranty Provision Labour"
                isBand={true}
              >
               <Column dataField="warrantyProvisionLabourTotal" caption="Total" dataType="number"/>
                <Column dataField="warrantyProvisionLabourUtilized" caption="Utilized" dataType="number"/>
                <Column dataField="warrantyProvisionLabourReversed" caption="Reversed" dataType="number"/>
                <Column dataField="warrantyProvisionLabourBalance" caption="Balance" dataType="number"/>
                </Column>


              </DataGrid>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={popupUploadVisible}
        onHide={()=>setPopupUploadVisible(false)}
        //size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title>Import Warranty</Modal.Title>
          <button onClick={()=>setPopupUploadVisible(false)}  className="btn btn-icon me-3">
                    <i className="fe fe-x"></i>
                </button>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
            <label htmlFor="QuotationNum">Excel file </label>
            <input className="form-control" onChange={onFileChange} type="file" id="formFile" />
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
            {QuotationExcelDownloadTemplate("UploadWarranty")}
          <Button variant="success" onClick={onSaveWarrantyClick}>Upload</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}

export default WarrantyMain;