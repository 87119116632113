import { useEffect, useRef, useState,useContext } from "react";
import { useParams } from "react-router-dom";
import QuotationCostItem from './QuotationCostItem';
import QuotationLines from './QuotationLines';
import { Link } from "react-router-dom";
import axiosConfig from '../config/axiosConfig';
import SelectBox from 'devextreme-react/select-box';
import MyContextProvider from '../Shared/MyContext';
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button
} from "react-bootstrap";
import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
} from "@coreui/react";
import DataGrid, {
  Column,
  FilterRow,
  Editing,
  Format,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem, Item, Toolbar,Lookup,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,
  Button as DXButtonDataGrid
} from 'devextreme-react/data-grid';
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert } from '../Shared/Utils';
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import QuotationHeader from './QuotationHeader';
import MyContext from '../Shared/MyContext';
import { useNavigate } from "react-router-dom";
import UserContext from "../Shared/UserContext";

function QuotationMain() {
  const { quotationId } = useParams();
  const {quotationObjContext,quotationHeaderContext,setQuotationObjContext,loadingContext,setLoadingContext,setQuotationHeaderContext }= useContext(MyContext);
  const {user} =useContext(UserContext);
  const customerGrid = useRef();
  const [newCustomerVisible, setNewCustomerVisible] = useState(false);
  const [showRevisionPopUp, setShowRevisionPopUp] = useState(false);
  const [customer, setCustomer] = useState({});
  const [customers, setCustomers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [linkPbd, setLinkPbd] = useState("");
  const [validated, setValidated] = useState(false);
  const [allRevisions,setAllRevisions]=useState([]);
  const [activeRevision,setActiveRevision]=useState("");
  const [revision, setRevision]=useState("");
  const buyerTypes =[{Id:5,Name:"Client"},{Id:4,Name:"Customer"},{Id:6,Name:"Consultant"}];
  const [lockedbyMe ,setLockedByMe]=useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //setLoadingContext(true);
    if (quotationId != undefined) {
        setQuotationObjContext({...quotationObjContext, quotationNum:quotationId});
    }
  }, []);

  useEffect(() => { 
    if(quotationObjContext.quotationNum !=  "" && quotationObjContext.revNum !=  null){
        setLinkPbd("/Quotation/PBD?QuotationNum="+quotationObjContext["quotationNum"]+"&revNum="+quotationObjContext["revNum"]); 
    }
  }, [quotationObjContext["quotationNum"] && quotationObjContext["revNum"]])

  useEffect(() => { 
    if(quotationHeaderContext.quotationNum !=  "" && quotationHeaderContext.revNum !=  null && user != null){
      quotationHeaderContext.lockedForEditingBy == user.Id ? setLockedByMe(true):setLockedByMe(false);
    }
  }, [quotationHeaderContext["quotationNum"] && quotationHeaderContext["revNum"],user])


  const onClickNewCustomerPopUp =() =>{
      setNewCustomerVisible(true);
      axiosConfig.get("master/area/all")
          .then((data) => {
              setAreas(data); 
              setCustomer((prevState) => ({ ...prevState, code: data[0].Code,type:4 }))
          })
          .catch(error => console.log(error));
           
  }
  const onInputChangeOfBuyer =(e) =>{
    //for dxSelectBox
    if (e.element != undefined) {
      setCustomer(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));
      if(e.element.id == "type"){
        axiosConfig.get("master/buyers/all?type=" + e.value)
        .then((data) => {
          setCustomers(data);
      })
      .catch(error => console.log(error));
      }
    }
    //for normal inputs
    else {
      setCustomer(prevState => ({
        ...prevState,
        [e.target.id]: e.target.value
      }));
    }
  }
  const onAddNewCustomerClick =(evt) =>{
  
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    if(form.checkValidity()) {
      setLoadingContext(true);
      axiosConfig.post('master/buyer/add?code='+customer.code+'&name='+customer.name+'&type='+customer.type)
      .then((data) => {
        let _list = customers;
        _list.push({Code:customer.code,Name:customer.name});
        setCustomer({});
        setCustomers(_list);
        showNotificationSuccess("Customer added");
        customerGrid.current.instance.refresh();
      })
      .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in saving customer :"+error)
        } 
    })
    .then(()=>setLoadingContext(false));
    }
    evt.preventDefault();
  }
  const onClickAddQuotLink =(e) =>{
    navigate("/Quotations");
    window.location.reload(false);
  }
  const onClickOpenRevisionPopUpLink =(e) =>{
    setShowRevisionPopUp(true);
    getAllRevisions();
  }
  const getAllRevisions = () =>{
    axiosConfig.get('Quotation/allrevisions?Id='+quotationObjContext["quotationNum"])
    .then(data => {
      setAllRevisions(data);
      let actRev = data.filter((item) => item.IsActiveRevision == true ); 
      if(actRev.length > 0)
        setActiveRevision(actRev[0].name);
      else
        showNotificationError("No active revision is been found");
      })
    .catch((error) => console.log(error));
  }
  const onChangeRevisionClick =(e) =>{
    setLoadingContext(true);
    setShowRevisionPopUp(false);
    axiosConfig.get("Quotation?Id=" + quotationObjContext["quotationNum"]+"&revNum="+revision["name"])
      .then(data => {
        setQuotationHeaderContext(data);
        //setIsQuotationHeaderToBeUpdatedContext(true);
        setQuotationObjContext({quotationNum:quotationObjContext["quotationNum"],revNum:revision["name"]});
        setLoadingContext(false);
      })
      .catch((error) => showNotificationError("Error in loading quotation of revnum:"+ revision["name"] + error));
  }
  const onSetActiveRevisionClick =(e) =>{
    setLoadingContext(true);
    axiosConfig.get("Quotation/setActiveRevision?Id=" + quotationObjContext["quotationNum"]+"&revNum="+revision["name"])
      .then(data => {
        showNotificationSuccess("R"+revision["name"]+" active revision has been set");
        setLoadingContext(false);
      })
      .catch((error) => showNotificationError("Error in loading quotation of revnum:"+ revision["name"] + error));
  }
  const onCreateNewRevisionClick =(e) =>{
    setLoadingContext(true);
    axiosConfig.get("Quotation/createRevision?Id=" + quotationObjContext["quotationNum"]+"&revNum="+revision["name"])
    .then(data => {
      getAllRevisions();
      showNotificationSuccess("New revsision created successfully");
      setLoadingContext(false);
    })
    .catch((error) => showNotificationError("Error in loading quotation of revnum:"+ revision["name"] + error));
  }
  const onLockUnLockQuotation =(e) =>{
    let data = JSON.stringify({quotationNum:quotationObjContext.quotationNum,revNum:quotationObjContext.revNum,userId:user.Id});
    axiosConfig.post("Quotation/lockOrUnlock",JSON.stringify(data))
    .then((data)=>{
      showNotificationSuccess("Quotation "+ (lockedbyMe ? "unLocked" :"Locked"));
      if(!lockedbyMe){ //now its ben locked
        setLockedByMe(true);
      }
      else
        setLockedByMe(false);
      
     
      //setQuotationObjContext((prevState)=>({...prevState,isLocked : !quotationObjContext.isLocked }));
    })
    .catch(()=>{});
  }
  const createWordDocument = async (data) => {
    setLoadingContext(true);
    try {
      const response = await axiosConfig.post("quotation/generate/word", data, { responseType: 'blob' });
      return response;
    } catch (error) {
      console.error('Error creating word document:', error);
      throw error;
    }finally{
      setLoadingContext(false);
    }
  };
  const onGenerateQuotation = async () => {
    try {
      const data = {"quotationNum":quotationHeaderContext["quotationNum"]};
      const fileData = await createWordDocument(data);
  
      // Create a URL for the file
      //const url = window.URL.createObjectURL(new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));

      const url = window.URL.createObjectURL(new Blob([fileData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', quotationHeaderContext["quotationNum"]+'.docx'); // or any other filename
      document.body.appendChild(link);
      link.click();
  
      // Clean up and revoke the URL
      //link.parentNode.removeChild(link);
      //window.URL.revokeObjectURL(url);

       // clean up "a" element & remove ObjectURL
       document.body.removeChild(link);
       URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the document:', error);
    }
  };

  return (
    <div>
      
            <Loader loading={loadingContext}/>
            <ToastContainer />
            <div className="page-header" style={quotationObjContext.isLocked ? {pointerEvents: "none", opacity: "0.7"}:{}}>
                <div>
                <h1 className="page-title">Quotation</h1>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item className="breadcrumb-item" href="#">
                    Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                    className="breadcrumb-item active breadcrumds"
                    aria-current="page"
                    >
                    Quotation
                    </Breadcrumb.Item>
                </Breadcrumb>
                </div>
                <div className="ms-auto pageheader-btn">
                <Link to={"#"} onClick={onClickNewCustomerPopUp} className="btn btn-primary btn-icon text-white me-3">
                    <span>
                    <i className="fe fe-plus"></i>&nbsp;
                    </span>
                    Add Buyer
                </Link>
                {quotationObjContext["quotationNum"] != "" && <Link onClick={onGenerateQuotation}  className="btn btn-primary btn-icon text-white me-3">
                    <span>
                    <i className="fe fe-edit"></i>&nbsp;
                    </span>
                    Generate Quotation Word
                </Link>}
                {quotationObjContext["quotationNum"] != "" && <Link onClick={onLockUnLockQuotation}  className={"btn" +(lockedbyMe ? " btn-secondary ": " btn-primary " )+"btn-icon text-white me-3"}>
                    <span>
                    <i className = {"fe" + (lockedbyMe ? " fe-unlock ":" fe-lock ")}></i>&nbsp;
                    </span>
                   {lockedbyMe ? "UnLock for Editing" : "Lock for Editing"} 
                </Link>}
                {quotationObjContext["quotationNum"] != "" && <Link onClick={onClickAddQuotLink}  className="btn btn-primary btn-icon text-white me-3">
                    <span>
                    <i className="fe fe-plus"></i>&nbsp;
                    </span>
                    Add Quotation
                </Link>}
                {quotationObjContext["quotationNum"] != "" && <Link onClick={onClickOpenRevisionPopUpLink}  className="btn btn-primary btn-icon text-white me-3">
                    <span>
                    <i className="fe fe-edit"></i>&nbsp;
                    </span>
                    Change revision
                </Link>}
                {quotationObjContext["quotationNum"] != "" &&<Link to={linkPbd} target="_blank" className="btn btn-success btn-icon text-white">
                    <span>
                    <i className="fe fe-log-in"></i>&nbsp;
                    </span>
                    PBD
                </Link>}
                </div>
            </div>
            <QuotationHeader />
            {quotationObjContext["quotationNum"] != "" && <QuotationLines  />  } 
            {quotationObjContext["quotationNum"] != "" && <QuotationCostItem />}

            <Modal
                show={newCustomerVisible}
                //onHide={()=> setNewCustomerVisible(false)}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header >
                <Modal.Title>New Buyer</Modal.Title>
                  <button onClick={()=> setNewCustomerVisible(false)}  className="btn btn-icon me-3">
                      <i className="fe fe-x"></i>
                  </button>
                </Modal.Header>
                <Modal.Body>
                <CForm
                    className="row g-3 needs-validation"
                    noValidate
                    validated={validated}
                    onSubmit={onAddNewCustomerClick}
                    >
                    <CCol md={2}>
                        <CFormLabel htmlFor="type">Type</CFormLabel>
                        <SelectBox
                            dataSource={buyerTypes}
                            //validationStatus={setValidationStatus("type")}
                            id="type"
                            displayExpr="Name"
                            valueExpr="Id"
                            value={customer["type"]}
                            onValueChanged={onInputChangeOfBuyer}
                            //readOnly={costItem.quotationCostItemGroupId != null ? true : false}
                        />
                      </CCol>
                    <CCol md={4}>
                        <CFormLabel htmlFor="code">Sales Area</CFormLabel>
                         <SelectBox
                            dataSource={areas}
                            //validationStatus={setValidationStatus("areaCode")}
                            //placeholder="Select Item Group"
                            displayExpr="Name"
                            valueExpr="Code"
                            id="code"
                            value={customer["code"]}
                            onValueChanged={onInputChangeOfBuyer}
                            required
                          />
                    </CCol>
                    <CCol md={4}>
                        <CFormLabel htmlFor="name">Customer Name</CFormLabel>
                        <CFormInput
                        type="text"
                        id="name"
                        onChange={(e) => setCustomer((prevState) => ({ ...prevState, name: e.target.value }))}
                        //defaultValue="Mark"
                        value={customer["name"]}
                        required
                        />
                        <CFormFeedback valid>Looks good!</CFormFeedback>
                    </CCol>
                    <CCol md={2}>
                        <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
                    <Button type="submit" variant="success"className="btn btn-primary btn-icon text-white me-3">
                        <span>
                        <i className="fe fe-plus"></i>&nbsp;
                        </span>
                        Add
                    </Button>
                    </CCol>
                </CForm>
                <div className='row mt-4'>
                <DataGrid
                        dataSource={customers}
                        showBorders={true}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        ref={customerGrid}
                        //ref={quotationSelectedOptionGrid}
                    >
                        <Paging defaultPageSize={10} enabled={true} />
                        <FilterRow visible={true} />
                        <Column dataField="Code" />
                        <Column dataField="Name" />

                </DataGrid>
                </div>
                </Modal.Body>
            </Modal> 
            <Modal
                show={showRevisionPopUp}
                //onHide={()=> setNewCustomerVisible(false)}
                //size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header >
                <Modal.Title>Revision Operations</Modal.Title>
                <button onClick={()=> setShowRevisionPopUp(false)}  className="btn btn-icon me-3">
                    <i className="fe fe-x"></i>
                </button>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                <CCol md={4}>
                    <Button variant="success" className="me-1" onClick={onChangeRevisionClick}>
                        Change
                    </Button>
                  </CCol>
                  <CCol md={4}>
                    <Button variant="success" className="me-1" onClick={onSetActiveRevisionClick}>
                        Set Active
                    </Button>
                  </CCol>
                  <CCol md={4}>
                    <Button variant="success" className="me-1" onClick={onCreateNewRevisionClick}>
                       Create
                    </Button>
                  </CCol>
                  </Row>
                <CCol md={4}>
                    <CFormLabel htmlFor="revsiion">Revsion On</CFormLabel>
                      <SelectBox
                        dataSource={allRevisions}
                        //validationStatus={setValidationStatus("areaCode")}
                        //placeholder="Select Item Group"
                        displayExpr="RevNum"
                        valueExpr="RevNum"
                        id="revision"
                        value={"R"+revision["name"]}
                        onValueChanged={(e) => setRevision((prevState) => ({ ...prevState, name: e.value.replace("R","")  }))}
                        required
                      />
                </CCol>
                 
                </Modal.Body>
            </Modal>
    </div>
  );

}

export default QuotationMain;