import { useEffect, useRef, useState,useCallback,useContext } from "react";
import CustomStore from 'devextreme/data/custom_store';
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
    CFormText,
  } from "@coreui/react";
  import QuotationCAF from './QuotationCAF';
import DataGrid, {
  Column,
  Editing,
  Format,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem, Item, Toolbar,Lookup,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,FilterRow,
  Export,
  RemoteOperations,
  Button as DXButtonDataGrid
} from 'devextreme-react/data-grid';
import {  Button  as DxButton } from 'devextreme-react/button' ;
import SelectBox from 'devextreme-react/select-box';
import { Autocomplete } from 'devextreme-react';
import axiosConfig from '../config/axiosConfig';
import QuotationCopyOption from './QuotationCopyOption';
import QuotationOptions from './QuotationOptions';
import MyContext from '../Shared/MyContext';
import UserContext from '../Shared/UserContext';
import { showNotificationSuccess,showNotificationError,showErrorAlert, showWarningAlert,Loader } from '../Shared/Utils';
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { CheckBox } from "devextreme-react";
import QuotationExcelDownloadTemplate from "./QuotationExcelDownloadTemplate";
function QuotationLines() {
  const quotationLineGrid = useRef();
  const currencyGrid = useRef();
  const copyQuotationLineGrid = useRef();
  const {quotationObjContext,setQuotationLinesContext,quotationLinesContext,quotationHeaderContext,
    isQuotationLinesToBeUpdatedContext,setLinesToBeUpdatedContext,setCostItemsToBeUpdatedContext,productsContext}= useContext(MyContext);
  const {user} =useContext(UserContext);
  const [quotationLine, setQuotationLine] = useState({});
  const [popupVisibleCopyOption, setPopupVisibleCopyOption] = useState(false);
  const [popupPricingVisible, setPopupPricingVisible] = useState(false);
  const [popupUploadSettings, setPopupUploadSettings] = useState({visible:false,isAHU:false});
  const typesOfUpdate =["VAT%","Mtlp","Margin%","Delete","CAF"];
  const vatTypes =[{code:15 , name:"15%"},{code:5 , name:"5%"},{code:0 , name:"0%"}];
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [showUpdateLinePopUp,setShowUpdateLinePopUp]= useState(false);
  const [showImportLinePopUp,setShowImportLinePopUp]= useState(false);
  const [selectedUpdateLinesKeys,setSelectedUpdateLinesKeys]= useState([]);
  const [updateLines,setUpdateLines]=useState({});
  const [productGroup,setProductGroup]=useState({});
  const [productCAF,setProductCAF] = useState([]);
  const [copyFromQuotation,setCopyFromQuotation]=useState({quotations:[],fromQuotationNum:"",toQuotationNum:"",toRevNum:"",data:[],lines:[]});

  useEffect(() => { 
        if(quotationObjContext.quotationNum !=  "" && quotationObjContext.revNum !=  null)
          refreshLinesGrid(quotationObjContext.quotationNum,quotationObjContext.revNum);
  }, [quotationObjContext["quotationNum"] && quotationObjContext["revNum"]])


  useEffect(() => { 
    if(isQuotationLinesToBeUpdatedContext){
      refreshLinesGrid(quotationObjContext.quotationNum,quotationObjContext.revNum);
      setLinesToBeUpdatedContext(false);
    }
  }, [isQuotationLinesToBeUpdatedContext])


  const  isNotEmpty =(value) => {
    return value !== undefined && value !== null && value !== '';
  }
  const setValidationStatus =(id) =>{
        return errors[id] != undefined ? errors[id] : "valid";
  }
  const renderAutoComplete = (cellInfo) => {
    const setEditedValue = valueChangedEventArg => {
        if(cellInfo.row.isNewRow != undefined){
          if(cellInfo.row.isNewRow){
            cellInfo.setValue(valueChangedEventArg.value);
          }
        }
    }
    if(cellInfo.row.isNewRow != undefined){
    return (
      <Autocomplete
        minSearchLength={3}
        dataSource={new CustomStore({
          key: 'code',
          useDefaultSearch: true,
          load(loadOptions) {
            if(loadOptions.filter != undefined)
            return axiosConfig.get(`ItemCode/filter?searchString=`+loadOptions.filter[0][2])
              .then((data) => ({
                data: data,
              }))
              .catch(() => { throw new Error('Data Loading Error'); });
          },
        })}
        valueExpr="name"
         defaultValue={cellInfo.value}
         //value={cellInfo.value}
        onItemClick={onItemClick}
        onValueChanged={setEditedValue}
      />
    )}
    else
    return <>{cellInfo.value}</>

  }

  const onItemClick =(e) =>{
    console.log(e);
    //GET DEFAULT MULTIPLIER
    axiosConfig.get(`ItemCode/mtlp?itemCode=`+e.itemData.code)
    .then((data) => {
      quotationLineGrid.current.instance.cellValue(0, "mtlp", data);
    })
    .catch(() => { throw new Error('Data Loading Error'); });
  }

  const onRowInserted = (e) => {
    console.log(e);
    let intputObj = {
      ...e.data,
      ...quotationHeaderContext,
      createdBy :user.Id
    }

    setLoading(true);
    axiosConfig.post('Quotation/line', JSON.stringify(intputObj))
      .then(data => {
        if (quotationLinesContext.length > 0){
          setQuotationLinesContext(
            quotationLinesContext.map((item) => {
              return item.__KEY__ === e.data.__KEY__ ? data : item;
            }));
          }
        else {
        }
        setCostItemsToBeUpdatedContext(true);
      })
      .catch((error) => {
        let filteredData = quotationLinesContext.filter((item) =>  item.__KEY__ != e.data.__KEY__ );
        quotationLineGrid.current.instance.option("dataSource", filteredData);
        setQuotationLinesContext(filteredData);
        
          if(error.response.status == 422){
            showErrorAlert(error.response.data);
          }
          else {
            showNotificationError("Error in saving Quotation Line :"+error)
          } 
       })
      .then(() => {
        setLoading(false);
        quotationLineGrid.current.instance.refresh();
      });
  }
  const onRowUpdated = (e) => {
    console.log(e);
    let p = { ...e.oldData, ...e.newData, updatedBy :user.Id };
    console.log(p);
    axiosConfig.post('Quotation/line/update',JSON.stringify(p))
      .then((data) => {
        //e.data = data; 
        //let rowIndex =  quotationLineGrid.current.instance.getRowIndexByKey(e.key);
        //quotationLineGrid.current.instance.repaintRows([rowIndex]); 
        let sourceData = quotationLineGrid.current.instance.option("dataSource");
        for (let i = 0; i < sourceData.length; i++) {
          if (sourceData[i]["lineNum"] == data["lineNum"]) {
              sourceData[i]["ttNetPrice"] = data["ttNetPrice"];
              sourceData[i]["costItemLineValue"] = data["costItemLineValue"];
              sourceData[i]["qty"] = data["qty"];
              sourceData[i]["mtlp"] = data["mtlp"];
              sourceData[i]["margin"] = data["margin"];
              //sourceData[i]["itemCode"] = data["itemCode"]; 
              sourceData[i]["vat"] = data["vat"];
              sourceData[i]["unitPrice"] = data["unitPrice"];
              sourceData[i]["ttCostPrice"] = data["ttCostPrice"];
              sourceData[i]["ttSlsPrice"] = data["ttSlsPrice"];
              sourceData[i]["ttSlsPriceWTVat"] = data["ttSlsPriceWTVat"];
            break;
          }
        }
        setCostItemsToBeUpdatedContext(true);
        quotationLineGrid.current.instance.option("dataSource", sourceData);
        quotationLineGrid.current.instance.refresh();;
      })
      .catch((error) => {
          setLinesToBeUpdatedContext(true);
          if(error.response.status == 422){
            showErrorAlert(error.response.data);
          }
          else {
            showNotificationError("Error in updating Quotation Line :"+error)
          } 
       })
      .then(() => {
        setLoading(false);
      });
  }
  const onRowRemoving = (e) => {
    setLoading(true);
    axiosConfig.post('Quotation/line/delete', JSON.stringify(e.data))
        .then((data) => {
            setCostItemsToBeUpdatedContext(true);
            showNotificationSuccess("Quotation Line deleted");
        })
        .catch((error) => {
            refreshLinesGrid(quotationObjContext.quotationNum,quotationObjContext.revNum);
            showNotificationError("Error in remving Quotation Line :"+error);
         })
        .then(() => setLoading(false));
  }
  const customizeText = useCallback((e) => {
    let currencyCode =  quotationHeaderContext.currencyCode; //quotationLines.length > 0 ? quotationLines[0].currencyCode :"";
    return (e.value).toLocaleString('en-US', {minimumFractionDigits: 2}) + ' ' + currencyCode;
  }, [quotationHeaderContext]);

  const onCopyOptionBtnClick = (e) => {
    setPopupVisibleCopyOption(true);
    e.event.preventDefault();
  }
  const onPriceBtnClicked = (e) => {
    setQuotationLine(e.row.data);
    setPopupPricingVisible(true);
  }
  const refreshLinesGrid = (quotationNum,revNum) =>{
    setLoading(true);
      axiosConfig.get("Quotation/lines?Id=" + quotationNum+ "&revNum="+revNum)
      .then(data => {
        setLoading(false);
        setQuotationLinesContext(data);
        quotationLineGrid.current.instance.option("dataSource", data);
      })
      .catch((error) => showNotificationError("Lines could not be loaded:" + error))
      .then(() => setLoading(false));
  }
  const calculateCustomSummary =(options)=>{
    if(options.name == "unitPrice" || options.name == "ttSlsPrice" || options.name == "ttSlsPriceWTVat"
    || options.name == "costItemLineValue" || options.name == "ttCostPrice" || options.name == "ttNetPrice") {
      switch(options.summaryProcess) {
        case "start":
            options.totalValue = 0;
            break;
        case "calculate":
            if(options.value.activeLine)
              options.totalValue += options.value[options.name];
            break;
        //case "finalize":
            // Assigning the final value to "totalValue" here
           // break;
    }
  }
  }
 
  const onEditorPrepared = (e) => {  
    if (e.row.data.qty && e.dataField == 'qty') {  
      setTimeout(() => {  
        e.component.focus(e.editorElement);
      }, 100)  
    }  
  }  
  const onRowValidating =(e) =>{
  if((e.newData.qty == undefined || e.newData.qty == null || e.newData.qty ==0)) {
    if(e.oldData != undefined){
      if(e.oldData.qty == undefined || e.oldData.qty == null || e.oldData.qty ==0){
        e.isValid = false;
        e.errorText = "Qty is required and cannot be 0";
      }
    }
    else{
      e.isValid = false;
      e.errorText = "Qty is required and cannot be 0";
    }
   
  }
  if(e.newData.mtlp == undefined || e.newData.mtlp == null || e.newData.mtlp ==0) {
    if(e.oldData != undefined){
      if(e.oldData.mtlp == undefined || e.oldData.mtlp == null || e.oldData.mtlp ==0){
        e.isValid = false;
        e.errorText = "Mtlp is required and cannot be 0";
      }
    }
    else{
      e.isValid = false;
      e.errorText = "Mtlp is required and cannot be 0";
    }
  }
  if(e.oldData == undefined){ // for new row
    if(e.newData.itemCode == undefined || e.newData.itemCode == null) {
      e.isValid = false;
      e.errorText = "ItemCode is required";
    }
  }
  }

  const onUploadLinesClick =(e) =>{
    setPopupUploadSettings({visible:true,isAHU:false});
    e.event.preventDefault();
  }
  const onUploadAHULinesClick =(e) =>{
    setPopupUploadSettings({visible:true,isAHU:true});
    e.event.preventDefault();
  }
  const onSaveAllLinesClick =(e) =>{
    e.preventDefault();
    if(!isFilePicked){
      showWarningAlert(["Load an excel file."]);
      return;
    }
    setLoading(true);
    const formData = new FormData();
		formData.append('File', selectedFile);
    formData.append('QuotationNum', quotationObjContext.quotationNum);
    formData.append('RevNum', quotationObjContext.revNum);
    formData.append('CreatedBy', user.Id);
    let url = popupUploadSettings.isAHU ? "Quotation/import/ahulines":"Quotation/lines/import/excel";
    axiosConfig.post(url, formData)
        .then(data => {
              showNotificationSuccess("Quotation Lines uploaded successfully.");
              setLinesToBeUpdatedContext(true);
              setCostItemsToBeUpdatedContext(true);
        })
        .catch((error) => {
          if(error.response.status == 422){
            showErrorAlert(error.response.data);
          }
          else {
            showNotificationError("Error in saving Quotation lines :"+error)
          } 
       })
       .then(()=>setLoading(false));
  }     
  const onFileChange =(event) =>{
    setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
  }

  const onExporting =(e) =>{
    e.component.beginUpdate();
    e.fileName = quotationObjContext.quotationNum+"-"+quotationObjContext.revNum;
    e.component.endUpdate();
  }
  const onApplyLinesClick = (e) =>{
    if(!isNotEmpty(updateLines.typeOfUpdate)){
      setErrors(prevState => ({...prevState,typeOfUpdate:"invalid"}));
      return;
    }
    if(updateLines.typeOfUpdate != "CAF") {
    if(["VAT%","Mtlp","Margin%"].includes(updateLines.typeOfUpdate)) {
       if(!isNotEmpty(updateLines.inputValue)){
        setErrors(prevState => ({...prevState,inputValue:"invalid"}));
        return;
      }
    }
    if(selectedUpdateLinesKeys.length == 0){
      showWarningAlert(["Select lines to update"]);
    }
    setLoading(true);
    let data = {...updateLines,lines:selectedUpdateLinesKeys,
              quotationNum:quotationObjContext.quotationNum,
                revNum:quotationObjContext.revNum};
    axiosConfig.post("quotation/multiple/update",JSON.stringify(data))
    .then((data)=>{
      setLinesToBeUpdatedContext(true);
      setCostItemsToBeUpdatedContext(true);
      showNotificationSuccess("Lines are updated successfully");
    })
    .catch((error) =>{
      showNotificationError("Lines not updated ",error);
    }).then(() => setLoading(false));
    }
    else {
      setLoading(true);
      currencyGrid.current.instance.saveEditData();
      let data = {
        productCAFs:currencyGrid.current.instance.option("dataSource"),
        quotationNum:quotationObjContext.quotationNum,
        revNum:quotationObjContext.revNum
      };
      axiosConfig.post("quotation/caf/update",JSON.stringify(JSON.stringify(data)))
      .then((data)=>{
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
        showNotificationSuccess("Lines are updated successfully");
      })
      .catch((error) =>{
        showNotificationError("Lines not updated ",error);
      })
      .then(() => setLoading(false));
    }
  }
  const onSelectedRowKeysChange = (e) => {
        setSelectedUpdateLinesKeys(e);
  }
  const oncheckBoxValueChange = (val,options) => {
      let prodLines = quotationLinesContext.filter(item => item.prodTypeId == options.data.key).map(x=>x.lineNum);
      if(val){
          let selectedLines = [...selectedUpdateLinesKeys,... prodLines];
          setSelectedUpdateLinesKeys(selectedLines);
      }
      else{
          let selectedLines = selectedUpdateLinesKeys.filter(x=> !prodLines.includes(x));
          setSelectedUpdateLinesKeys(selectedLines);
      }
      setProductGroup(prevState => ({
          ...prevState,
          [options.displayValue]: val
        }));
  }
  const groupCell =(options) =>{
      return (<><CheckBox value={productGroup[options.displayValue]} onValueChange={(e) => oncheckBoxValueChange(e,options)}></CheckBox>&nbsp; {options.displayValue}</>);
  }
  const getProductCAF = () => {
    setLoading(true);
    axiosConfig.get("quotation/currency/caf?quotationNum="+quotationObjContext["quotationNum"]+"&revNum="+quotationObjContext["revNum"])
      .then(data => {
        setProductCAF(data);
      })
      .catch((error) => showNotificationError("Error in getting CAF :" + error))
      .then(() => setLoading(false));
  }
  const applyPopupInputChange = (e) =>{
    setUpdateLines((prevState) => ({...prevState,
      [e.element.id]: e.value
    }))
    if(e.element.id == "typeOfUpdate" && e.value == "CAF"){
      getProductCAF();
    }
  }
  const onApplyOpenPopUp =(e)=>{
    setShowUpdateLinePopUp(true);
    setUpdateLines({});
    setProductCAF([]);
  }
  const onImportOpenPopUp =(e) =>{
    setShowImportLinePopUp(true);
    setCopyFromQuotation({quotations:[],fromQuotationNum:"",toQuotationNum:"",toRevNum:"",data:[],lines:[]});
    //get all quotations
    axiosConfig.get("Quotation/all")
    .then((data)=> {
      setCopyFromQuotation(prevState => ({
        ...prevState,
        quotations : data
      }))});
  }
  const onCopyQuotationInputChange =(e) => {
    setCopyFromQuotation(prevState => ({
      ...prevState,
      fromQuotationNum : e.value
    }));
    axiosConfig.get("Quotation/lines/activeRev?Id="+e.value)
    .then((data) => setCopyFromQuotation(prevState => ({...prevState,data:data})));
  }
  const onCopyQuotationLineClick =(e) =>{
    setLoading(true);
    let selectedLines = copyQuotationLineGrid.current.instance.getSelectedRowsData().map(x => x["lineNum"]);
    let inputData ={toQuotationNum: quotationObjContext.quotationNum ,toRevNum :quotationObjContext.revNum 
        ,fromQuotationNum:copyFromQuotation.fromQuotationNum,lines:selectedLines};
    axiosConfig.post("Quotation/lines/copy",JSON.stringify(inputData))
    .then((data) => {
      showNotificationSuccess("Lines copied");
      setLinesToBeUpdatedContext(true);
    })
    .catch((error) => showNotificationError("Lines are not copied"))
    .then(() => setLoading(false));
  }
  return (
    <>
       <Loader loading={loading}/>
       <ToastContainer />
      <Card style={quotationObjContext.isLocked ? {pointerEvents: "none", opacity: "0.7"}:{}}>
      <Card.Body>

       <DataGrid
          dataSource={quotationLinesContext}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={true}
          //columnAutoWidth={false}
          showBorders={true}
          ref={quotationLineGrid}
          //newRowPosition={"last"}
          onRowInserted={onRowInserted}
          onEditorPrepared ={onEditorPrepared}
          onRowValidating={onRowValidating}
          onRowRemoving={onRowRemoving}
          refreshMode={"repaint"}
          onExporting={onExporting}
          //rowRenderingMode={"standard"}
          //onEditorPreparing={onEditorPreparing}
          //onEditorPreparing={overrideOnValueChanged}  
          //keyExpr="lineNum"
          onRowUpdating={onRowUpdated}
        >
          <Paging defaultPageSize={10} enabled={true} />
          <Export  enabled={true} />
          <Editing
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={true}
            useIcons={true}
          />
          <ColumnChooser enabled={true} />
          <Column dataField="lineNum"  caption={"LNo"} dataType="number" allowEditing={false}/>
          <Column dataField="activeLine" dataType="boolean"/>
          <Column dataField="itemCode"  width={250} editCellRender={renderAutoComplete}/> 
          <Column dataField="unitTag" />
          <Column dataField="caf" dataType="number" width={60} caption={"CAF"} allowEditing={false} />
          <Column dataField="unitPrice" dataType="number" allowEditing={false} caption={"List Price"}>
          <Format type="fixedPoint" precision={2} />  
          </Column>
          <Column dataField="qty" width={40} dataType="number" />
           
          <Column dataField="mtlp" width={60} dataType="number" >
            <Format type="fixedPoint" precision={4} />  
          </Column>
          <Column dataField="ttNetPrice" dataType="number" caption={"Total Net"} allowEditing={false}>
            <Format type="fixedPoint" precision={2} />  
          </Column>
          <Column dataField="costItemLineValue" dataType="number" caption={"Cost Item"} allowEditing={false}>
            <Format type="fixedPoint" precision={2} />  
          </Column>
         
          <Column dataField="subItemCode" visible={false} allowExporting={false}/>
          <Column dataField="baseItemCode" visible={false} allowExporting={false}/>
          <Column dataField="productCurrencyCode" visible={false} allowExporting={false}/>
        
          <Column dataField="ttCostPrice" dataType="number" allowEditing={false} caption={"Total Cost"}>
            <Format type="fixedPoint" precision={2} />  
          </Column>
          <Column dataField="margin" dataType="number" caption={"Margin%"}>
            <Format type="fixedPoint" precision={2} />  
          </Column>
          <Column dataField="ttSlsPrice" dataType="number" caption={"Total Price"} >
            <Format type="fixedPoint" precision={2} />  
          </Column>
          <Column dataField="vat" width={60}>
            <Lookup dataSource={vatTypes}
              valueExpr="code"
              displayExpr="name"
            />
          </Column>
          <Column dataField="ttSlsPriceWTVat" dataType="number" allowEditing={false} caption={"Total Amnt"}>
            <Format type="fixedPoint" precision={2} />  
          </Column>
          
          <Column type="buttons">
              <DXButtonDataGrid  icon="money" onClick={onPriceBtnClicked} />
              <DXButtonDataGrid name="edit" />
              <DXButtonDataGrid name="delete" />
          </Column>
          <Summary calculateCustomSummary={calculateCustomSummary}>
            <TotalItem
              showInColumn="ttSlsPrice"
              name ="ttSlsPrice"
              summaryType="custom"
              customizeText={customizeText}
            />
             <TotalItem
              showInColumn="ttNetPrice"
              name ="ttNetPrice"
              summaryType="custom"
              customizeText={customizeText}
            />
            <TotalItem
              showInColumn="ttSlsPriceWTVat"
              name ="ttSlsPriceWTVat"
              summaryType="custom"
              customizeText={customizeText}
            />
             <TotalItem
              showInColumn="ttCostPrice"
              name ="ttCostPrice"
              summaryType="custom"
              customizeText={customizeText}
            />
            <TotalItem
              showInColumn="costItemLineValue"
              name ="costItemLineValue"
              summaryType="custom"
              customizeText={customizeText}
            />
          </Summary>
          <Toolbar>
          <Item location="after" render={() => {
              return <DxButton
                width={120}
                text="Import"
                type="normal"
                stylingMode="contained"
                onClick={onImportOpenPopUp}//() => setShowUpdateLinePopUp(true)
              />;
            }} />
            <Item location="after" render={() => {
              return <DxButton
                width={120}
                text="Upload AHU"
                type="normal"
                stylingMode="contained"
                onClick={onUploadAHULinesClick}//() => setShowUpdateLinePopUp(true)
              />;
            }} />
           <Item location="after" render={() => {
              return <DxButton
                width={120}
                text="Apply"
                type="normal"
                stylingMode="contained"
                onClick={onApplyOpenPopUp}//() => setShowUpdateLinePopUp(true)
              />;
            }} />
          <Item location="after" render={() => {
              return <DxButton
                width={120}
                text="Upload"
                type="normal"
                stylingMode="contained"
                onClick={onUploadLinesClick}
              />;
            }} />
            <Item location="after" render={() => {
              return <DxButton
                width={120}
                text="Copy Option"
                type="normal"
                stylingMode="contained"
                onClick={onCopyOptionBtnClick}
              />;
            }} />
            <Item name="addRowButton" />
            <Item name="exportButton" />
          </Toolbar>
        </DataGrid>

        <div>
          <QuotationCopyOption setPopupVisible={setPopupVisibleCopyOption} popupVisible={popupVisibleCopyOption}/>
          <QuotationOptions line={quotationLine} setPopupVisible={setPopupPricingVisible} popupVisible={popupPricingVisible}/>
        </div>
        </Card.Body>
      </Card>
      <Modal
        show={popupUploadSettings.visible}
        onHide={()=>setPopupUploadSettings({...popupUploadSettings ,visible:false})}
        //size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title>Import Quotation {popupUploadSettings.isAHU ? "AHU" :""} Lines</Modal.Title>
          <button onClick={()=>setPopupUploadSettings({...popupUploadSettings ,visible:false})}  className="btn btn-icon me-3">
                    <i className="fe fe-x"></i>
                </button>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
            <label htmlFor="QuotationNum">Excel file </label>
            <input className="form-control" onChange={onFileChange} type="file" id="formFile" />
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
        {popupUploadSettings.isAHU ? QuotationExcelDownloadTemplate("UploadAHU") : QuotationExcelDownloadTemplate("UploadLines")}
          <Button variant="success" className="me-1" onClick={onSaveAllLinesClick}>Upload</Button>
        </Modal.Footer>
      </Modal>
      <Modal
            show={showUpdateLinePopUp}
            //onHide={()=> setNewCustomerVisible(false)}
            //size="lg"
            dialogClassName="modal-90w"
            //size="lg"
            aria-labelledby="example-custom-modal-styling-title-1"
        >
          <Modal.Header >
              <Modal.Title>Update Lines</Modal.Title>
              <button onClick={() => setShowUpdateLinePopUp(false)}  className="btn btn-icon me-3">
                  <i className="fe fe-x"></i>
              </button>
          </Modal.Header>
          <Modal.Body>
              <CForm
                  className="row g-3 needs-validation"
                  noValidate
                  //validated={validated}
                  //onSubmit={onApplyLinesClick}
              >
                  <CCol md={4}>
                      <CFormLabel htmlFor="typeOfUpdate">Type of update</CFormLabel>
                      <SelectBox
                          dataSource={typesOfUpdate}
                          validationStatus={setValidationStatus("typeOfUpdate")}
                          //displayExpr="Name"
                          //valueExpr="Code"
                          id="typeOfUpdate"
                          value={updateLines.typeOfUpdate}
                          onValueChanged={applyPopupInputChange}
                          required
                      />
                  </CCol>  
                 {!["CAF","VAT%","Delete"].includes(updateLines.typeOfUpdate) && <CCol md={4}>
                      <CFormLabel htmlFor="inputValue">Apply</CFormLabel>
                       <CFormInput
                          type="number"
                          id="inputValue"
                           validationStatus={setValidationStatus("inputValue")}
                          value={updateLines.inputValue}
                          onChange={(e)=> setUpdateLines(prevState =>({...prevState , inputValue : e.target.value}))}
                      />
                  </CCol>}
                  {updateLines.typeOfUpdate == "VAT%" && <CCol md={4}>
                      <CFormLabel htmlFor="inputValue">Apply</CFormLabel>
                      <SelectBox
                        dataSource = {vatTypes}
                        value={updateLines.inputValue}
                        id="inputValue"
                        valueExpr={"code"}
                        displayExpr={"name"}
                        onValueChanged={(e)=> setUpdateLines(prevState =>({...prevState , inputValue : e.value}))}/>
                  </CCol> }
                
                  <div className='row mt-4'>
                    {updateLines.typeOfUpdate != "CAF" &&  <DataGrid
                          dataSource={quotationLinesContext}
                          showBorders={true}
                          allowColumnReordering={true}
                          rowAlternationEnabled={true}
                          keyExpr="lineNum"
                          selectedRowKeys={selectedUpdateLinesKeys}
                          onSelectedRowKeysChange ={onSelectedRowKeysChange}
                      >
                          <Selection mode="multiple" allowSelectAll showCheckBoxesMode={"always"}/>
                          <Grouping autoExpandAll={false} />
                          <Paging defaultPageSize={10} enabled={true} />
                          <FilterRow visible={true} />
                          <Column dataField="lineNum" width={150}/>
                          <Column dataField="itemCode" />
                          <Column dataField="prodTypeId" groupIndex={0} groupCellRender={groupCell}/>
                      </DataGrid>}
                    {updateLines.typeOfUpdate == "CAF" &&    <QuotationCAF ref={currencyGrid} data={productCAF} quotationCurrency={quotationHeaderContext.currencyCode}/>}
                  </div>
              </CForm>
              
          </Modal.Body>
          <Modal.Footer> 
            <Button variant="success" className="me-1" onClick={onApplyLinesClick}>Update</Button>
          </Modal.Footer>
      </Modal>
      <Modal
      show={showImportLinePopUp}
      //onHide={handleClose}
      size="lg"
      //dialogClassName ="modal-xl"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header>
        <Modal.Title>Copy from Quotation</Modal.Title>
        <button onClick={() => setShowImportLinePopUp(false)}  className="btn btn-icon me-3">
            <i className="fe fe-x"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
      <Row>
        <Col md={4}>
        <Form.Group>
                <Form.Label>
                  QuotationNum
                </Form.Label>
                <SelectBox
                  dataSource={copyFromQuotation["quotations"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchTimeout={200}
                  minSearchLength={3}
                  id="quotationNum"
                  value={copyFromQuotation["fromQuotationNum"]}
                  onValueChanged={onCopyQuotationInputChange}
                />
              </Form.Group>
        </Col>

        <Col  md={12}>

          <DataGrid
            dataSource={copyFromQuotation["data"]}
            showBorders={true}
            allowColumnReordering={true}
            //rowAlternationEnabled={true}
            allowSearch
            //keyExpr="lineNum"
            ref={copyQuotationLineGrid}
            height={500}
          >
            <FilterRow visible={true} />
            <Paging defaultPageSize={10} enabled={true} />
            <Pager visible={true} />
            <Selection mode="multiple" />
            <Column dataField="lineNum" />
            <Column dataField="itemCode" />
          </DataGrid>
        </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="success" className="me-1" onClick={onCopyQuotationLineClick}>
          Copy Lines
        </Button>
      </Modal.Footer>
    </Modal>
    </>);

}

export default QuotationLines;
/*
 const onEditorPreparing = (e) => {
  if (e.dataField === "itemCode") {
    console.log(e);
    e.editorType = "dxAutocomplete";
    e.editorOptions = {
      items:new CustomStore({
        key: 'code',
        useDefaultSearch: true,
        load(loadOptions) {
          return axiosConfig.get(`ItemCode/filter?searchString=`+loadOptions.filter[0][2])
            .then((data) => ({
              data: data,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
        },
      }),
      valueExpr:"name",
     // items: this.names,
      value: e.value,
      onValueChanged: function (ev) {
        e.setValue(ev.value);
      }
    };
  }
  }

*/