import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function HeaderMaster({name}) {
    const [data, setData] = useState([]);
    const [input, setInput] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        axiosConfig.get("Master/all?type="+name)
            .then(data => setData(data))
            .catch((error) => showNotificationError("Error loading pricing data:"+error))
            .then(()=>{
              setLoading(false);
            });
            let columns = headerMasterData[name].columns;
    },[name])
    const headerMasterData ={
        "CostItems":{columns:["Code","Name"]},
        "Status":{columns:["Id","Name"]},
        "Areas":{columns:["Code","Name"]},
        "PaymentTerms":{columns:["Id","Name"]},
        "DeliveryTerms":{columns:["Id","Name"]},
        "Industry":{columns:["Id","Name"]},
        "Currency":{columns:["Name","ConvFactor"]},
    }

    useEffect(() => {
        if(loading) {
            getAllData();
        }
    }, [loading]);

    const getAllData = () =>{
        axiosConfig.get("Master/all?type="+name)
        .then(data => setData(data))
        .catch((error) => showNotificationError("Error loading pricing data:"+error))
        .then(()=>{
          setLoading(false);
        });
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoading(true);
        let data = {type:name,name:input.name,code:input.code,convFactor:input.convFactor};
        axiosConfig.post("Master/Insert", JSON.stringify(JSON.stringify(data)))
            .then(data => {
                showNotificationSuccess("Added");
                setInput({});
                getAllData();})
            .catch((error) => showNotificationError("Error loading pricing data:"+error))
          .then(()=>{
            setLoading(false);
          });
    }

    return (
        <div>
            <Loader loading={loading}/>
            <ToastContainer />
            <div className="page-header ">
              <div>
              <h1 className="page-title">{name}</h1>
              <Breadcrumb className="breadcrumb">
                  <Breadcrumb.Item className="breadcrumb-item" href="#">
                 {name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                  className="breadcrumb-item active breadcrumds"
                  aria-current="page"
                  >
                   {name}
                  </Breadcrumb.Item>
              </Breadcrumb>
              </div>
          </div>
            <CForm
                className="row g-3 needs-validation mb-4"
                // noValidate
                // validated={validated}
                onSubmit={handleSubmit}
            >
                {(name == "Areas") && <CCol md={2}>
                    <CFormLabel htmlFor="name">Code</CFormLabel>
                    <CFormInput
                        type="text"
                        id="code"
                        placeholder="Enter"
                        value ={input.code}
                        onChange={e => setInput(prevState => ({
                            ...prevState,
                            code: e.target.value
                        }))}
                        required
                    />
                    <CFormFeedback valid>Looks good!</CFormFeedback>
                </CCol>}
               <CCol md={4}>
                    <CFormLabel htmlFor="name">Name</CFormLabel>
                    <CFormInput
                        type="text"
                        id="name"
                        placeholder="Enter"
                        value ={input.name}
                        onChange={e => setInput(prevState => ({
                            ...prevState,
                            name: e.target.value
                        }))}
                        required
                    />
                    <CFormFeedback valid>Looks good!</CFormFeedback>
                </CCol>
                {(name == "Currency") && <CCol md={2}>
                    <CFormLabel htmlFor="name">Conv Factor</CFormLabel>
                    <CFormInput
                        type="number"
                        id="convFactor"
                        placeholder="Enter"
                        value ={input.convFactor}
                        onChange={e => setInput(prevState => ({
                            ...prevState,
                            convFactor: e.target.value
                        }))}
                        required
                    />
                    <CFormFeedback valid>Looks good!</CFormFeedback>
                </CCol>}
                <CCol md={4}>
                    <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
                    <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                        <span>
                            <i className="fe fe-plus"></i>&nbsp;
                        </span>
                        Add
                    </Button>
                </CCol>
            </CForm>
            <Card>
                <Card.Body>

                    <div className="row">
                        <DataGrid
                            dataSource={data}
                            allowColumnReordering={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columns={headerMasterData[name].columns}
                        >
                            <FilterRow visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                            
                        </DataGrid>

                    </div>
                </Card.Body>
            </Card>
        </div>);

}

export default HeaderMaster;


  //<Column dataField="brandName" name={"Name"} />