import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import QuotationMain from './components/App/Quotation/QuotationMain';
import QuotationReport from './components/App/Quotation/QuotationReport';
import QuotationPBD from './components/App/Quotation/QuotationPBD';

import WarrantyHeader from './components/App/Warranty/WarrantyHeader';

import ProductMaster from "./components/App/Master/Items/ProductMaster";
import ItemGroup from "./components/App/Master/Items/ItemGroup";
import Pricing from "./components/App/Master/Items/Pricing";
import ItemCode from "./components/App/Master/Items/ItemCode";
import Series from "./components/App/Master/Items/Series";
import Brand from "./components/App/Master/Items/Brand";
import OptionCode from "./components/App/Master/Items/OptionCode";

import { MyContextProvider } from "./components/App/Shared/MyContext";
import { UserContextProvider } from "./components/App/Shared/UserContext";
import { MsalProvider, MsalContext } from '@azure/msal-react';
import { msalConfig } from "./components/App/config/AuthConfig";
import { PublicClientApplication,EventType  } from "@azure/msal-browser";
import HeaderMaster from "./components/App/Master/Header/HeaderMaster";
import UserMaster from "./components/App/Master/Header/UserMaster";
import ReportIssue from "./components/App/ReportIssue";
import Dashboard from "./components/App/Dashboard";
import WarrantyMain from "./components/App/Warranty/WarrantyMain";
const App = React.lazy(() => import("./components/app"));
//Errorpages
const Errorpage400 = React.lazy(()=>import("./components/ErrorPages/ErrorPages/400/400"));
const Errorpage401 = React.lazy(()=>import("./components/ErrorPages/ErrorPages/401/401"));


const Loaderimg = () => {
  return (
    <div id="global-loader">
      <img
        src={require("./assets/images/loader.svg").default}
        className="loader-img"
        alt="Loader"
      />
    </div>
  );
};
const Root = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  //const [isAuthenticated,setIsAuthenticated] = useState(false);

  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: msalConfig.clientId,
      redirectUri: msalConfig.redirectUri,
      authority: msalConfig.authority
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  });

  /*async function login () {
    try {
      await  publicClientApplication.loginPopup({
        scopes: msalConfig.scopes,
        prompt: "select_account"
      });
      setIsAuthenticated(true);
    } catch(err) {
      setIsAuthenticated(false);
    }
  }*/


  return (
    <Fragment>
      <BrowserRouter basename="/">
      <React.Suspense fallback={Loaderimg()}>
     <MsalProvider instance={msalInstance}>
       
        <Routes>
          <Route element={<QuotationPBD/>}  path={`${process.env.PUBLIC_URL}/Quotation/PBD`}/> 
          <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<UserContextProvider> <App /></UserContextProvider>}
            >
            <Route index element={<QuotationReport/>}  path={`${process.env.PUBLIC_URL}`}/> 
            <Route element={ <MyContextProvider><QuotationMain/> </MyContextProvider>} path={`${process.env.PUBLIC_URL}/Quotations/:quotationId`} /> 
            <Route element={ <MyContextProvider><QuotationMain/></MyContextProvider>}  path={`${process.env.PUBLIC_URL}/Quotations`}/>            
            <Route element={ <MyContextProvider><WarrantyMain/></MyContextProvider>}  path={`${process.env.PUBLIC_URL}/Warranty/List`} />
            <Route element={ <MyContextProvider><WarrantyHeader/></MyContextProvider>}  path={`${process.env.PUBLIC_URL}/Warranty/:jobDetailsId`}/>
            <Route element={ <MyContextProvider><WarrantyHeader/></MyContextProvider>}  path={`${process.env.PUBLIC_URL}/Warranty`}/>

            <Route>
              <Route element={<ProductMaster/>} path={`${process.env.PUBLIC_URL}/Master/Items/products`} /> 
              <Route element={<ItemGroup/>} path={`${process.env.PUBLIC_URL}/Master/Items/itemgroups`} /> 
              <Route element={<Series/>} path={`${process.env.PUBLIC_URL}/Master/Items/series`} /> 
              <Route element={<Brand/>} path={`${process.env.PUBLIC_URL}/Master/Items/brands`} /> 
              <Route element={<ItemCode/>} path={`${process.env.PUBLIC_URL}/Master/Items/itemcodes`} /> 
              <Route element={<OptionCode/>} path={`${process.env.PUBLIC_URL}/Master/Items/options`} /> 
              <Route element={<Pricing/>} path={`${process.env.PUBLIC_URL}/Master/Items/pricing`} /> 
            </Route>
            <Route>
              <Route element={<UserMaster />} path={`${process.env.PUBLIC_URL}/Master/Header/Users`} /> 
              <Route element={<HeaderMaster name={"CostItems"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/CostItems`} /> 
              <Route element={<HeaderMaster name={"Status"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/Status`} /> 
              <Route element={<HeaderMaster name={"Areas"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/areas`} /> 
              <Route element={<HeaderMaster name={"PaymentTerms"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/paymentTerms`} /> 
              <Route element={<HeaderMaster name={"DeliveryTerms"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/deliveryTerms`} /> 
              <Route element={<HeaderMaster name={"Industry"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/industry`} /> 
              <Route element={<HeaderMaster name={"Currency"}/>} path={`${process.env.PUBLIC_URL}/Master/Header/currency`} /> 
            </Route>
            <Route>
              <Route element={ <MyContextProvider><ReportIssue/></MyContextProvider>}  path={`${process.env.PUBLIC_URL}/Maintanence/Report/Issue`} />
              <Route element={<Dashboard/>}  path={`${process.env.PUBLIC_URL}/Dashboard/Brand`} /> 
            </Route>
          </Route>
          <Route path="*" element={<Errorpage400 />} />  
          <Route
              path={`${process.env.PUBLIC_URL}/unauthorized`}
              element={<Errorpage401 />}
            />            
        </Routes>
        </MsalProvider>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
