import { useEffect, useRef, useState,useContext } from "react";
import notify from 'devextreme/ui/notify';
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  Selection,
  Grouping,
  Button as DxButton,
  Summary,
  TotalItem,
  Pager,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';
//import Tabs from 'devextreme-react/tabs';
//import { Popup } from 'devextreme-react/popup';
//import ScrollView from 'devextreme-react/scroll-view';
import MyContext from '../Shared/MyContext';
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../Shared/Utils';
import axiosConfig from '../config/axiosConfig';
import { Modal, Button, Row, Col, Container,Nav, TabContainer, Tabs, Tab, Breadcrumb,Card, ModalFooter } from "react-bootstrap";
import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
} from "@coreui/react";

function QuotationOptions({ line, popupVisible,setPopupVisible}) {
  const quotationOptionGrid = useRef();
  const quotationSelectedOptionGrid = useRef();
  const [allOptions, setAllOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLine, setSelectedLine] = useState({});
  const[nonStandardOption,setNonStandardOption]=useState({});
  const[nonStandardOptions,setNonStandardOptions]=useState([]);
  const [visible, setvisibility] = useState(false);
  const [validated, setValidated] = useState(false);
  const {quotationObjContext,setLoadingContext,setQuotationLinesContext,quotationLinesContext,quotationHeaderContext,
    isQuotationLinesToBeUpdatedContext,setLinesToBeUpdatedContext, isQuotationCostItemsToBeUpdatedContext,
    setCostItemsToBeUpdatedContext}= useContext(MyContext);
    const [tabKey, setTabKey] = useState("tab1");

  useEffect(() => {
    if (popupVisible) {
      getOptions("STANDARD");
      getOptions("NONSTANDARD");
      setvisibility(popupVisible);
      setPopupVisible(false);
    }
  }, [popupVisible]);

  const getOptions = (optType) => {
    setLoadingContext(true);
    if(optType == "STANDARD"){
      axiosConfig.post('Quotation/line/options',JSON.stringify(line))
        .then((data) => {
          let allOptions = JSON.parse(data["allOptions"]);
          let selectedOptions = JSON.parse(data["selectedOptons"]).map(x => x["OptCode"]);
          let unSelectedOptions = [];
          let selectedQuoteOptions = []
          for (let i = 0; i < allOptions.length; i++) {
            if (!selectedOptions.includes(allOptions[i]["optCode"])) {
              unSelectedOptions.push({
                optCode: allOptions[i]["optCode"], price: allOptions[i]["price"], optName: allOptions[i]["optName"],
                isNet: allOptions[i]["isNet"]
              });
            }
            else {
              selectedQuoteOptions.push({ optCode: allOptions[i]["optCode"], price: allOptions[i]["price"], isNet: allOptions[i]["isNet"] });
            }

          }
          setAllOptions(unSelectedOptions);
          setSelectedOptions(selectedQuoteOptions);

        })
        .catch((error) => console.log(error))
        .then(()=>setLoadingContext(false));
    }
    else {
      axiosConfig.get('Quotation/line/nonstandard/options?quotationId='+line.quotationNum+'&revNum='+line.revNum+'&lineNum='+line.lineNum)
      .then((data) => {
        setNonStandardOptions(data);
      })
      .catch((error) => console.log(error));
    }
  }

  useEffect(() => {
    if (line != undefined) {
      setSelectedLine(line);
    }
  }, [line]);

  const onAddOption = (e) => {
    //let optionsSelected = quotationOptionGrid.current.instance.getSelectedRowsData().map(x => x["optCode"]);

    //let updatedOptions = allOptions.filter((item) => !optionsSelected.includes(item["optCode"]));
    //quotationOptionGrid.current.instance.option("dataSource", updatedOptions);

    //optionsSelected = [...selectedOptions, ...quotationOptionGrid.current.instance.getSelectedRowsData()];
    //quotationSelectedOptionGrid.current.instance.option("dataSource", optionsSelected);
    //quotationSelectedOptionGrid.current.instance.refresh();
    //quotationOptionGrid.current.instance.refresh();
    //setAllOptions(updatedOptions);
    //setSelectedOptions(optionsSelected);
    let selectedRows = quotationOptionGrid.current.instance.getSelectedRowsData();
    if(selectedRows.length == 0){
      showWarningAlert(["No options are being selected"]);
      return;
    }
    let input = selectedLine;
    input["optCodes"] = selectedRows.map(x => x["optCode"]).join(",");
    setLoadingContext(true);
    axiosConfig.post('Quotation/line/options/add',JSON.stringify(input))
      .then((data) => {
        /*let sourceData = quotationLines;
        for (let i = 0; i < sourceData.length; i++) {
          if (sourceData[i]["lineNum"] == data["lineNum"]) {
            sourceData[i]["ttslsPrice"] = data["ttslsPrice"];
            sourceData[i]["unitPrice"] = data["unitPrice"];
            break;
          }
        }*/
        getOptions("STANDARD");
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
        showNotificationSuccess("Option added successfuly");
        //refreshLinesGrid(sourceData);
      })
      .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in saving Quotation Option :"+error)
        } 
     })
     .then(()=>setLoadingContext(false));

  }
  const onDeleteOptionClick = (e) => {
    let input = selectedLine;
    input["optCodes"] = e.row.data["optCode"];
    setLoadingContext(true);
    axiosConfig.post('Quotation/line/options/remove',JSON.stringify(input))
      .then((data) => {
        //update quotation grid 
        //et selectedOptions = selectedOptions.filter(x => x["optCode"] != e.row.data["optCode"]);
        //setSelectedOptions(selectedOptions);

        getOptions("STANDARD");
        //update lines grid
        /*let sourceData = quotationLines;
        for (let i = 0; i < sourceData.length; i++) {
          if (sourceData[i]["lineNum"] == data["lineNum"]) {
            sourceData[i]["ttslsPrice"] = data["ttslsPrice"];
            sourceData[i]["unitPrice"] = data["unitPrice"];
            break;
          }
        }*/
        //refreshLinesGrid([]);
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
      })
      .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in deleting Quotation Option :"+error)
        } 
     })
     .then(()=>setLoadingContext(false));
  }
  const onEditorPreparing = (e) => {
    e.editorOptions.disabled = e.parentType == "dataRow" && e.dataField == "isNet" && e.row.isNet == null;
  }
  /*const onTabsSelectionChanged = (args) => {
    if (args.name === 'selectedIndex') {
      setSelectedTabIndex( args.value);
    }
  }*/
  const formControIsValid = (error) => {
    return error ? "is-invalid" : "";
  }
  const onAddNSOptionClick =(e) =>{
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    setLoadingContext(true);
    let input = {quotationNum: selectedLine.quotationNum,lineNum: selectedLine.lineNum,
      revNum: selectedLine.revNum,itemCode:selectedLine.baseItemCode};
    input ={...input , ... nonStandardOption};
    axiosConfig.post('Quotation/line/nonstandard/options/add',JSON.stringify(input))
    .then((data) => {
      getOptions("NONSTANDARD");
      setLinesToBeUpdatedContext(true);
      setCostItemsToBeUpdatedContext(true);
      setNonStandardOption({});
    })
    .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in saving Quotation Option :"+error)
        } 
     })
     .then(()=>setLoadingContext(false));
  }
  const onDeleteNSOptionClick = (e) => {
    setLoadingContext(true);
    let input =  e.data;// {quotationNum: selectedLine.quotationNum,lineNum: selectedLine.lineNum,revNum: selectedLine.revNum};
    //input["optCode"] = e.data["optCode"];
    axiosConfig.post('Quotation/line/nonstandard/options/remove',JSON.stringify(input))
      .then((data) => {
        getOptions("NONSTANDARD");
        ///refreshLinesGrid([]);
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
      })
      .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in deleting Quotation Option :"+error)
        } 
     })
     .then(()=>setLoadingContext(false));
  }

  const onEditNSOptionClick =(e) =>{
    setLoadingContext(true);
    let input =  e.data;
    //input["optCode"] = e.row.data["optCode"];
    axiosConfig.post('Quotation/line/nonstandard/options/update',JSON.stringify(input))
      .then((data) => {
        getOptions("NONSTANDARD");
        ///refreshLinesGrid([]);
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
      })
      .catch((error) => {
        if(error.response.status == 422){
          showErrorAlert(error.response.data);
        }
        else {
          showNotificationError("Error in deleting Quotation Option :"+error)
        } 
     })
     .then(()=>setLoadingContext(false));
  }

  const onInputChange = (e) => {
      setNonStandardOption(prevState => ({
        ...prevState,
        [e.target.id]: e.target.id == "isNet" ? e.target.checked : e.target.value
      }));
  }
  const getTabContent = (index) => {
    if (index == 0) {
      return (
        <div className="row">
          <div className="col-md-6">
            <DataGrid
              dataSource={allOptions}
              showBorders={true}
              allowColumnReordering={true}
              ref={quotationOptionGrid}
              //onEditorPreparing={onEditorPreparing}
              // rowAlternationEnabled={true}
              //width={'300'}
              height={500}
            >
              <Selection mode="multiple" />
              <Scrolling mode="virtual" />
              <Grouping autoExpandAll={false} />
              <Column dataField="optCode" />
              <Column dataField="price" />
              <Column dataField="isNet" dataType="boolean" />
              <Column dataField="optName" caption={""} groupIndex={0} width={'70'} />
            </DataGrid>
          </div>
          <div className="col-md-6">
            <DataGrid
              dataSource={selectedOptions}
              showBorders={true}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              ref={quotationSelectedOptionGrid}
            >
              <Editing
                mode="row"
                useIcons={true}
                allowDeleting />
              <Column dataField="optCode" />
              <Column dataField="price" />
              <Column dataField="isNet" dataType="boolean" />
              <Column type="buttons" width={60}>
                <DxButton name="edit" />
                <DxButton name="delete" onClick={onDeleteOptionClick} />
              </Column>

            </DataGrid>
          </div>
        </div>
      );
    }
    else {
      return (
        <>
      <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={onAddNSOptionClick}
          >
            <CCol md={2}>
              <CFormLabel htmlFor="optCode">Option Code</CFormLabel>
              <CFormInput
                type="text"
                id="optCode"
                onChange={onInputChange}
                //defaultValue="Mark"
                value={nonStandardOption["optCode"]}
                required
              />
              <CFormFeedback valid>Looks good!</CFormFeedback>
            </CCol>
            <CCol md={4}>
              <CFormLabel htmlFor="optName">Option Name</CFormLabel>
              <CFormInput
                type="text"
                id="optName"
                onChange={onInputChange}
                //defaultValue="Mark"
                value={nonStandardOption["optName"]}
                required
              />
              <CFormFeedback valid>Looks good!</CFormFeedback>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="price">Price ({selectedLine["productCurrencyCode"]})</CFormLabel>
              <CFormInput
                type="number"
                id="price"
                onChange={onInputChange}
                //defaultValue="Mark"
                value={nonStandardOption["price"]}
                required
              />
              <CFormFeedback valid>Looks good!</CFormFeedback>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="price">Is Net</CFormLabel>
                    <label className="custom-switch">
                      <input
                        type="checkbox"
                        id="isNet"
                        name="custom-switch-checkbox"
                        className="custom-switch-input"
                        onChange={onInputChange}
                        value={nonStandardOption["isNet"]}
                      />
                      <span className="custom-switch-indicator"></span>
                      <span className="custom-switch-description">
                        
                      </span>
                    </label>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
            <Button type="submit" variant="success"className="btn btn-success btn-icon text-white me-3">
              <span>
                <i className="fe fe-plus"></i>&nbsp;
              </span>
                Add option
            </Button>
            </CCol>
      </CForm>
      <div className="row mt-4">
        <DataGrid
                dataSource={nonStandardOptions}
                showBorders={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                onRowRemoved={onDeleteNSOptionClick}
                onRowUpdated={onEditNSOptionClick}
                //ref={quotationSelectedOptionGrid}
              >
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating
                  allowDeleting />
                <Column dataField="optCode" allowEditing={false} />
                <Column dataField="price" />
                <Column dataField="isNet" dataType={"boolean"}/>

        </DataGrid>
      </div>
      </>
      );
    }
  }

  const handleClose = () => {
    setTabKey("tab1");
    setvisibility(false);
    setAllOptions([]);
    setNonStandardOptions([]);
  };

  return (

    <Modal
        show={visible}
        onHide={handleClose}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header >
          <Modal.Title> Pricing for Line : {selectedLine["lineNum"]} in {selectedLine["productCurrencyCode"]}</Modal.Title>
          <button onClick={handleClose}  className="btn btn-icon me-3">
                    <i className="fe fe-x"></i>
                </button>
        </Modal.Header>
        <Modal.Body>
          <div className="panel panel-primary">
                  <div className="tab-menu-heading border">
                    <div className="tabs-menu ">
                      <Tabs
                        className=" nav panel-tabs"
                        variant="pills"
                        //defaultActiveKey="tab1"
                        onSelect={(k) => setTabKey(k)}
                      >
                        <Tab eventKey="tab1" className="me-1" title="Standard Options">
                          {getTabContent(0)}
                        </Tab>
                        <Tab eventKey="tab2" className="me-1" title="Non Standard Options">
                          <hr />
                          {getTabContent(1)}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
          </div>
        </Modal.Body>
        <ModalFooter>
          {tabKey == "tab1" && <Button variant="success"className="btn btn-success btn-icon text-white me-3" onClick={onAddOption}>
              <span>
                <i className="fe fe-plus"></i>&nbsp;
              </span>
                Add option
          </Button>}
        </ModalFooter>
      </Modal>
    );

}

export default QuotationOptions;

