import SelectBox from 'devextreme-react/select-box';
import { Autocomplete } from 'devextreme-react';
import { useEffect, useRef, useState, useCallback } from "react";
import DataGrid, {
  Column,
  Editing,
  Format,
  Scrolling,
  Selection,
  Grouping,
  Summary,
  TotalItem, Item, Toolbar,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,
  FilterRow,
  Export,
  HeaderFilter,
  Button as DXButtonDataGrid
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Button
} from "react-bootstrap";
import { Loader } from '../Shared/Utils';
import axiosConfig from '../config/axiosConfig';
import TagBox from 'devextreme-react/tag-box';
function QuotationReport() {
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState({});
  const [data, setData] = useState({});
  const [masters, setMasters] = useState({ quotationYears:[],users: [], areas: [], quotations: [], brands: [], customers: [], statuses: [], projects: [], products: [],consultants:[],clients:[] });
 

  useEffect(() => {
    setLoading(true);
    axiosConfig.get("Master/Search/GetAll")
      .then(data => {
        setMasters({
          users: JSON.parse(data["users"]),
          areas: JSON.parse(data["areas"]),
          customers: JSON.parse(data["customers"]),
          clients: JSON.parse(data["clients"]),
          consultants: JSON.parse(data["consultants"]),
          statuses: JSON.parse(data["statuses"]),
          quotationYears: JSON.parse(data["quotationYears"]),
          brands: JSON.parse(data["brands"]),
          products: JSON.parse(data["products"]),
          projects: JSON.parse(data["projects"]),
        });
        axiosConfig.get("Quotation/all")
        .then(data => {
          setLoading(false);
          setMasters(prevState => ({
            ...prevState,
            quotations: data
          }));
        });
      });
      
  }, []);


  /*const onInputChange = (e) => {
      setQuotation(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));
  }*/
  const onInputChange = (e) => {
    //for dxSelectBox
    if (e.element != undefined) {
      setQuotation(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));
    }
    //for normal inputs
    else {
      setQuotation(prevState => ({
        ...prevState,
        [e.target.id]: e.target.id == "itemCodeWise" ? e.target.checked : e.target.value
      }));
    }
  }

  const handleSearch = (evt) => {
    evt.preventDefault();
      console.log(quotation);
      setLoading(true);
      axiosConfig.post("Quotation/Search", JSON.stringify(JSON.stringify(quotation)))
        .then(data => { setLoading(false); setData(data); });
  }

  const generateQuotlink =(e) =>{
    console.log(e);
    let link = "/Quotations/"+e.row.data.quotationNum;
    return <a target={"_blank"} href={link}><b>{e.row.data.quotationNum}</b></a>;
  }
  const handleReset = () =>{ 
    setQuotation({});
  }
  const onValueChanged = useCallback((e) => {
    setQuotation(prevState => ({
      ...prevState,
      [e.element.id]: e.value
    }));
}, []);
  return (
    <div>
    <Loader loading={loading}/>
     <div className="page-header ">
        <div>
          <h1 className="page-title">Search Quotation</h1>
        </div>
         <div className="ms-auto pageheader-btn">
          <Link to={"/Quotations"}  className="btn btn-primary btn-icon text-white me-3">
            <span>
              <i className="fe fe-plus"></i>&nbsp;
            </span>
            Add Quotation
          </Link>
        </div>
      </div>
     <Card>
        <Card.Body>
        <Row>
            <Col sm={4} md={4}>
              <Form.Group>
                <Form.Label>
                  Project Name <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                  dataSource={masters["projects"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="projectName"
                  value={quotation["projectName"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Customer <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["customers"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="customerCode"
                  value={quotation["customerCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Client <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["clients"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="clientCode"
                  value={quotation["clientCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Consultant <span className="text-red">*</span>
                </Form.Label>
                <SelectBox
                 dataSource={masters["consultants"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'Name'}
                  searchTimeout={200}
                  minSearchLength={2}
                  displayExpr="Name"
                  valueExpr="Code"
                  id="consultantCode"
                  value={quotation["consultantCode"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>
                  QuotationNum
                </Form.Label>
                <SelectBox
                  dataSource={masters["quotations"]}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchTimeout={200}
                  minSearchLength={3}
                  id="quotationNum"
                  value={quotation["quotationNum"]}
                  onValueChanged={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  Area
                </Form.Label>
                <TagBox dataSource={masters["areas"]}
                        displayExpr="Name"  
                        id="areaCode"
                        onValueChanged={onValueChanged}
                        value ={quotation["areaCode"]}
                        valueExpr="Code" />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>
                  Sales Representative
                </Form.Label>
                <TagBox dataSource={masters["users"]}
                        displayExpr="Name"  
                        id="msp"
                        onValueChanged={onValueChanged}
                        value ={quotation["msp"]}
                        valueExpr="Id" />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  Status
                </Form.Label>
                <TagBox dataSource={masters["statuses"]}
                        displayExpr="Name"  
                        id="statusId"
                        onValueChanged={onValueChanged}
                        value ={quotation["statusId"]}
                        valueExpr="Id" />
              </Form.Group>
            </Col>
            <Col sm={1} md={1}>
              <Form.Group>
                <Form.Label>
                  Quotation Year 
                </Form.Label>
                  <TagBox dataSource={masters["quotationYears"]}
                        displayExpr="Name"  
                        id="quotationYear"
                        onValueChanged={onValueChanged}
                        value ={quotation["quotationYear"]}
                        valueExpr="Id" />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  From Booking date
                </Form.Label>
                <DateBox id="fromBookingDate" type="date" pickerType="calendar" 
                onValueChanged={onInputChange} value={quotation["fromBookingDate"]} required displayFormat="dd/MM/yyyy"   />
              </Form.Group>
            </Col>
            <Col sm={2} md={2}>
              <Form.Group>
                <Form.Label>
                  To Booking date <span className="text-red">*</span>
                </Form.Label>
                <DateBox id="toBookingDate" type="date" pickerType="calendar"
                onValueChanged={onInputChange} value={quotation["toBookingDate"]} required displayFormat="dd/MM/yyyy"   />
              </Form.Group>
            </Col>
            <Col sm={12} md={12}>
              <Row>
              <Col sm={1} md={1}>
              <Form.Group>
                <Form.Label>
                  &nbsp;
                </Form.Label>
              <Button variant="success" onClick={handleSearch} type="submit">
                      Search
                    </Button>
                    </Form.Group>
                    </Col>
                    <Col sm={2} md={2}>
                    <Form.Group>
                    <Form.Label>
                  &nbsp;
                </Form.Label>
                    <Button className='' onClick={handleReset}>
                      Reset
                    </Button>
                    </Form.Group>
                    </Col>
              </Row>
            </Col>

          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <Row>
        <DataGrid
        dataSource={data}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        columnAutoWidth
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Export  enabled={true} />
        <Paging defaultPageSize={10} enabled={true} />
        <ColumnChooser enabled={true} />
        <Column dataField="quotationNum" cellRender={generateQuotlink} />
        <Column dataField="revNum"/>
        <Column dataField="projectName" />
        <Column dataField="areaName" caption={"SalesArea"} />
        <Column dataField="customerName" />
        <Column dataField="consultantCode" />
        <Column dataField="clientCode" />
        <Column dataField="brandName" />
        <Column dataField="productName" />      
        <Column dataField="totalOrderValue" dataType="number">
          <Format type="fixedPoint" precision={2} />  
        </Column>
        <Column dataField="currencyCode" caption={"Currency"}/>  
        <Column dataField="salesRep" />
        <Column dataField="industry" />
        <Column dataField="probability" />
        <Column dataField="status" />
        <Column dataField="remarks" />
        <Column dataField="quotationDate" dataType= "date" format="dd/MM/yyyy" />
        <Column dataField="bookingDate" dataType= "date" format="dd/MM/yyyy" />
        </DataGrid>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );

}

export default QuotationReport;