import React, { useState } from 'react';

const UserContext = React.createContext();
function UserContextProvider({ children }) {
    const [user, setUser] = useState(null);

    const setUserContext = (obj) => {
        setUser(obj);
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUserContext
            }}
        >
            {children}
        </UserContext.Provider>
    );


}


export default UserContext;

export { UserContextProvider };