import { useEffect, useRef, useState, useContext } from "react";
import SelectBox from 'devextreme-react/select-box';
import DataGrid, {
    Column,
    Editing,
    Scrolling,
    Selection,
    Popup,
    Form,
    Grouping,
    Button as DxDataGridButton,
    Summary,
    TotalItem,
    Lookup,
    Pager,
    Paging,
    FilterRow,Toolbar, HeaderFilter,MasterDetail,Format,
    SearchPanel,
} from 'devextreme-react/data-grid';
import { Button as DxButton } from 'devextreme-react/button';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Modal,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
    CFormText,
  } from "@coreui/react";
import 'devextreme/dist/css/dx.light.css';
import MyContext from '../Shared/MyContext';
import axiosConfig from '../config/axiosConfig';
import { showNotificationSuccess, showNotificationError, Loader, showErrorAlert } from '../Shared/Utils';
import { CostItem } from "../Models/CostItemModel";
import { CheckBox } from "devextreme-react";
import QuotationHeader from "./QuotationHeader";
const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
    position: "fixed",
    zIndex: "9999",
    left: "50%",
    top: "55%"
};
function QuotationCostItem() { 
    const quotationCostItemGrid = useRef();
    const quotLinesInCostItem = useRef(); 
    const costItemLinesGrid = useRef();
    const [showFreightCost, setShowFreightCost] = useState(false);
    const [quotationLinesProdWise, setQuotationLinesProdWise] = useState([]);
    const [quotationCostLinesKeys, setQuotationCostLinesKeys] = useState([]);
    const [quotationCostItems, setQuotationCostItems,] = useState([]);
    const [costItems, setCostItems] = useState([]);
    const [costItem, setCostItem] = useState(new CostItem());
    //const [products, setProducts] = useState([]);
    const [editingRowKey, setEditingRowKey] = useState("");
    const { quotationObjContext, setLoadingContext, setQuotationLinesContext, quotationLinesContext, quotationHeaderContext,
        isQuotationLinesToBeUpdatedContext, setLinesToBeUpdatedContext, isQuotationCostItemsToBeUpdatedContext,
        setCostItemsToBeUpdatedContext,productsContext,setProductsContext } = useContext(MyContext);
    const [showCostItemEditPopUp, setShowCostItemEditPopUp] = useState(false);
    const [isCostItemState, setIsCostItemState] = useState("Add"); //if 
    const costItemTypes =[{ Code: 'ByVal', Name: 'ByVal' }, { Code: 'ByPercentage', Name: 'ByPercentage' }];
    const [errors, setErrors] = useState({});
    const mandatoryFields = [ "costItemId", "costItemType","costItemValue"];
    const [validated, setValidated] = useState(false);
    const [freight,setFreightCode] = useState("");
    const [selectedCostLinesKeys,setSelectedCostLinesKeys]= useState([]);
    const [costLineGroup,setCostLineGroup]=useState({});

    useEffect(() => {
        //if (isCurrencyUpdated) {
        if (quotationObjContext.quotationNum != "" && quotationObjContext.revNum != null) {
            const { quotationNum, revNum } = quotationObjContext;

            Promise.all([axiosConfig.get("Quotation/products?Id=" + quotationNum + "&revNum=" + revNum),
            axiosConfig.get("master/costItems")])
                .then(function ([productsData, costItemsData]) {
                    setProductsContext(productsData);
                    setCostItems(costItemsData);
                    setFreightCode(costItemsData.filter((item)=>item.Name.toLowerCase().includes("freight")));
                    getCostItemsAndRefreshGrid(quotationNum, revNum);
                })
                .catch(error => console.log(error));
        }
        //}
    }, [quotationObjContext["quotationNum"] && quotationObjContext["revNum"]])

    useEffect(() => {
        if (isQuotationCostItemsToBeUpdatedContext) {
            getCostItemsAndRefreshGrid(quotationObjContext.quotationNum, quotationObjContext.revNum);
            setCostItemsToBeUpdatedContext(false);
            setCostItem(new CostItem());
            axiosConfig.get("Quotation/products?Id=" + quotationObjContext.quotationNum + "&revNum=" + quotationObjContext.revNum)
            .then(data =>  setProductsContext(data))
            .catch(error => console.log(error));
        }
    }, [isQuotationCostItemsToBeUpdatedContext])


    const onRowRemoving = (e) => {
        let inputobj = {
            ...e.data,
            quotationNum: quotationObjContext["quotationNum"],
            revNum: quotationObjContext["revNum"]
        };
        setLoadingContext(true);
        axiosConfig.post('Quotation/costLine/delete', JSON.stringify(e.data))
            .then((data) => {
                //getQuotationAndRefreshGrid();
                //getCostItemsAndRefreshGrid();

                setLinesToBeUpdatedContext(true);
                setCostItemsToBeUpdatedContext(true);

                showNotificationSuccess('Cost Item Line Deleted');
            })
            .catch((error) => showNotificationError(`Error in deleting costItemLine :` + error))
            .then(() => setLoadingContext(false));
    }
    const getCostItemsAndRefreshGrid = (quotationNum, revNum) => {
        axiosConfig.get("Quotation/cost/lines?Id=" + quotationNum + "&revNum=" + revNum)
            .then(data => {
                setLoadingContext(false);
                setQuotationCostItems(data);
            });
    }

    const onSelectedRowKeysChange = (e) => {
        setSelectedCostLinesKeys(e);
    }

    const onEditButtonClick = (e) => {
        setCostItem(e.row.data);
        setShowCostItemEditPopUp(true);
        setShowFreightCost(false);
        setErrors({});
        freight.forEach(item =>{
            if(e.row.data.costItemId == item.Code)
            setShowFreightCost(true);
        });
        let selectedLines = e.row.data.quotationLineCostItems.map(item=> item.lineNum);
        //costItemLinesGrid.current.instance.selectRows(selectedLines,true);
        setSelectedCostLinesKeys(selectedLines);
    }

    const onSaveCostItemClick =(evt) =>{
        evt.preventDefault();
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
        evt.preventDefault();
        evt.stopPropagation();
        }
        setValidated(true);
        let isFormValid = true;
        mandatoryFields.forEach(item => {
            if (costItem[item] == null || costItem[item] == "") {
                setErrors(prevState => ({
                ...prevState,
                [item]: "invalid"
                }));
                isFormValid = false;
            }
        });
        if (isFormValid && form.checkValidity()) {
        setLoadingContext(true);
        setErrors({});
        let inputList =[];
        let url ="Quotation/costLine/add";
        let data;
        if(costItem.quotationCostItemGroupId == null){ //for costItemTobeAdded
            productsContext.map((item) => {
                let inputobj = {
                    ...costItem,
                    quotationLineCostItems:quotationLinesContext.filter(x => x.prodTypeId == item.Code)      //costItemLinesGrid.current.instance.getSelectedRowsData(),//.map((item) => ({ lineNum: item.lineNum, itemCode: item.itemCode })),
                                        .filter(x=> selectedCostLinesKeys.includes(x.lineNum))
                                        .map((x) => {return {lineNum:x.lineNum,itemCode:x.itemCode}}),
                    quotationNum: quotationObjContext["quotationNum"],
                    revNum: quotationObjContext["revNum"],
                    prodTypeId:item.Code
                };
                if(inputobj.quotationLineCostItems.length > 0)
                    inputList.push(inputobj);
            });
            data = inputList;
        }
        else{
            url ="Quotation/costLine/update";
            data ={
                ...costItem,
                quotationLineCostItems:quotationLinesContext.filter(x => x.prodTypeId == costItem.prodTypeId)      //costItemLinesGrid.current.instance.getSelectedRowsData(),//.map((item) => ({ lineNum: item.lineNum, itemCode: item.itemCode })),
                                    .filter(x=> selectedCostLinesKeys.includes(x.lineNum))
                                    .map((x) => {return {lineNum:x.lineNum,itemCode:x.itemCode}}),
                quotationNum: quotationObjContext["quotationNum"],
                revNum: quotationObjContext["revNum"]
            };
        }
        
    
        axiosConfig.post(url, JSON.stringify(data) )
            .then(data => {
                setLinesToBeUpdatedContext(true);
                setCostItemsToBeUpdatedContext(true);
                setShowCostItemEditPopUp(false);
                showNotificationSuccess('Cost Item Line saved');
            })
            .catch((error) => {
                //getCostItemsAndRefreshGrid();
                showNotificationError(`Error in saving costItemLine` + error);
            })
            .then(() => setLoadingContext(false));
        }
    }
    const setValidationStatus =(id) =>{
        return errors[id] != undefined ? errors[id] : "valid";
      }
    const onInputChange = (e) => {
        //for dxSelectBox
        if (e.element != undefined) { //e.value != null
          setCostItem(prevState => ({
            ...prevState,
            [e.element.id]: e.value 
          }));
          
          if(e.element.id == "prodTypeId") {
            let data=quotationLinesContext.reduce((values,value)=>{
                if(value.prodTypeId == e.value) values.push({lineNum : value.lineNum ,itemCode:value.itemCode});
                return values;
            },[]);
            setCostItem(prevState => ({
                ...prevState,
                quotationLineCostItems: data
              }));
          }
          if(e.element.id == "costItemId") {
            let isFreight = false;
            freight.forEach(item =>{
                if(e.value == item.Code)
                isFreight = true;
            });
            setShowFreightCost(isFreight);
          }
          if (e.value != "") {
            setErrors(prevState => ({
              ...prevState,
              [e.element.id]: "valid"
            }));
          }
        }
        //for normal inputs
        else {
            let costValue = 0
            if(e.target.id == "freightRate"){
                costValue = (costItem.noOfContainers ?? 0) * parseFloat(e.target.value);
            }
            if(e.target.id == "noOfContainers"){
                costValue = (costItem.freightRate ?? 0) * parseFloat(e.target.value);
            }
            if(costValue != 0){
                setCostItem(prevState => ({
                    ...prevState,
                    costItemValue: costValue
                  }));
            }

            setCostItem(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
          }));
          
          if (e.target.value != "") {
            setErrors(prevState => ({
              ...prevState,
              [e.target.id]: "valid"
            }));
          }
        }
      }
    
    const isAdded =()=>{
        return costItem.quotationCostItemGroupId != null ? false : true;
    }
    const onAddCostItemClick  = (e)=>{
        setCostItem(new CostItem());
        setShowFreightCost(false);
        setShowCostItemEditPopUp(true);
        setSelectedCostLinesKeys([]);
        setCostLineGroup({});
        setErrors({});
        let prods = [... new Set(quotationLinesContext.map(x=>x.prodTypeId))];
        prods.map((item) => {
            setCostLineGroup(prevState => ({
                ...prevState,
                [item]: false
              }));
        });
    }
    const DetailTemplate =(e)=>{
        const costLines = e.data.data.quotationLineCostItems;
        return(
            <div style={{width:"40%"}}>
                <div className="master-detail-caption">
                {`Cost Lines:`}
                </div>
                <DataGrid
                dataSource={costLines}
                showBorders={true}
                columnAutoWidth={true}
                >
                <Column dataField="lineNum" width={50} caption={"LNo"}/>
                <Column dataField="itemCode"  />
                <Column dataField="costLineValue" >
                    <Format type="fixedPoint" precision={2} />  
                </Column>
                </DataGrid>
            </div>
        );
    }
    const oncheckBoxValueChange = (val,options) => {
        let prodLines = quotationLinesContext.filter(item => item.prodTypeId == options.data.key).map(x=>x.lineNum);
        if(val){
            let selectedLines = [...selectedCostLinesKeys,... prodLines];
            setSelectedCostLinesKeys(selectedLines);
        }
        else{
            let selectedLines = selectedCostLinesKeys.filter(x=> !prodLines.includes(x));
            setSelectedCostLinesKeys(selectedLines);
        }
        setCostLineGroup(prevState => ({
            ...prevState,
            [options.displayValue]: val
          }));
    }
    const groupCell =(options) =>{
        return (<><CheckBox value={costLineGroup[options.displayValue]} onValueChange={(e) => oncheckBoxValueChange(e,options)}></CheckBox>&nbsp; {options.displayValue}</>);
    }
    return (
        <>
            <Card style={quotationObjContext.isLocked ? {pointerEvents: "none", opacity: "0.7"}:{}}>
                <Card.Header className="d-flex justify-content-between">

                            <h5>Cost Item</h5>
                        
                        <DxButton
                            //width={120}
                            //text="Copy Option"
                            icon="add"
                            //type="normal"
                            //stylingMode="contained"
                            onClick={onAddCostItemClick}
                            />
                       
                    </Card.Header>
                <Card.Body>
                    <DataGrid
                        dataSource={quotationCostItems}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        ref={quotationCostItemGrid}
                        //onRowInserting={onRowInserting}
                        // onEditorPreparing={onEditorPreparing}
                        //onEditingStart={onEditingStart}
                        //onEditorPrepared={onEditorPrepared}
                        //onRowUpdating={onRowUpdating}
                        onRowRemoving={onRowRemoving}
                    //onSaved={onSaving}
                    >
                        <Editing allowDeleting useIcons/>
                        <Paging defaultPageSize={10} enabled={true} />
                        <Column dataField="prodTypeId" caption={"Product Type"}>
                            <Lookup
                                dataSource={productsContext}
                                valueExpr="Code"
                                displayExpr="Name"
                            />
                        </Column>
                        <Column dataField="costItemType" caption={"Type"}>
                            <Lookup
                                dataSource={costItemTypes}
                                valueExpr="Code"
                                displayExpr="Name"
                            />
                        </Column>
                        <Column dataField="costItemId" caption={"Cost Item"}>
                            <Lookup
                                dataSource={costItems}
                                valueExpr="Code"
                                displayExpr="Name"
                            />
                        </Column>
                        <Column dataField="costItemValue" dataType="number" caption={"Value"} />
                        <Column type="buttons">
                            <DxDataGridButton
                                text="Edit"
                                icon="edit"
                                hint="Edit"
                                onClick={onEditButtonClick} />
                             <DxDataGridButton name="delete" />
                        </Column>
                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    </DataGrid>
                </Card.Body>
            </Card>
            
            <Modal
                show={showCostItemEditPopUp}
                //onHide={()=> setNewCustomerVisible(false)}
                size="lg"
                dialogClassName="modal-90w"
                //size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header >
                    <Modal.Title>{isAdded() ? "Add" : "Edit"} Cost item ({quotationHeaderContext.currencyCode})</Modal.Title>
                    <button onClick={() => setShowCostItemEditPopUp(false)}  className="btn btn-icon me-3">
                        <i className="fe fe-x"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={onSaveCostItemClick}
                    >
                        <CCol md={4}>
                            <CFormLabel htmlFor="costItemId">Cost Item</CFormLabel>
                            <SelectBox
                                dataSource={costItems}
                                validationStatus={setValidationStatus("costItemId")}
                                displayExpr="Name"
                                valueExpr="Code"
                                id="costItemId"
                                value={costItem.costItemId}
                                onValueChanged={onInputChange}
                                readOnly={costItem.quotationCostItemGroupId != null ? true : false}
                            />
                        </CCol>
                        {!isAdded() && <CCol md={4}>
                            <CFormLabel htmlFor="prodTypeId">Product Type</CFormLabel>
                             <CFormInput
                                type="text"
                                id="prodTypeId"
                                value={costItem.prodTypeId}
                                readOnly
                            />
                        </CCol>}
                        <CCol md={4}>
                            <CFormLabel htmlFor="costItemType">Cost Item Type</CFormLabel>
                            <SelectBox
                                dataSource={costItemTypes}
                                validationStatus={setValidationStatus("costItemType")}
                                displayExpr="Name"
                                valueExpr="Code"
                                id="costItemType"
                                value={costItem.costItemType}
                                onValueChanged={onInputChange}
                                readOnly={costItem.quotationCostItemGroupId != null ? true : false}
                            />
                        </CCol>
                        <CCol md={4}>
                            <CFormLabel htmlFor="costItemType">Cost Item Value</CFormLabel>
                            <CFormInput
                                type="number"
                                id="costItemValue"
                                onChange={onInputChange}
                                //defaultValue="Mark"
                                value={costItem.costItemValue}
                                required
                            />
                        </CCol>
                        {showFreightCost && <CCol md={4}>
                          <CFormLabel htmlFor="freightRate">Freight Rate</CFormLabel>
                            <CFormInput
                                type="number"
                                id="freightRate"
                                onChange={onInputChange}
                                //defaultValue="Mark"
                                value={costItem.freightRate}
                                required = {showFreightCost ? true:false}
                            />
                        </CCol>}
                        {showFreightCost && <CCol md={4}>
                            <CFormLabel htmlFor="noOfContainers">No of Containers</CFormLabel>
                            <CFormInput
                                type="number"
                                id="noOfContainers"
                                onChange={onInputChange}
                                //defaultValue="Mark"
                                value={costItem.noOfContainers}
                                required = {showFreightCost ? true:false}
                            />
                        </CCol>}
                        <CCol md={12}>
                            <CFormLabel htmlFor="remarks">Remarks</CFormLabel>
                            <CFormInput
                                type="text"
                                id="remarks"
                                onChange={onInputChange}
                                //defaultValue="Mark"
                                value={costItem.remarks}
                            />
                        </CCol>
                        <CCol  md={{ span: 12, offset: 12 }}>
                            <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3 float-end">
                                Save
                            </Button>
                        </CCol>
                    </CForm>
                    <div className='row mt-4'>
                        <DataGrid
                            dataSource={isAdded() ? quotationLinesContext : quotationLinesContext.filter(item => item.prodTypeId == costItem.prodTypeId)}//.filter(item => item.prodTypeId == costItem.prodTypeId)
                            showBorders={true}
                            allowColumnReordering={true}
                            rowAlternationEnabled={true}
                            ref={costItemLinesGrid}
                            keyExpr="lineNum"
                            selectedRowKeys={selectedCostLinesKeys}
                            onSelectedRowKeysChange ={onSelectedRowKeysChange}
                        >
                            <Selection mode="multiple" allowSelectAll showCheckBoxesMode={"always"}/>
                            <Grouping autoExpandAll={false} />
                            <Paging defaultPageSize={10} enabled={true} />
                            <FilterRow visible={true} />
                            <Column dataField="lineNum" width={150}/>
                            <Column dataField="itemCode" />
                            <Column dataField="prodTypeId" groupIndex={0} groupCellRender={groupCell}/>
                        </DataGrid>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );

}

export default QuotationCostItem;

/**
 * 
 *  
    


    const onRowInserting = (e) => {
        console.log(e);
        let inputobj = {
            ...e.data,
            quotationLineCostItems: quotLinesInCostItem.current.instance.getSelectedRowsData().map((item) => ({ lineNum: item.lineNum, itemCode: item.itemCode })),
            quotationNum: quotationObj["quotationNum"],
            revNum: quotationObj["revNum"]
        };
        setLoading(true);
        axiosConfig.post('Quotation/costLine/add', JSON.stringify(inputobj))
            .then(data => {
                //getQuotationAndRefreshGrid();
                setLinesToBeUpdated(true);
                //getCostItemsAndRefreshGrid();
                //setLoading(false);
                /*if (quotationCostItems.length > 0)
                  setQuotationLines(
                    quotationLines.map((item) => {
                      return item.__KEY__ === e.data.__KEY__ ? data : item;
                    }));
                else {
                    quotationCostItems.push(data);
                  setQuotationLines(quotationCostItems);
                }
                showNotificationSuccess('Cost Item Line Added');
            })
            .catch((error) => {
                getCostItemsAndRefreshGrid();
                showNotificationError(`Error in adding costItemLine` + error);
            })
            .then(() => setLoading(false));
    }
    const onRowUpdating = (e) => {
        console.log(e);
        //let data = { ...e.oldData, ...e.newData };
        let inputobj = {
            ...costItem,
            quotationLineCostItems: quotLinesInCostItem.current.instance.getSelectedRowsData(),//.map((item) => ({ lineNum: item.lineNum, itemCode: item.itemCode })),
            quotationNum: quotationObj["quotationNum"],
            revNum: quotationObj["revNum"]
        };
        setLoading(true);
        axiosConfig.post('Quotation/costLine/update', JSON.stringify(inputobj))
            .then((data) => {
                //getQuotationAndRefreshGrid();
                setLinesToBeUpdated(true);
                setCostItemsToBeUpdated(true);
                //getCostItemsAndRefreshGrid();
                showNotificationSuccess('Cost Item Line Updated');
            })
            .catch((error) => showNotificationError(`Error in updating costItemLine :` + error))
            .then(() => setLoading(false));
    }
     const onEditingStart = (e) => {
        setEditingRowKey(e.key);
    }
    const onSaving = (e) => {
        if (e.changes.length == 0) { // that means that the nw item cods were selected
            //check if any new item code is selected for costItemGroupId
            let newCostLines = quotLinesInCostItem.current.instance.getSelectedRowsData().map((item) => item.lineNum);
            if (newCostLines.sort().join(',') !== quotationCostLinesKeys.sort().join(',')) {
                let inputobj = {
                    ...editingRowKey,
                    quotationLineCostItems: quotLinesInCostItem.current.instance.getSelectedRowsData().map((item) => ({ lineNum: item.lineNum, itemCode: item.itemCode })),
                };
                setLoading(true);
                axiosConfig.post('Quotation/costLine/update', JSON.stringify(inputobj))
                    .then((data) => {
                        //getQuotationAndRefreshGrid();
                        //getCostItemsAndRefreshGrid();

                        setLinesToBeUpdated(true);
                        setCostItemsToBeUpdated(true);

                        showNotificationSuccess('Cost Item Line Updated');
                    })
                    .catch((error) => showNotificationError(`Error in updating costItemLine :` + error))
                    .then(() => setLoading(false));
            }
        }
    }
     const onEditorPreparing = (e) => {
        if (e.parentType == 'dataRow' && e.dataField == 'costItemId') {

            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) { // Override the default handler
                // ...
                // Custom commands go here
                // ...
                // If you want to modify the editor value, call the setValue function:
                // e.setValue(newValue);
                // Otherwise, call the default handler:
                console.log(args);
                if (args.value == "C0003") {
                    setShowFreightCost(true);
                }
                else {
                    setShowFreightCost(false);
                }
                defaultValueChangeHandler(args);
            }
        }
        else if (e.parentType == 'dataRow' && e.dataField == 'prodTypeId') {

            if (e.row.data.quotationCostItemGroupId != null) {
                let linesDataSource = quotationLines.filter(x => x.prodTypeId == e.row.data.prodTypeId)
                let lineNums = e.row.data.quotationLineCostItems.map((item) => item.lineNum);
                setQuotationCostLinesKeys(lineNums);
                setQuotationLinesProdWise(linesDataSource);
            }
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) { // Override the default handler
                let linesDataSource = quotationLines.filter(x => x.prodTypeId == args.value)
                setQuotationLinesProdWise(linesDataSource);
                quotLinesInCostItem.current.instance.option("dataSource", linesDataSource);
                //quotLinesInCostItem.current.instance.selectAll();
                //setQuotationLinesProdWise(e.row.data.quotationLineCostItems);


                defaultValueChangeHandler(args);
            }
        }
    }

    const onContentReady = (e) => {
        if (!e.component.__ready) {  //next time the event fires, this code will not be executed  
            e.component.__ready = true;
            if (quotationCostLinesKeys.length > 0) {
                quotLinesInCostItem.current.instance.option("dataSource", quotationLinesProdWise);
                quotLinesInCostItem.current.instance.selectRows(quotationCostLinesKeys, true);
                //setQuotationLinesProdWise(quotationLinesProdWise);
            }
            else {
                quotLinesInCostItem.current.instance.option("dataSource", quotationLinesProdWise);
                quotLinesInCostItem.current.instance.selectAll();
                setQuotationLinesProdWise(quotationLinesProdWise);
            }
        }
    }

 */