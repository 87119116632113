export const CostItem  = class {
    costItemId =  null;
    costItemType=  null;
    costItemValue= 0;
    freightRate= null;
    noOfContainers=  null;
    prodTypeId=  null;
    quotationCostItemGroupId=  null;
    quotationLineCostItems=  [];
    quotationNum=  null;
    revNum= null;
}