import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
import FileUpload from '../../Shared/FileUpload';

function Pricing() {
    const [pricingList, setPricingData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(loading) {
          axiosConfig.get("Pricing")
          .then(data => {
            setPricingData(data);
          })
          .catch((error) => showNotificationError("Error loading pricing data:"+error))
          .then(()=>{
            setLoading(false);
          });
      }
    },[loading]);

    const refresh = () =>{
        setLoading(true);
    }

    return (
        <div>
            <Loader loading={loading}/>
            <ToastContainer />
            <div className="page-header ">
              <div>
              <h1 className="page-title">Pricing</h1>
              <Breadcrumb className="breadcrumb">
                  <Breadcrumb.Item className="breadcrumb-item" href="#">
                  Item Master
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                  className="breadcrumb-item active breadcrumds"
                  aria-current="page"
                  >
                  Pricing
                  </Breadcrumb.Item>
              </Breadcrumb>
              </div>
          </div>
            <ToastContainer />
            <CForm
                className="row g-3 needs-validation mb-4"
            >
                <CCol md={4}>
                    <CFormLabel htmlFor="seriesId">Upload All headers</CFormLabel>
                    <FileUpload url="pricing/import/excel" refresh={refresh}  />
                </CCol>
                <CCol md={4}>
                    <CFormLabel htmlFor="itemCode">Upload Pricing</CFormLabel>
                    <FileUpload url="pricing/import/excel/pricing" refresh={refresh}/>
                </CCol>
            </CForm>
            <Card>
            <Card.Body>
                <div className="row">
                <DataGrid
                          dataSource={pricingList}
                          allowColumnReordering={true}
                          rowAlternationEnabled={true}
                          showBorders={true}
                      >
                          <FilterRow visible={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} />
                          <Column dataField="prodName" />
                          <Column dataField="groupName" />
                          <Column dataField="brandName" />
                          <Column dataField="seriesName" />
                          <Column dataField="itemCode" />
                          <Column dataField="optCode" />
                          <Column dataField="price" />
                          <Column dataField="version" />
                      </DataGrid>
                </div>
            </Card.Body>
            </Card>
        </div>);


}

export default Pricing;