import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function ProductMaster() {

  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axiosConfig.get("Product")
      .then(data => { setProducts(data); })
      .catch((error) => showNotificationError("Error loading products data:"+error));
  }, [loading]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);
    axiosConfig.post("Product", JSON.stringify(product))
      .then(data => setLoading(false))
      .catch((error) => showNotificationError("Error saving product:"+error));
  }


  return(
    <div>
    <Loader loading={loading}/>
    <ToastContainer />
    <div className="page-header ">
            <div>
            <h1 className="page-title">Products</h1>
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item className="breadcrumb-item" href="#">
                Item Master
                </Breadcrumb.Item>
                <Breadcrumb.Item
                className="breadcrumb-item active breadcrumds"
                aria-current="page"
                >
                Products
                </Breadcrumb.Item>
            </Breadcrumb>
            </div>
    </div>
    <CForm
        className="row g-3 needs-validation mb-4"
        // noValidate
        // validated={validated}
        onSubmit={handleSubmit}
    >
        <CCol md={4}>
            <CFormLabel htmlFor="seriesName">ProdTypeId</CFormLabel>
            <CFormInput
                type="text"
                id="prodTypeId"
                placeholder="Enter Product"
                onChange={e => setProduct(prevState => ({
                  ...prevState,
                  ProdTypeId: e.target.value
                }))}
                required
            />
            <CFormFeedback valid>Looks good!</CFormFeedback>
        </CCol>
        <CCol md={4}>
            <CFormLabel htmlFor="prodName">Product Name</CFormLabel>
            <CFormInput
                type="text"
                id="prodName"
                placeholder="Enter Product Name"
                onChange={e => setProduct(prevState => ({
                  ...prevState,
                  ProdName: e.target.value
                }))}
                required
            />
            <CFormFeedback valid>Looks good!</CFormFeedback>
        </CCol>
        <CCol md={4}>
            <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
            <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                <span>
                    <i className="fe fe-plus"></i>&nbsp;
                </span>
                Add
            </Button>
        </CCol>
    </CForm>
    <Card>
        <Card.Body>

            <div className="row">
            <DataGrid
            dataSource={products}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
          >
            <FilterRow visible={true} />

            <Column dataField="prodTypeId" />
            <Column dataField="prodName" />
          </DataGrid>

            </div>
        </Card.Body>
    </Card>
</div>
);
}

export default ProductMaster;

/*
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="prodTypeId">ProdTypeId</label>
              <input type="text" className="form-control" id="prodTypeId" placeholder="Enter ProdTypeId"
                onChange={e => setProduct(prevState => ({
                  ...prevState,
                  ProdTypeId: e.target.value
                }))} />
            </div>

          </div>
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="groupId">Product Name</label>
              <input type="text" className="form-control" id="groupId" placeholder="Enter Product Name"
                onChange={e => setProduct(prevState => ({
                  ...prevState,
                  ProdName: e.target.value
                }))} />
            </div>
          </div>
          <div className="col-sm">
            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add</button>
          </div>
        </div>
        <div className="row">
           <FileUpload/>
        </div>
        <div className="row">
          <DataGrid
            dataSource={products}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
          >
            <FilterRow visible={true} />

            <Column dataField="prodTypeId" />
            <Column dataField="prodName" />
          </DataGrid>

        </div>
      </div>
    </div>

  );
*/