import SelectBox from 'devextreme-react/select-box';
import { useEffect, useRef, useState, useCallback, useContext } from "react";
import DateBox from 'devextreme-react/date-box';
import axiosConfig from '../config/axiosConfig';
import {
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button
} from "react-bootstrap";
import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
} from "@coreui/react";

import { showNotificationSuccess, showNotificationError, showWarningAlert,Loader, showErrorAlert } from '../Shared/Utils';
import MyContext from '../Shared/MyContext';
import UserContext from '../Shared/UserContext';
import { useNavigate } from 'react-router-dom';
import QuotationCAF from './QuotationCAF';

function QuotationHeader() {
  const currencyGrid = useRef();
  const { quotationObjContext, setQuotationObjContext,setLoadingContext, setQuotationHeaderContext, setLinesToBeUpdatedContext, setCostItemsToBeUpdatedContext,quotationLinesContext } = useContext(MyContext);
  const {user} = useContext(UserContext);
  const [popupCurrencyVisible, setPopupCurrencyVisible] = useState(false);
  const [currency, setCurrency] = useState({});
  const [customers, setCustomers] = useState([]);
  const [quotation, setQuotation] = useState({});
  const [errors, setErrors] = useState({});
  const [masters, setMasters] = useState({ industries: [], users: [], areas: [], deliveryTerms: [], paymentTerms: [], customers: [], statuses: [], currency: [], costItems: [],clients:[],consultants:[] });
  const mandatoryFields = ["customerCode", "currencyCode", "areaCode", "bookingDate", "expectedDeliveryDate", "deliveryTermId", "paymentTermId", "msp", "probability", "statusId"];
  const [validated, setValidated] = useState(false);
  const [oldCurrencyCode, setOldCurrencyCode] = useState("");
  const probabilityList = [{Name:"0%",Id:0},{Name:"25%",Id:25},{Name:"50%",Id:50},{Name:"75%",Id:75},{Name:"90%",Id:90},{Name:"100%",Id:100}];
  const[productCAF,setproductCAF] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    setLoadingContext(true);
    axiosConfig.get('Master')
      .then(data => {
        setMasters({
          users: JSON.parse(data["users"]),
          areas: JSON.parse(data["areas"]),
          deliveryTerms: JSON.parse(data["deliveryTerms"]),
          paymentTerms: JSON.parse(data["paymentTerms"]),
          customers: JSON.parse(data["customers"]),
          clients: JSON.parse(data["clients"]),
          consultants: JSON.parse(data["consultants"]),
          statuses: JSON.parse(data["statuses"]),
          currency: JSON.parse(data["currency"]),
          industries: JSON.parse(data["industries"]),
        });
        setCustomers(JSON.parse(data["customers"]));
      })
      .catch((error) => showNotificationError("Masters loading error:" + error))
      .then(() => setLoadingContext(false));
    if (quotationObjContext["quotationNum"] != "" && user != null) {

      axiosConfig.get('Quotation?Id=' + quotationObjContext["quotationNum"])
        .then(data => {
          let isLocked= false;
          if(data.lockedForEditingBy != null && data.lockedForEditingBy != ""){
           if(data.lockedForEditingBy != user.Id) isLocked = true;
          }
          setQuotationObjContext({ ...quotationObjContext, revNum:data.revNum, isLocked:isLocked });
          setQuotation(data);
          setQuotationHeaderContext(data);
          
          if(isLocked)
            showWarningAlert(["Quotation is locked by User "+ data.lockedForEditingByName]);
        })
        .catch((error) => showNotificationError("Quotation loading error:" + error))
        .then(() => setLoadingContext(false));
    }

  }, [quotationObjContext["quotationNum"],user]);


  const getQuotationHeaderRefresh = () => {
    setLoadingContext(true);
    axiosConfig.get("Quotation?Id=" + quotationObjContext["quotationNum"])
      .then(data => {
        setQuotation(data);
        setQuotationHeaderContext(data);
        setLoadingContext(false);
      });
  }
  const getCurrencyConv = (code, oldcode) => {
    setLoadingContext(true);
    axiosConfig.get("quotation/currency/convfactor?code=" + code 
    + "&oldCode=" + oldcode+"&quotationNum="+quotationObjContext["quotationNum"]+"&revNum="+quotationObjContext["revNum"])
      .then(data => {
        setCurrency(data);
        setCurrency((prevState) => ({ ...prevState, newConvFactor: data.convFactor }));
        setproductCAF(data.productCAFs);
        setPopupCurrencyVisible(true);
        setOldCurrencyCode(oldcode); 
      })
      .catch((error) => showNotificationError("Error in getting conversion currency :" + error))
      .then(() => setLoadingContext(false));
  }

  const onInputChange = (e) => {
    //for dxSelectBox
    if (e.element != undefined) {
      if (e.element.id == "currencyCode" && e.previousValue != undefined) {
        if (e.value != null && e.value != oldCurrencyCode && quotationLinesContext.length > 0)
          getCurrencyConv(e.value, e.previousValue);
      }
      setQuotation(prevState => ({
        ...prevState,
        [e.element.id]: e.value
      }));

      if (e.value != "") {
        setErrors(prevState => ({
          ...prevState,
          [e.element.id]: "valid"
        }));
      }
    }
    //for normal inputs
    else {
      setQuotation(prevState => ({
        ...prevState,
        [e.target.id]: e.target.value
      }));

      if (e.target.value != "") {
        setErrors(prevState => ({
          ...prevState,
          [e.target.id]: "valid"
        }));
      }
    }
  }


  const handleQuotationSave = (evt) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setValidated(true);
    let isFormValid = true;
    mandatoryFields.forEach(item => {
      if (quotation[item] === null || quotation[item] === "" || quotation[item] === undefined ) {
        setErrors(prevState => ({
          ...prevState,
          [item]: "invalid"
        }));
        isFormValid = false;
      }
    });
    if (isFormValid && form.checkValidity()) {
      setLoadingContext(true);
      setErrors({});
      axiosConfig.post("Quotation", JSON.stringify(quotation))
        .then(data => {
          showNotificationSuccess("QuotationHeader saved successfully");
          if(quotation.quotationNum == undefined){
            navigate("/Quotations/"+data.quotationNum);
            window.location.reload(false);
          }
        })
        .catch((error) =>
          showNotificationError("Error in saving quotation:" + error)
        )
        .then(() => setLoadingContext(false));
    }

  }

  const onUpdateCurrencyClick = (e) => {
    currencyGrid.current.instance.saveEditData();
    let data = currencyGrid.current.instance.option("dataSource");
    currency.quotationNum = quotation.quotationNum;
    currency.revNum = quotation.revNum;
    currency.convFactor = currency.newConvFactor == "" ? currency.convFactor : currency.newConvFactor;
    currency.productCAFs = data;// .map((x) => {return {brandCode:x.brandCode,currencyCode:x.currencyCode,productCode:x.productCode}});
    setLoadingContext(true);
    axiosConfig.post("Quotation/currency/update", JSON.stringify(JSON.stringify( currency)))
      .then((data) => {
        setPopupCurrencyVisible(false);
        setLinesToBeUpdatedContext(true);
        setCostItemsToBeUpdatedContext(true);
        showNotificationSuccess("Currency has successfully updated");
      })
      .catch((error) => {
        showNotificationError("Error in updating currency :" + error);
      })
      .then(() => {
        setLoadingContext(false);
        setOldCurrencyCode("");
        getQuotationHeaderRefresh();
      });
  }
  const toggleCurrencyPopup = () => {
    setPopupCurrencyVisible(!popupCurrencyVisible);

  };
  const setValidationStatus = (id) => {
    return errors[id] != undefined ? errors[id] : "valid";
  }
  const handleClose = (e) => {
    setPopupCurrencyVisible(false);

    //change the currency to old currency 
    setQuotation(prevState => ({
      ...prevState,
      currencyCode: oldCurrencyCode
    }));
  }
  const onRefreshCustomerClick =(e,type) =>{
    setLoadingContext(true);
    axiosConfig.get('master/buyers/all?type='+type)
    .then(data => {
      if(type == 4 )
        setMasters(prevState => ({
          ...prevState,
          customers: data
        }));
      else if (type == 5)
        setMasters(prevState => ({
          ...prevState,
          clients: data
        }));
      else 
        setMasters(prevState => ({
          ...prevState,
          consultants: data
        }));
    })
    .catch((error) => showNotificationError("Buyer loading error:" + error))
    .then(() => setLoadingContext(false));
    e.preventDefault();
  }


  return (
    <div>
      <Card style={quotationObjContext.isLocked ? {pointerEvents: "none", opacity: "0.7"}:{}}>
        <Card.Header>
          <h3 className="card-title">{quotationObjContext["quotationNum"]} - R{quotationObjContext["revNum"]}</h3>
        </Card.Header>
        <Card.Body>
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleQuotationSave}
          >
            <CCol md={3}>
              <CFormLabel htmlFor="projectName">Project Name</CFormLabel>
              <CFormInput
                type="text"
                id="projectName"
                onChange={onInputChange}
                //defaultValue="Mark"
                value={quotation["projectName"]}
                required
              />
              <CFormFeedback valid>Looks good!</CFormFeedback>
            </CCol>
            <CCol md={3}>
              <CFormLabel htmlFor="customerCode">Customer</CFormLabel>
              <Row>
                <CCol>
                  <SelectBox
                    dataSource={masters.customers}
                    validationStatus={setValidationStatus("customerCode")}
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Name'}
                    searchTimeout={200}
                    minSearchLength={2}
                    displayExpr="Name"
                    valueExpr="Code"
                    id="customerCode"
                    value={quotation["customerCode"]}
                    onValueChanged={onInputChange}
                  />
                </CCol>
                  <span className="col-auto mb-2">
                    <button className="btn btn-secondary" onClick={(e) => onRefreshCustomerClick(e,4)}>
                      <i className="fe fe-refresh-ccw"></i>
                    </button>
                  </span>
              </Row>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="clientCode">Client</CFormLabel>
              <Row>
                <CCol>
                  <SelectBox
                    dataSource={masters.clients}
                    //validationStatus={setValidationStatus("clientCode")}
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Name'}
                    searchTimeout={200}
                    minSearchLength={2}
                    displayExpr="Name"
                    valueExpr="Code"
                    id="clientCode"
                    value={quotation["clientCode"]}
                    onValueChanged={onInputChange}
                  />
                </CCol>
                  <span className="col-auto mb-2">
                    <button className="btn btn-secondary" onClick={(e) => onRefreshCustomerClick(e,5)}>
                      <i className="fe fe-refresh-ccw"></i>
                    </button>
                  </span>
              </Row>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="consultantCode">Consultant</CFormLabel>
              <Row>
                <CCol>
                  <SelectBox
                    dataSource={masters.consultants}
                    //validationStatus={setValidationStatus("consultantCode")}
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Name'}
                    searchTimeout={200}
                    minSearchLength={2}
                    displayExpr="Name"
                    valueExpr="Code"
                    id="consultantCode"
                    value={quotation["consultantCode"]}
                    onValueChanged={onInputChange}
                  />
                </CCol>
                  <span className="col-auto mb-2">
                    <button className="btn btn-secondary" onClick={(e) => onRefreshCustomerClick(e,6)}>
                      <i className="fe fe-refresh-ccw"></i>
                    </button>
                  </span>
              </Row>
            </CCol>
            <CCol md={1} sm={2}>
              <CFormLabel htmlFor="currencyCode">Currency</CFormLabel>
              <CInputGroup className="has-validation">
                <SelectBox
                  dataSource={masters["currency"]}
                  validationStatus={setValidationStatus("currencyCode")}
                  //placeholder="Select Item Group"
                  displayExpr="Name"
                  valueExpr="Code"
                  id="currencyCode"
                  value={quotation["currencyCode"]}
                  onValueChanged={onInputChange}
                  required
                />
              </CInputGroup>
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="areaCode">Area</CFormLabel>
              <SelectBox
                dataSource={masters["areas"]}
                validationStatus={setValidationStatus("areaCode")}
                //placeholder="Select Item Group"
                displayExpr="Name"
                valueExpr="Code"
                id="areaCode"
                value={quotation["areaCode"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="msp">ASM</CFormLabel>
              <SelectBox
                dataSource={masters["users"]}
                validationStatus={setValidationStatus("msp")}
                displayExpr="Name"
                valueExpr="Id"
                id="msp"
                value={quotation["msp"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="msp">AE</CFormLabel>
              <SelectBox
                dataSource={masters["users"]}
                validationStatus={setValidationStatus("asp")}
                displayExpr="Name"
                valueExpr="Id"
                id="asp"
                value={quotation["asp"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol md={2}>
              <CFormLabel htmlFor="deliveryTermId">Delivery Term</CFormLabel>
              <SelectBox
                dataSource={masters["deliveryTerms"]}
                validationStatus={setValidationStatus("deliveryTermId")}
                displayExpr="Name"
                valueExpr="Id"
                id="deliveryTermId"
                value={quotation["deliveryTermId"]}
                onValueChanged={onInputChange}
                required
              />
            </CCol>
            <CCol sm={4}>
              <CFormLabel htmlFor="paymentTermId"> Payment Term </CFormLabel>
              <SelectBox
                dataSource={masters["paymentTerms"]}
                validationStatus={setValidationStatus("paymentTermId")}
                displayExpr="Name"
                valueExpr="Id"
                id="paymentTermId"
                value={quotation["paymentTermId"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol sm={2}>
              <CFormLabel htmlFor="industryId">Industry </CFormLabel>
              <SelectBox
                dataSource={masters["industries"]}
                validationStatus={setValidationStatus("industryId")}
                //placeholder="Select Item Group"
                displayExpr="Name"
                valueExpr="Id"
                id="industryId"
                value={quotation["industryId"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol sm={2}>
              <CFormLabel htmlFor="bookingDate">Booking Date</CFormLabel>
              <DateBox id="bookingDate" type="date" pickerType="calendar" validationStatus={setValidationStatus("bookingDate")}
                onValueChanged={onInputChange} value={quotation["bookingDate"]} required displayFormat="dd/MM/yyyy"   />
            </CCol>
            <CCol sm={2}>
              <CFormLabel htmlFor="expectedDeliveryDate">  Expected Delivery Date</CFormLabel>
              <DateBox id="expectedDeliveryDate" type="date" pickerType="calendar" validationStatus={setValidationStatus("expectedDeliveryDate")}
                onValueChanged={onInputChange} value={quotation["expectedDeliveryDate"]} required displayFormat="dd/MM/yyyy"  />
            </CCol>
            <CCol sm={2}>
              <CFormLabel htmlFor="quotationDate"> Quotation Date</CFormLabel>
              <DateBox id="quotationDate" type="date" pickerType="calendar"
                onValueChanged={onInputChange} value={quotation["quotationDate"]} readOnly displayFormat="dd/MM/yyyy"  />
            </CCol>
            <CCol sm={2}>
              <CFormLabel htmlFor="statusId">Status</CFormLabel>
              <SelectBox
                dataSource={masters["statuses"]}
                validationStatus={setValidationStatus("statusId")}
                //placeholder="Select Item Group"
                displayExpr="Name"
                valueExpr="Id"
                id="statusId"
                value={quotation["statusId"]}
                onValueChanged={onInputChange}
              />
            </CCol>
            <CCol sm={2}>
              <CFormLabel htmlFor="statusId">Probability</CFormLabel>
              <SelectBox
                dataSource={probabilityList}
                validationStatus={setValidationStatus("probability")}
                displayExpr="Name"
                valueExpr="Id"
                id="probability"
                value={quotation["probability"]}
                onValueChanged={onInputChange}
                required
              />
            </CCol>
            <CCol md={12}>
              <CFormLabel htmlFor="remarks">Remarks</CFormLabel>
                  <textarea
                  className="form-control mb-4 is-valid state-valid"
                  //placeholder="Textarea (success state)"
                  //required=""
                  rows="3"
                  id="remarks"
                  //defaultValue="This is textarea"
                  onChange={onInputChange}
                  value={quotation["remarks"]}
              ></textarea>
            </CCol>
            <CCol md={12}>
              <CButton color="success" type="submit" className="float-end">
                Save Header
              </CButton>
            </CCol>
          </CForm>
        </Card.Body>
      </Card>

      <Modal
        show={popupCurrencyVisible}
        //onHide={toggleCurrencyPopup}
        //size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header >
          <Modal.Title>Converion currency from {currency.oldCurrencyCode} to {currency.currencyCode}</Modal.Title>
          <button onClick={handleClose}  className="btn btn-icon me-3">
            <i className="fe fe-x"></i>
        </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-4'>
              <label htmlFor="QuotationNum">Currency Rate</label>
              <input type="number" className={"form-control"} value={currency.convFactor} disabled />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="QuotationNum">New conversion Rate</label>
              <input type="number" className={"form-control"} id="newConvFactor" placeholder=""
                onChange={(e) => setCurrency((prevState) => ({ ...prevState, newConvFactor: e.target.value, }))} value={currency.newConvFactor} />
            </div>
          </div>
          <div className='row'>
            <QuotationCAF ref={currencyGrid} data={productCAF} quotationCurrency={currency.currencyCode}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" className="me-1" onClick={onUpdateCurrencyClick}>
            Change currency
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}

export default QuotationHeader;


