import { useEffect, useRef, useState, useContext } from "react";
import SelectBox from 'devextreme-react/select-box';
import DataGrid, {
    Column,
    Editing,
    Scrolling,
    Selection,
    Popup,
    Form,
    Grouping,
    Button as DxDataGridButton,
    Summary,
    TotalItem,
    Lookup,
    Pager,
    Paging,
    FilterRow,Toolbar, HeaderFilter,MasterDetail,Format,
    SearchPanel,
} from 'devextreme-react/data-grid';
import { Button as DxButton } from 'devextreme-react/button';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Modal,
    Button,
    Badge
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
    CFormText,
  } from "@coreui/react";
import 'devextreme/dist/css/dx.light.css';
import axiosConfig from './config/axiosConfig';
import { showNotificationSuccess, showNotificationError, Loader, showErrorAlert } from './Shared/Utils';
import { CheckBox } from "devextreme-react";
import MyContext from './Shared/MyContext';
import UserContext from './Shared/UserContext';
import { IssueItem } from "./Models/IssueItemModel";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
    position: "fixed",
    zIndex: "9999",
    left: "50%",
    top: "55%"
};
function ReportIssue() { 
    const issueTypes = ["Bug","Feature","Design","Other"]
    const [issues,setIssues] = useState([]);
    const [issue,setIssue] = useState(new IssueItem());
    const [showPopup,setShowPopup] = useState(false);
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const {setLoadingContext,loadingContext } = useContext(MyContext);
    const {user} =useContext(UserContext);
    const mandatoryFields = ["type","title"];
    const [isRefresh, setIsRefresh]= useState(true);
    useEffect(() => {
        setLoadingContext(true);
        axiosConfig.get("Issue")
                .then(data =>{setIssues(data);setIsRefresh(false); })
                .catch(error => console.log(error))
                .then (() => setLoadingContext(false))
    }, [isRefresh])


    /*const onRowRemoving = (e) => {
        let inputobj = {
            ...e.data,
            quotationNum: quotationObjContext["quotationNum"],
            revNum: quotationObjContext["revNum"]
        };
        setLoadingContext(true);
        axiosConfig.post('Quotation/costLine/delete', JSON.stringify(e.data))
            .then((data) => {
                
                showNotificationSuccess('Cost Item Line Deleted');
            })
            .catch((error) => showNotificationError(`Error in deleting costItemLine :` + error))
            .then(() => setLoadingContext(false));
    }
    const getCostItemsAndRefreshGrid = (quotationNum, revNum) => {
        axiosConfig.get("Quotation/cost/lines?Id=" + quotationNum + "&revNum=" + revNum)
            .then(data => {
                setLoadingContext(false);
                setQuotationCostItems(data);
            });
    }
     const onEditButtonClick = (e) => {
        setCostItem(e.row.data);
        setShowCostItemEditPopUp(true);
        setShowFreightCost(false);
        setErrors({});
        freight.forEach(item =>{
            if(e.row.data.costItemId == item.Code)
            setShowFreightCost(true);
        });
        let selectedLines = e.row.data.quotationLineCostItems.map(item=> item.lineNum);
        //costItemLinesGrid.current.instance.selectRows(selectedLines,true);
        setSelectedCostLinesKeys(selectedLines);
    }*/
    const DetailTemplate =(e)=>{
        const data = e.data.data.additionalRemarks;
        return(
            <div style={{width:"40%"}}>
                <div className="master-detail-caption">
                <b>{`Additonal Remarks:`}</b>
                </div>
                <p>{data}</p>
            </div>
        );
    }
   

   

    const onSaveIssueItemClick =(evt) =>{
        evt.preventDefault();
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
        evt.preventDefault();
        evt.stopPropagation();
        }
        setValidated(true);
        let isFormValid = true;
        mandatoryFields.forEach(item => {
            if (issue[item] == null || issue[item] == "") {
                setErrors(prevState => ({
                ...prevState,
                [item]: "invalid"
                }));
                isFormValid = false;
            }
        });
        if (isFormValid && form.checkValidity()) {
        setLoadingContext(true);
        setErrors({});
        issue.createdBy = user.Id;
        issue.id =0;
        issue.status ="Open";
        console.log(issue);
        axiosConfig.post("Issue", JSON.stringify(issue) )
            .then(data => {
                
                setShowPopup(false);
                setIsRefresh(true);
                showNotificationSuccess('Issue reported');
            })
            .catch((error) => {
                //getCostItemsAndRefreshGrid();
                showNotificationError(`Error in saving costItemLine` + error);
            })
            .then(() => setLoadingContext(false));
        }
    }
    const setValidationStatus =(id) =>{
        return errors[id] != undefined ? errors[id] : "valid";
      }
    const onInputChange = (e) => {
        //for dxSelectBox
        if (e.element != undefined) { //e.value != null
          setIssue(prevState => ({
            ...prevState,
            [e.element.id]: e.value 
          }));
          
          if (e.value != "") {
            setErrors(prevState => ({
              ...prevState,
              [e.element.id]: "valid"
            }));
          }
        }
        //for normal inputs
        else {
            setIssue(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
          }));
          
          if (e.target.value != "") {
            setErrors(prevState => ({
              ...prevState,
              [e.target.id]: "valid"
            }));
          }
        }
      }
    
    const isAdded =()=>{
        return issue.id != null ? false : true;
    }
    const onAddIssueItemClick  = (e)=>{
        setShowPopup(true);
        setIssue(new IssueItem());
    }
    const cellRender =(e) =>{
        let status =e.row.data.status;
        let bg ="";
        if(status == "Open") bg ="danger";
        else if(status == "WIP") bg ="warning";
        else if(status == "Done") bg ="success";
        else  bg ="primary";
        return <Badge
        bg={bg}
        className={'badge rounded-pill me-1 mb-1 mt-1 bg-'+ bg} > {status}</Badge>;
      }

    return (
        <>
         <Loader loading={loadingContext}/>
            <ToastContainer />
            <div className="page-header ">
                <div>
                <h1 className="page-title">Issue</h1>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item className="breadcrumb-item" href="#">
                    Report
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                    className="breadcrumb-item active breadcrumds"
                    aria-current="page"
                    >
                    Issue
                    </Breadcrumb.Item>
                </Breadcrumb>
                </div>
            </div>
            <Card>
                <Card.Header className="d-flex justify-content-between">

                            <h5>Issue</h5>
                        
                        <DxButton
                            //width={120}
                            //text="Copy Option"
                            icon="add"
                            //type="normal"
                            //stylingMode="contained"
                            onClick={onAddIssueItemClick}
                            />
                       
                    </Card.Header>
                <Card.Body>
                    <DataGrid
                        dataSource={issues}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        //ref={quotationCostItemGrid}
                        //onRowRemoving={onRowRemoving}
                    >
                        <FilterRow visible={true} />
                        
                        <Paging defaultPageSize={20} enabled={true} />
                        <Column dataField="type" />
                        <Column dataField="title" />
                        <Column dataField="reportedBy" />
                        <Column dataField="createdOn"  dataType= "date" format="dd/MM/yyyy"  />
                        <Column dataField="status" cellRender={cellRender}/>
                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    </DataGrid>
                </Card.Body>
            </Card>
            
            <Modal
                show={showPopup}
                //onHide={()=> setNewCustomerVisible(false)}
                size="lg"
                dialogClassName="modal-90w"
                //size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header >
                    <Modal.Title>Add Issue</Modal.Title>
                    <button onClick={() => setShowPopup(false)}  className="btn btn-icon me-3">
                        <i className="fe fe-x"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={onSaveIssueItemClick}
                    >
                         <CCol md={4}>
                            <CFormLabel htmlFor="type">Type</CFormLabel>
                            <SelectBox
                                dataSource={issueTypes}
                                validationStatus={setValidationStatus("type")}
                                id="type"
                                value={issue.type}
                                onValueChanged={onInputChange}
                                //readOnly={costItem.quotationCostItemGroupId != null ? true : false}
                            />
                        </CCol>
                        <CCol md={8}>
                            <CFormLabel htmlFor="title">Title</CFormLabel>
                            <CFormInput
                                type="text"
                                id="title"
                                onChange={onInputChange}
                                value={issue.title}
                                required
                            />
                        </CCol>
                        <CCol md={12}>
                        <CFormLabel htmlFor="additionalRemarks">Addional Remarks</CFormLabel>
                            <textarea
                            className="form-control mb-4 is-valid state-valid"
                            //placeholder="Textarea (success state)"
                            //required=""
                            rows="3"
                            id="additionalRemarks"
                            //defaultValue="This is textarea"
                            onChange={onInputChange}
                            value={issue.additionalRemarks}
                        ></textarea>
                        </CCol>
                        <CCol  md={{ span: 12, offset: 12 }}>
                            <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3 float-end">
                                Save
                            </Button>
                        </CCol>
                    </CForm>
                </Modal.Body>
            </Modal>

        </>
    );

}

export default ReportIssue;
