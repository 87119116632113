import React from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { useEffect, useState } from "react";
import Popup from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import axiosConfig from '../config/axiosConfig';
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../Shared/Utils';
function FileUpload(props){
    const [popupVisible,setPopupVisible] = useState(false);
    const [validations,setValidations] = useState([]);
    const [loading, setLoading] = useState(false);
    const excelFileTypes =".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls";
    const onSelectedFilesChanged = (e) =>{
      if(e.value == null || e.value.length == 0)
        return ;
      setLoading(true);
      var data = new FormData();
      data.append("file",e.value[0]);
      axiosConfig.post(props.url, data)
        .then(data => {
                props.refresh();
                showNotificationSuccess("Successfuly uploaded");
              })
        .catch((error) => {
          if(error.response.status == 422){
            showWarningAlert(error.response.data);
            //setPopupVisible(true);
            //setValidations(data);
          }
          else {
            showNotificationError("Error in saving file :"+error)
          } 
       })
       .then(()=> setLoading(false));

    }
    /*
    const renderPopup = () => {
      return (
        <div className="">
          {
            validations.map((item,index) => {
              return <div>{index+1}. {item}</div>;
            })
          }
        </div>
      );
    }
    */
    return(
        <div className="fileuploader-container">
           <Loader loading={loading}/>
           <ToastContainer />
          <FileUploader  selectButtonText="Select excel file" labelText="" allowedFileExtensions={['.xlsx', '.xls', '.csv']} uploadMode="useForm" onValueChanged={onSelectedFilesChanged}/>
        </div>
    );
}

export default FileUpload;
 /*<Popup
            width={660}
            height={500}
            showTitle={true}
            title={"Validation Messages"}
            dragEnabled={false}
            hideOnOutsideClick={true}
            visible={popupVisible}
           // onHiding={this.handlePopupHidden}
            contentRender={renderPopup}
          />*/