import DataGrid, {
    Column,
    Editing,
    Format,
    Scrolling,
    Selection,
    Grouping,
    Summary,
    TotalItem, Item, Toolbar,Lookup,
    ColumnChooser,
    Pager,
    Paging,
    SearchPanel,FilterRow,
    Export,
    RemoteOperations,
    Button as DXButtonDataGrid
  } from 'devextreme-react/data-grid';
import React from "react";
const QuotationCAF = React.forwardRef ((props,ref) => {

    const renderCurrency =(e) =>{
        var data = e.row.data.currencyCode+"->"+props.quotationCurrency;
        return <b>{data}</b>;
      }
    
    return(
        <DataGrid
        dataSource={props.data}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        ref={ref}
    >
        <Editing
            mode="batch"
            selectTextOnEditStart
            startEditAction={"click"}
            allowUpdating={true}/>
        <Paging defaultPageSize={10} enabled={true} />
        <Column dataField="productCode" caption={"Product Type"}  allowUpdating={false}/>
        <Column dataField="currencyCode" caption={"Currency"} allowUpdating={false} cellRender={renderCurrency}/>
        <Column dataField="brandCode"  allowUpdating={false}/>
        <Column dataField="caf" dataType="number"  allowUpdating/>
    </DataGrid>
    );

});

export default QuotationCAF;