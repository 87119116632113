export const msalConfig = {
    auth: {
      clientId: "d5496d35-0733-4a1d-a3f3-b5e97425ca3f",
      authority: "https://login.microsoftonline.com/07f8d74b-f1bf-468d-b560-da0bb6844e48", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
       //redirectUri: "http://localhost:3000",
       redirectUri: "https://cms.comfortrh.com",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };