import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function ItemGroup() {
    const [products, setProducts] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);
    const [selectedprod, setSelectedProd] = useState("");
    const [itemGroup, setItemGroup] = useState({});//ProdTypeId:"",GroupId:"",GroupDescription:""
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosConfig.get("Product")
            .then(data => setProducts(data));
    }, []);

    const productValueChanged = (e) => {
        setSelectedProd(e.value);
        setItemGroup(prevState => ({
            ...prevState,
            ProdTypeId: e.value
        }));
    }
    useEffect(()=>{
        axiosConfig.get("ItemGroup")
        .then(data => setItemGroups(data));
    },[loading]);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoading(true);
        axiosConfig.post('ItemGroup', JSON.stringify(itemGroup))
            .then(data =>  setLoading(false));
    }



return(
    <div>
    <Loader loading={loading}/>
    <ToastContainer />
    <div className="page-header ">
            <div>
            <h1 className="page-title">ItemGroup</h1>
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item className="breadcrumb-item" href="#">
                Item Master
                </Breadcrumb.Item>
                <Breadcrumb.Item
                className="breadcrumb-item active breadcrumds"
                aria-current="page"
                >
                ItemGroup
                </Breadcrumb.Item>
            </Breadcrumb>
            </div>
    </div>
    <CForm
        className="row g-3 needs-validation mb-4"
        // noValidate
        // validated={validated}
        onSubmit={handleSubmit}
    >
        
        <CCol md={2}>
            <CFormLabel htmlFor="itemGroup">Product</CFormLabel>
            <SelectBox
                dataSource={products}
                placeholder="Select product"
                displayExpr="prodName"
                valueExpr="prodTypeId"
                value={selectedprod}
                onValueChanged={productValueChanged}
                />
        </CCol>
        <CCol md={4}>
            <CFormLabel htmlFor="groupName">ItemGroup Name</CFormLabel>
            <CFormInput
                type="text"
                id="groupName"
                placeholder="Enter Group"
                onChange={e => setItemGroup(prevState => ({
                    ...prevState,
                    GroupName: e.target.value
                }))}
                required
            />
            <CFormFeedback valid>Looks good!</CFormFeedback>
        </CCol>
        <CCol md={4}>
            <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
            <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                <span>
                    <i className="fe fe-plus"></i>&nbsp;
                </span>
                Add
            </Button>
        </CCol>
    </CForm>
    <Card>
        <Card.Body>

            <div className="row">
            <DataGrid
                        dataSource={itemGroups}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="groupName" />
                        <Column dataField="prodTypeId" />
                    </DataGrid>

            </div>
        </Card.Body>
    </Card>
</div>
);

}

export default ItemGroup;



/*
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                    <div className="form-group">
                            <label htmlFor="prodTypeId">ProdTypeId</label>
                            <SelectBox
                            dataSource={products}
                            placeholder="Select product"
                            displayExpr="prodName"
                            valueExpr="prodTypeId"
                            value={selectedprod}
                            onValueChanged={productValueChanged}
                            />
                        </div> 
                    </div>
                    <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="groupName">Group Name</label>
                            <input type="text" className="form-control" id="groupName" placeholder="Enter ItemGroup Description" 
                                onChange={e => setItemGroup(prevState => ({
                                    ...prevState,
                                    GroupName: e.target.value
                                }))}/>
                        </div>
                    </div>
                    <div className="col-sm">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add</button>
                    </div>
                </div>
                <div className="row">
                    <DataGrid
                        dataSource={itemGroups}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="groupName" />
                        <Column dataField="prodTypeId" />
                    </DataGrid>

                </div>
            </div>
        </div>);

*/