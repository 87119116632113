import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function Brand() {
    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState({});//ProdTypeId:"",GroupId:"",GroupDescription:""
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(loading) {
        axiosConfig.get("Brand")
            .then(data => setBrands(data))
            .catch((error) => showNotificationError("Error loading pricing data:"+error))
            .then(()=>{
              setLoading(false);
            });
        }
    }, [loading]);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoading(true);
        axiosConfig.post("Brand", JSON.stringify(brand))
            .then(data => setLoading(false))
            .catch((error) => showNotificationError("Error loading pricing data:"+error))
          .then(()=>{
            setLoading(false);
          });
    }

    return (
        <div>
            <Loader loading={loading}/>
            <ToastContainer />
            <div className="page-header ">
              <div>
              <h1 className="page-title">Brands</h1>
              <Breadcrumb className="breadcrumb">
                  <Breadcrumb.Item className="breadcrumb-item" href="#">
                  Item Master
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                  className="breadcrumb-item active breadcrumds"
                  aria-current="page"
                  >
                  Brand
                  </Breadcrumb.Item>
              </Breadcrumb>
              </div>
          </div>
            <CForm
                className="row g-3 needs-validation mb-4"
                // noValidate
                // validated={validated}
                onSubmit={handleSubmit}
            >
                <CCol md={4}>
                    <CFormLabel htmlFor="brand">Brand</CFormLabel>
                    <CFormInput
                        type="text"
                        id="brand"
                        placeholder="Enter Brand"
                        onChange={e => setBrand(prevState => ({
                            ...prevState,
                            BrandName: e.target.value
                        }))}
                        required
                    />
                    <CFormFeedback valid>Looks good!</CFormFeedback>
                </CCol>
                <CCol md={4}>
                    <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
                    <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                        <span>
                            <i className="fe fe-plus"></i>&nbsp;
                        </span>
                        Add
                    </Button>
                </CCol>
            </CForm>
            <Card>
                <Card.Body>

                    <div className="row">
                        <DataGrid
                            dataSource={brands}
                            allowColumnReordering={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                        >
                            <FilterRow visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                            <Column dataField="brandName" name={"Name"} />
                        </DataGrid>

                    </div>
                </Card.Body>
            </Card>
        </div>);

}

export default Brand;