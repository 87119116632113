import React,{useState} from 'react';

const MyContext = React.createContext();
function MyContextProvider ({children}) {
    // Context state
    const [isQuotationCostItemsToBeUpdatedContext,setIsQuotationCostItemsToBeUpdated] = useState(false);
    const [isQuotationLinesToBeUpdatedContext,setIsQuotationLinesToBeUpdated] = useState(false);
    const [isHeaderToBeUpdatedContext,setIsQuotationHeaderToBeUpdated] = useState(false);
    const [loadingContext,setLoadingSpinner] = useState(false);
    const [quotationObjContext,setQuotation] = useState({quotationNum:"",revNum:null,isLocked:false});
    const [quotationLinesContext,setQuotationItemLines] = useState([]);
    const [quotationHeaderContext,setQuotationHeader] = useState([]);
    const [productsContext,setProducts] = useState([]);
    const [warrantyObjContext,setWarranty] = useState({salesOrderReference:""});
    const [warrantyLinesContext,setWarrantyLines] = useState([]);



    const setLinesToBeUpdatedContext =(val) =>{
      setIsQuotationLinesToBeUpdated(val);
    }
    const setCostItemsToBeUpdatedContext =(val) =>{
      setIsQuotationCostItemsToBeUpdated(val);
    }
    const setQuotationObjContext =(obj) =>{
      setQuotation(obj);
    }
    const setQuotationLinesContext =(lines) =>{
      setQuotationItemLines(lines);
    }
    const setLoadingContext =(val)=>{
      setLoadingSpinner(val);
    }
    const setQuotationHeaderContext =(obj)=>{
      setQuotationHeader(obj);
    }
    const setHeaderToBeUpdatedContext =(val) =>{
      setIsQuotationHeaderToBeUpdated(val);
    }
    const setProductsContext =(arr) =>{
      setProducts(arr);
    }
    const setWarrantyObjContext =(obj) =>{
      setWarranty(obj);
    }
    const setWarrantyLinesContext =(lines) =>{
      setWarrantyLines(lines);
    }

        return (
          <MyContext.Provider
            value={{
                isQuotationCostItemsToBeUpdatedContext,
                setCostItemsToBeUpdatedContext,
                isQuotationLinesToBeUpdatedContext,
                setLinesToBeUpdatedContext,
                quotationObjContext,
                setQuotationObjContext,
                quotationLinesContext,
                setQuotationLinesContext,
                quotationHeaderContext,
                setQuotationHeaderContext,
                setLoadingContext,
                loadingContext,
                isHeaderToBeUpdatedContext,
                setHeaderToBeUpdatedContext,
                productsContext,
                setProductsContext,
                warrantyObjContext,
                setWarrantyObjContext,
                warrantyLinesContext,
                setWarrantyLinesContext,
            }}
          >
            {children}
          </MyContext.Provider>
        );
      

}


export default MyContext;

export { MyContextProvider };