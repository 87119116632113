import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
} from 'devextreme-react/data-grid';
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
} from "@coreui/react";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import { showNotificationSuccess,showNotificationError,Loader,showErrorAlert,showWarningAlert } from '../../Shared/Utils';
import axiosConfig from '../../config/axiosConfig';
function ItemCode() {
    const [series, setSeries] = useState([]);
    const [itemCode, setItemCode] = useState({});//ProdTypeId:"",GroupId:"",GroupDescription:""
    const [itemCodes, setItemCodes] = useState({});
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        axiosConfig.get("Series")
            .then(data => setSeries(data));
    }, []);

    const onValueChanged = (e) => {
        console.log(e);
        let  seriesName = series.filter(x=>x["seriesId"] == e.value)[0]["seriesName"]
        setItemCode(prevState => ({
            ...prevState,
            SeriesId: e.value,
            SeriesName : seriesName
        }));
    }
    useEffect(()=>{
        axiosConfig.get("ItemCode")
        .then(data => setItemCodes(data));
    },[loading]);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemCode)
        };
        setLoading(true);
        axiosConfig.post('ItemCode', requestOptions)
            .then(data =>  setLoading(false));
    }


    return(
        <div>
        <Loader loading={loading}/>
        <ToastContainer />
        <div className="page-header ">
                <div>
                <h1 className="page-title">Item Code</h1>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item className="breadcrumb-item" href="#">
                    Item Master
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                    className="breadcrumb-item active breadcrumds"
                    aria-current="page"
                    >
                    Item Codes
                    </Breadcrumb.Item>
                </Breadcrumb>
                </div>
        </div>
        <CForm
            className="row g-3 needs-validation mb-4"
            // noValidate
            // validated={validated}
            onSubmit={handleSubmit}
        >
            <CCol md={4}>
                <CFormLabel htmlFor="seriesId">Series</CFormLabel>
                <SelectBox
                    dataSource={series}
                    placeholder="Select Series"
                    displayExpr="seriesName"
                    valueExpr="seriesId"
                    //value={selectedSeries}
                    onValueChanged={onValueChanged}
                    />
            </CCol>
            <CCol md={4}>
                <CFormLabel htmlFor="itemCode">Model No</CFormLabel>
                <CFormInput
                    type="text"
                    id="itemCode"
                    placeholder="Enter Series"
                    onChange={e => setItemCode(prevState => ({
                        ...prevState,
                        ItemCode: e.target.value
                    }))}
                    required
                />
                <CFormFeedback valid>Looks good!</CFormFeedback>
            </CCol>
            <CCol md={4}>
                <CFormLabel htmlFor="price">&nbsp;</CFormLabel>
                <Button type="submit" variant="success" className="btn btn-success btn-icon text-white me-3">
                    <span>
                        <i className="fe fe-plus"></i>&nbsp;
                    </span>
                    Add
                </Button>
            </CCol>
        </CForm>
        <Card>
            <Card.Body>

                <div className="row">
                <DataGrid
                        dataSource={itemCodes}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="itemCode" />
                        <Column dataField="seriesName" />
                    </DataGrid>

                </div>
            </Card.Body>
        </Card>
    </div>
    );
}

export default ItemCode;

/*

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                    <div className="form-group">
                            <label htmlFor="seriesId">Series</label>
                            <SelectBox
                            dataSource={series}
                            placeholder="Select Series"
                            displayExpr="seriesName"
                            valueExpr="seriesId"
                            //value={selectedSeries}
                            onValueChanged={onValueChanged}
                            />
                        </div> 
                    </div>
                    <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="groupName">Model No</label>
                            <input type="text" className="form-control" id="modelNo" placeholder="Enter Model No" 
                                onChange={e => setItemCode(prevState => ({
                                    ...prevState,
                                    ItemCode: prevState.SeriesName +e.target.value
                                }))}/>
                        </div>
                    </div>
                    <div className="col-sm">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add</button>
                    </div>
                </div>
                  <div className="row">
                    <FileUpload url="itemCode/import/excel"/>
                  </div>
                <div className="row">
                    <DataGrid
                        dataSource={itemCodes}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column dataField="itemCode" />
                        <Column dataField="seriesName" />
                    </DataGrid>

                </div>
            </div>
        </div>);
        */